import React from 'react'
import Edushrine from './edu'

export default function index() {
  return (
    <div>
        <Edushrine/>
    </div>
  )
}
