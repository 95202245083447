import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_course_material:[],
    current_course_material:null,
    loading:false,
    hasError:false,
}

export const course_materialSlice = createSlice({

  name: 'course_material',
  initialState,

  reducers: {
    getcourse_material: state => {
      state.loading = true;
    },

    getAll_course_material_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_course_material = payload
    },

    get_course_material_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_course_material = payload
  
  },

  },
})


export const { getcourse_material ,getAll_course_material_success,getCurrentSuccess, get_course_material_Failure } = course_materialSlice.actions;

export const course_materialSelector = state => state.course_material;



export const fetchAllcourse_material = () => async dispatch => {
  dispatch(getcourse_material())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/course_material`)
   console.log({data});
   dispatch(getAll_course_material_success(data));

  } catch (error) {
 dispatch(get_course_material_Failure())
  }
 };



 export const fetchOnecourse_material = (id) => async dispatch => {

  dispatch(getcourse_material())
  try {
 
    console.log("Inside try")
   const {data} = await axios.get(keyUri.BACKEND_URI +`/course_material/${id}`, config)
   console.log("after the line")

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_course_material_Failure())
  }
 };



export default course_materialSlice.reducer;