import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_news:[],
    current_news:null,
    loading:false,
    hasError:false,
}

export const newsSlice = createSlice({

  name: 'news',
  initialState,

  reducers: {
    getnews: state => {
      state.loading = true;
    },

    getAll_news_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_news = payload
    },

    get_news_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_news = payload
  
  },

  },
})


export const { getnews ,getAll_news_success,getCurrentSuccess, get_news_Failure } = newsSlice.actions;

export const newsSelector = state => state.news;



export const fetchAllnews = (id) => async dispatch => {
  dispatch(getnews())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/news`)
   console.log({data});
   dispatch(getAll_news_success(data));

  } catch (error) {
 dispatch(get_news_Failure())
  }
 };



 export const fetchOneNews = (id) => async dispatch => {

  dispatch(getnews())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/news/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_news_Failure())
  }
 };



export default newsSlice.reducer;