import React from 'react'
import Ban1 from '../images/ban1.png'
import { NavLink } from 'react-router-dom'

export default function newsletter() {
  return (
    <div>
   <section class="bg-white py-10  sm:py-20 sm:px-24" style={{fontFamily:'manrope'}}>
  <div class="container sm:mx-auto ">
    <div class="relative  sm:py-11 sm:px-16 px-2 rounded-2xl overflow-hidden">
    <img class=" absolute h-52 w-full sm:w-auto sm:h-auto top-0 left-0 px-6 sm:px-20" src={Ban1} alt=""/>

      <div class="relative z-10 sm:z-20 flex flex-wrap items-center">
        <div class="w-1/2 sm:w-1/2">
        <p class="px-4 mt-4 mb-5 sm:mt-5 sm:mb-10 sm:px-16 sm:text-4xl text-md pl-14  text-white font-bold">Design your Future with Edushrine.<span className='text-red-600'>Today!</span> </p>
        </div>
        <div class="w-full sm:w-1/2 sm:p-3 p-2 ">

          <NavLink to='/contact'>
          <button class="block sm:mt-4  ml-10 sm:ml-60 sm:px-10 sm:py-2 px-5 py-2  font-semibold sm:text-2xl text-sm  text-white bg-red-600 hover:bg-orange-600 rounded-lg sm:rounded-md">Contact Now</button>
          </NavLink>
        </div>
      </div>

      
    </div>
  
  </div>

  
</section>
    </div>
  )
}
