import { configureStore } from '@reduxjs/toolkit';
import blogReducer from './blog'
import newsReducer from './news'
import testimonialReducer from './testimonials'
import bannerReducer from './banner'
import authenticateReducer from './authSlice';
import resultReducer from './result'
import PTBReducer from './PTB'
import PTEReducer from './PTE'
import GTEReducer from './GTE'
import GTBReducer from './GTB'
import CourseMaterialReducer from './courseMaterial'
import mobbannerReducer from './mobBanner'
import contactReducer from './contactenquiry'
import notificationReducer from './notification'
import BroadcastReducer from './broadcast'
 

export default configureStore({
  reducer: {
     blog:blogReducer,
     news:newsReducer,
     testimonials:testimonialReducer,
     banner:bannerReducer,
     auth:authenticateReducer,
     results:resultReducer,
     PTBresults:PTBReducer,
     PTEresults:PTEReducer,
     GTEresults:GTEReducer,
     GTBresults:GTBReducer,
     course_material:CourseMaterialReducer,
     mobbanner:mobbannerReducer,
     contact:contactReducer,
     notify:notificationReducer,
     broadcast:BroadcastReducer,

    

  },
});