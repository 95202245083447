import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAlltestimonials, testimonialsSelector} from '../../api/testimonials';

import Loadable from '@loadable/component';


const OwlCarousel = Loadable(() => import('react-owl-carousel3'));



const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 1000,
    responsive: {
        0:{
            items:1,
        },
        768:{
            items:1,
        },
        1200:{
            items:4,
        }
    }
}

export default function SectionThree() {

     const dispatch = useDispatch()
    const {all_testimonials} = useSelector(testimonialsSelector)

    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    useEffect(()=>{
        dispatch(fetchAlltestimonials())
    },[dispatch])



    return (
<section className="pt-12 pb-16 sm:pt-24 sm:pb-32 bg-red-50 overflow-hidden" style={{fontFamily:'Manrope'}}>
  {/* <div className="container"> */}
    <div className="sm:mw-md-md sm:mx-auto text-center sm:mb-16">
      <h2 className="fs-10 font-extrabold text-black text-lg sm:text-2xl mb-5"><span  className='text-red-600'>12k+</span> Proud Students</h2>
      <p className="text-2xl sm:text-5xl font-extrabold text-black">Students, Parents & Experts <br/> <span className='text-red-600'>Love Us!</span></p>
    </div>  
    <div className='sm:mx-16 sm:flex'>
    {display ? <OwlCarousel 
     
            {...options}
        >
    {
     all_testimonials.map((item ,i)=>{
        return(
         
            <div key = {i} className="row g-10 flex m-2">
          <div className="col-12 col-md-4 m-6">
            <div className="h-100 w-full rounded-md shadow-lg p-[1.5px] shadow-gray-300 border-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 " >
              <div className="d-flex flex-column justify-content-between align-items-between h-100 p-8 bg-white rounded-1">
                <div className="mb-8 w-1/3 ">
                <img className=" object-cover rounded-full justify-start" src={item.image} alt=""/>
                </div>
                  <p className="text-secondary">&ldquo;{item.title}&rdquo;</p>
                <div className=' flex'>
                  <h3 className="fs-17 mt-6  ml-2 text-secondary mb-1.5">{item.author}</h3>
                </div>
              </div>
            </div>
          </div>
          </div>
        
          
        )

     })
    }
    </OwlCarousel> : ''}
     </div>
    
        



          {/* <div className="col-12 col-md-4">
            <div className="h-100 rounded-3 shadow border border-gradient-to-r from-indigo-500 via-purple-400 to-pink-900">
              <div className="d-flex flex-column justify-content-between align-items-between h-100 p-8 bg-white rounded-1">
                <div className="mb-8">
                  <img className="mb-8" src="" alt=""/>
                  <p className="text-secondary">&ldquo;Simply the best. Better than all the rest. I&rsquo;d recommend this product to beginners and advanced users.&rdquo;</p>
                </div>
                <div>
                  <h3 className="fs-17 text-secondary mb-1.5">Ronald Richards</h3>
                  <p className="fs-19 text-muted mb-0">Software Engineer</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="h-100 rounded-3">
              <div className="d-flex flex-column justify-content-between align-items-between h-100 p-8 bg-white rounded-1">
                <div className="mb-8">
                  <img className="mb-8" src="" alt=""/>
                  <p className="text-secondary">&ldquo;I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to edit and publish.&rdquo;</p>
                </div>
                <div>
                  <h3 className="fs-17 text-secondary mb-1.5">Darlene Robertson</h3>
                  <p className="fs-19 text-muted mb-0">Social Media Influencer</p>
                </div>
              </div>
            </div>
          </div> */}
     
 
</section>   
    )
}
 
