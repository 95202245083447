import React from 'react'
import Fig from '../images/bannermedicored.png'
import Figone from '../images/medico.png'
import Img1 from '../images/img1.png'
import Img2 from '../images/img2.png'
import Img3 from '../images/img3.png'
import Img4 from '../images/img4.png'
import Monitor from '../images/monitor.png'
import Study from '../images/study.png'
import Shedule from '../images/shedule.png'
// import Contactimage from '../images/contactimage.webp'
import Overview from '../images/overview.jpg'
import Ban1 from '../images/ban1.png'
// import Calender from '../images/calender.webp'
import {heading} from '../courses/faqdata'
import { NavLink } from 'react-router-dom'
import Stories from '../homepage/stories'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

console.log(heading)


export default function Medico() {
  return (
    <div className='bg-white'>
       <div className='relative hidden sm:block object-cover bg-cover w-full sm:w-full  justify-center items-center' style={{backgroundImage:`url(${Fig})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}>
</div>
<div className='block sm:hidden object-cover bg-cover w-full sm:w-full  justify-center items-cente' style={{backgroundImage:`url(${Figone})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}>
</div>   

{/* --------------------------------------------------------------------------------- */}
<section class="py-10 overflow-x-hidden " style={{fontFamily:'Manrope'}}>
<p class="max-w-6xl mx-auto font-heading font-bold text-center mb-10 px-16 text-3xl md:text-4xl text-gray-800 ">MEDICO - Target Exams<span className='text-red-600'>  & Courses</span></p>


  <div class="container mx-auto px-4">
    <div class="flex flex-wrap justify-center -mx-3 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6 ">
        <div class="h-full p-6 md:p-10 border bg-red-50  transform hover:-translate-y-3 transition ease-out">
          <div class="flex items-center">
            <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="37" height="37" viewbox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.9845 11.6702C33.7519 10.3368 32 9.60814 30.0543 9.60814H24.9767V6.75543C24.9767 6.2438 24.5581 5.8252 24.0465 5.8252H0.930233C0.418605 5.8252 0 6.2438 0 6.75543V27.2128C0 27.7244 0.418605 28.143 0.930233 28.143H4.63566C4.93798 29.864 6.43411 31.174 8.24031 31.174C10.0465 31.174 11.5426 29.864 11.845 28.143H24.0465H26.0853C26.3876 29.864 27.8837 31.174 29.6899 31.174C31.4961 31.174 32.9922 29.864 33.2946 28.143H36.0698C36.5814 28.143 37 27.7244 37 27.2128V17.6004C36.9922 15.143 36.3023 13.0888 34.9845 11.6702ZM1.86047 7.68566H23.1163V10.5384V26.2903H11.6822C11.1783 24.8795 9.82171 23.864 8.24031 23.864C6.65892 23.864 5.30233 24.8795 4.79845 26.2903H1.86047V7.68566ZM8.24031 29.3136C7.24806 29.3136 6.44186 28.5074 6.44186 27.5151C6.44186 26.5229 7.24806 25.7167 8.24031 25.7167C9.23256 25.7167 10.0388 26.5229 10.0388 27.5151C10.0388 28.5074 9.23256 29.3136 8.24031 29.3136ZM29.6899 29.3136C28.6977 29.3136 27.8915 28.5074 27.8915 27.5151C27.8915 26.5229 28.6977 25.7167 29.6899 25.7167C30.6822 25.7167 31.4884 26.5229 31.4884 27.5151C31.4884 28.5074 30.6822 29.3136 29.6899 29.3136ZM35.1318 26.2826H33.1318C32.6279 24.8717 31.2713 23.8562 29.6899 23.8562C28.1085 23.8562 26.7519 24.8717 26.2481 26.2826H24.9845V11.4686H30.062C33.1938 11.4686 35.1395 13.8174 35.1395 17.6004V26.2826H35.1318Z" fill="white"></path>
              </svg> */}
                                         <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class="mb-4 text-xl font-bold font-heading text-red-700">1 PUC & 2 PUC Board Exams</h3>
              <p class="text-gray-800">Physics- Chemistry- Mathematics- Biology- English- Kannada/Hindi/Sanskrit</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
        <div class="h-full p-6 md:p-12 border bg-red-50">
          <div class="flex items-center">
            <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="39" height="36" viewbox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.7601 5.67776C30.3819 2.38616 25.8883 0.572266 21.1139 0.572266C16.2512 0.572266 11.7014 2.44089 8.30713 5.83414C7.93802 6.20161 7.94604 6.79581 8.32318 7.16329C8.70032 7.52294 9.31017 7.51512 9.68731 7.14765C12.7205 4.12188 16.7727 2.45653 21.1139 2.45653C29.9165 2.44871 37.0742 9.42284 37.0742 17.9998C37.0742 26.5767 29.9165 33.5508 21.1139 33.5508C12.3113 33.5508 5.15359 26.5767 5.15359 17.9998V17.8043L6.7424 19.3524C6.92696 19.5322 7.17571 19.626 7.42446 19.626C7.67322 19.626 7.91395 19.5322 8.10653 19.3524C8.48367 18.9849 8.48367 18.3907 8.10653 18.0232L4.88077 14.8724C4.50363 14.5049 3.89378 14.5049 3.51664 14.8724L0.282856 18.0232C-0.0942853 18.3907 -0.0942853 18.9849 0.282856 19.3524C0.467414 19.5322 0.716167 19.626 0.96492 19.626C1.21367 19.626 1.4544 19.5322 1.64698 19.3524L3.23579 17.8043V17.9998C3.23579 22.6518 5.09742 27.0302 8.47565 30.3218C11.8539 33.6134 16.3475 35.4273 21.1219 35.4273C25.8964 35.4273 30.39 33.6134 33.7682 30.3218C37.1464 27.0302 39 22.6518 39 17.9998C39 13.3477 37.1384 8.96937 33.7601 5.67776Z" fill="white"></path>
                <path d="M20.4014 8C17.272 8 14.7283 10.4785 14.7283 13.5277V16.1938H12.9629C12.4333 16.1938 12 16.616 12 17.132V26.4908C12 27.0068 12.4333 27.429 12.9629 27.429H27.848C28.3776 27.429 28.8109 27.0068 28.8109 26.4908V17.1399C28.8109 16.6238 28.3776 16.2016 27.848 16.2016H26.0826V13.5355C26.0826 10.4863 23.5309 8 20.4014 8ZM16.6541 13.5355C16.6541 11.5183 18.3392 9.88427 20.4014 9.88427C22.4637 9.88427 24.1488 11.5262 24.1488 13.5355V16.2016H16.6541V13.5355ZM26.885 25.5526H13.9258V18.0703H26.885V25.5526Z" fill="white"></path>
              </svg> */}
                                        <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class="mb-4 text-xl font-bold font-heading  text-red-700">NEET</h3>
              <p class=" text-gray-800"> MBBS & BDS: State & National Level- B.V.Sc. : National Level- Ayush Courses : National Level</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
        <div class="h-full p-6 md:p-12 border bg-red-50">
          <div class="flex items-center">
            <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="31" height="37" viewbox="0 0 31 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.59532 15.086C9.59532 14.5819 9.18285 14.1694 8.67872 14.1694H1.33066C0.826531 14.1694 0.414062 14.5819 0.414062 15.086C0.414062 15.5902 0.826531 16.0026 1.33066 16.0026H8.67872C9.18285 16.0026 9.59532 15.5902 9.59532 15.086Z" fill="white"></path>
                <path d="M29.669 14.1694H22.3209C21.8168 14.1694 21.4043 14.5819 21.4043 15.086C21.4043 15.5902 21.8168 16.0026 22.3209 16.0026H29.669C30.1731 16.0026 30.5856 15.5902 30.5856 15.086C30.5856 14.5819 30.1807 14.1694 29.669 14.1694Z" fill="white"></path>
                <path d="M15.4996 9.18126C16.0037 9.18126 16.4162 8.76879 16.4162 8.26466V0.916598C16.4162 0.412469 16.0037 0 15.4996 0C14.9955 0 14.583 0.412469 14.583 0.916598V8.26466C14.583 8.76879 14.9955 9.18126 15.4996 9.18126Z" fill="white"></path>
                <path d="M20.3199 11.1828C20.5567 11.1828 20.7858 11.0911 20.9691 10.9155L26.1708 5.71376C26.5298 5.35476 26.5298 4.77425 26.1708 4.41525C25.8118 4.05625 25.2313 4.05625 24.8723 4.41525L19.6706 9.61694C19.3116 9.97594 19.3116 10.5565 19.6706 10.9155C19.8539 11.0911 20.0907 11.1828 20.3199 11.1828Z" fill="white"></path>
                <path d="M10.0305 10.9155C10.2062 11.0911 10.443 11.1828 10.6798 11.1828C10.9166 11.1828 11.1457 11.0911 11.329 10.9155C11.688 10.5565 11.688 9.97594 11.329 9.61694L6.12733 4.41525C5.76833 4.05625 5.18782 4.05625 4.82882 4.41525C4.46982 4.77425 4.46982 5.35476 4.82882 5.71376L10.0305 10.9155Z" fill="white"></path>
                <path d="M26.5146 20.8679C25.8195 20.2721 24.9411 19.9437 24.0474 19.9437H23.2149H20.2206H18.7999V16.2391C18.7999 14.467 18.2729 13.1761 17.2264 12.4046C15.5765 11.1978 13.3385 11.8776 13.2392 11.9082C12.8573 12.0304 12.5976 12.3817 12.5976 12.7789V17.2473C12.5976 18.607 11.9483 19.7603 10.6575 20.6846C9.67976 21.3873 8.67914 21.7081 8.54929 21.7463L8.45763 21.7692C8.1139 21.3797 7.60978 21.1352 7.04454 21.1352H3.56147C2.52266 21.1352 1.6748 21.9831 1.6748 23.0219V34.4488C1.6748 35.4876 2.52266 36.3355 3.56147 36.3355H7.05982C7.51812 36.3355 7.94586 36.1674 8.26667 35.8925C8.9694 36.5799 9.92419 37 10.9554 37H14.4308H14.7898H21.9011C23.001 37 23.971 36.7327 24.712 36.2362C25.6591 35.5946 26.2473 34.5787 26.4153 33.2802L27.8284 24.4961C28.0499 23.1365 27.5458 21.7463 26.5146 20.8679ZM7.11329 34.4488C7.11329 34.4794 7.09037 34.5023 7.05982 34.5023H3.56147C3.53092 34.5023 3.508 34.4794 3.508 34.4488V23.0219C3.508 22.9914 3.53092 22.9684 3.56147 22.9684H7.05982C7.09037 22.9684 7.11329 22.9914 7.11329 23.0219V34.4488ZM26.0181 24.2135L24.605 33.0128C24.605 33.0205 24.605 33.0357 24.5974 33.051C24.5363 33.5781 24.3377 35.1745 21.9011 35.1745H14.7898H14.4308H10.9554C9.97765 35.1745 9.12216 34.4412 8.9694 33.4711C8.96176 33.4329 8.95412 33.3947 8.94648 33.3642V23.5413L8.99995 23.526C9.01523 23.526 9.02287 23.5184 9.03814 23.5184C9.09161 23.5031 10.3901 23.1365 11.681 22.2122C13.476 20.9366 14.4308 19.218 14.4308 17.2473V13.5351C14.9349 13.4816 15.63 13.5046 16.1418 13.8788C16.6917 14.2837 16.9667 15.0781 16.9667 16.2314V20.8526C16.9667 21.3568 17.3792 21.7692 17.8833 21.7692H20.2206H23.2149H24.0474C24.5057 21.7692 24.9564 21.9449 25.323 22.2504C25.873 22.724 26.1327 23.4726 26.0181 24.2135Z" fill="white"></path>
              </svg> */}
                                        <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class="mb-4 text-xl font-bold font-heading  text-red-700">NEST</h3>
              <p class=" text-gray-800"> National Entrance Screening test for NISER</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-3 mb-6 lg:mb-0">
        <div class="h-full p-6 md:p-12 border bg-red-50">
          <div class="flex items-center">
            <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="35" height="37" viewbox="0 0 35 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.1582 14.0693V12.6468C30.1582 9.24223 28.9534 6.07857 26.7691 3.73109C24.5305 1.32143 21.4523 0 18.0944 0H16.9206C13.5626 0 10.4845 1.32143 8.24583 3.73109C6.06158 6.07857 4.85675 9.24223 4.85675 12.6468V14.0693C2.20612 14.2481 0.0996094 16.4557 0.0996094 19.1529V21.3761C0.0996094 24.1821 2.3849 26.4674 5.191 26.4674H8.05927C8.5723 26.4674 8.99205 26.0477 8.99205 25.5347V14.9866C8.99205 14.4735 8.5723 14.0538 8.05927 14.0538H6.7223V12.6468C6.7223 6.49832 11.1063 1.86555 16.9128 1.86555H18.0866C23.9009 1.86555 28.2771 6.49832 28.2771 12.6468V14.0538H26.9402C26.4271 14.0538 26.0074 14.4735 26.0074 14.9866V25.5269C26.0074 26.0399 26.4271 26.4597 26.9402 26.4597H28.246C27.8652 31.3256 24.5149 32.4527 22.9603 32.7092C22.5328 31.3956 21.2969 30.4473 19.8433 30.4473H17.5114C15.708 30.4473 14.2389 31.9164 14.2389 33.7197C14.2389 35.5231 15.708 37 17.5114 37H19.8511C21.3591 37 22.6261 35.9739 23.007 34.5903C23.7687 34.4815 24.9736 34.2095 26.1706 33.5099C27.8574 32.5227 29.8551 30.5095 30.1194 26.4519C32.7855 26.2887 34.8998 24.0733 34.8998 21.3683V19.1452C34.9076 16.4557 32.8089 14.2403 30.1582 14.0693ZM7.14205 24.5941H5.20654C3.4265 24.5941 1.9807 23.1483 1.9807 21.3683V19.1452C1.9807 17.3651 3.4265 15.9193 5.20654 15.9193H7.14205V24.5941ZM19.8511 35.1345H17.5114C16.7341 35.1345 16.1044 34.5048 16.1044 33.7275C16.1044 32.9502 16.7341 32.3206 17.5114 32.3206H19.8511C20.6284 32.3206 21.258 32.9502 21.258 33.7275C21.258 34.5048 20.6284 35.1345 19.8511 35.1345ZM33.042 21.3683C33.042 23.1483 31.5962 24.5941 29.8162 24.5941H27.8807V15.9193H29.8162C31.5962 15.9193 33.042 17.3651 33.042 19.1452V21.3683Z" fill="white"></path>
              </svg> */}
                                        <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class="mb-4 text-xl font-bold font-heading  text-red-700">K-CET (State Level Admissions)</h3>
              <p class=" text-gray-800">Engineering- B.V.Sc- B.Sc. Ag- B.Pharma- D.Pharma</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-3">
        <div class="h-full p-6 md:p-12 border bg-red-50">
          <div class="flex items-center">
            <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="37" height="29" viewbox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0991 22.5451H2.54649C2.14481 22.5451 1.81892 22.2192 1.81892 21.8176V7.97858H32.7785V9.79751C32.7785 10.2977 33.1878 10.707 33.688 10.707C34.1882 10.707 34.5974 10.2977 34.5974 9.79751V3.2418C34.5974 1.83972 33.453 0.695312 32.0509 0.695312H2.54649C1.13683 0.695312 0 1.83972 0 3.2418V21.8176C0 23.2196 1.14441 24.364 2.54649 24.364H20.1067C20.6069 24.364 21.0161 23.9548 21.0161 23.4546C21.0161 22.9544 20.6069 22.5451 20.0991 22.5451ZM2.54649 2.51424H32.0509C32.4526 2.51424 32.7785 2.84013 32.7785 3.2418V6.15966H1.81134V3.2418C1.81134 2.84013 2.14481 2.51424 2.54649 2.51424Z" fill="white"></path>
                <path d="M18.2046 14.7312C18.2046 14.231 17.7954 13.8218 17.2952 13.8218H6.36649C5.86629 13.8218 5.45703 14.231 5.45703 14.7312C5.45703 15.2314 5.86629 15.6407 6.36649 15.6407H17.2952C17.803 15.6407 18.2046 15.2314 18.2046 14.7312Z" fill="white"></path>
                <path d="M6.36649 17.0806C5.86629 17.0806 5.45703 17.4898 5.45703 17.99C5.45703 18.4902 5.86629 18.8995 6.36649 18.8995H10.7395C11.2397 18.8995 11.6489 18.4902 11.6489 17.99C11.6489 17.4898 11.2397 17.0806 10.7395 17.0806H6.36649Z" fill="white"></path>
                <path d="M6.26005 12.3438H17.1887C17.6889 12.3438 18.0982 11.9346 18.0982 11.4344C18.0982 10.9342 17.6889 10.5249 17.1887 10.5249H6.26005C5.75984 10.5249 5.35059 10.9342 5.35059 11.4344C5.35059 11.9346 5.75226 12.3438 6.26005 12.3438Z" fill="white"></path>
                <path d="M36.0982 18.6043H34.7644V16.4822C34.7644 13.8523 32.6196 11.7075 29.9897 11.7075C27.3598 11.7075 25.215 13.8523 25.215 16.4822V18.6043H23.8811C23.3809 18.6043 22.9717 19.0135 22.9717 19.5137V27.3957C22.9717 27.8959 23.3809 28.3052 23.8811 28.3052H36.0907C36.5909 28.3052 37.0001 27.8959 37.0001 27.3957V19.5137C37.0077 19.0059 36.5984 18.6043 36.0982 18.6043ZM27.0339 16.4746C27.0339 14.8452 28.3602 13.5189 29.9897 13.5189C31.6191 13.5189 32.9454 14.8452 32.9454 16.4746V18.5967H27.0339V16.4746ZM35.1888 26.4787H24.7906V20.4156H35.1812V26.4787H35.1888Z" fill="white"></path>
              </svg> */}
                                         <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class="mb-4 text-xl font-bold font-heading  text-red-700">KVPY (Scholarship cum admission Test) </h3>
              <p class="text-gray-800"> IISc- IISERs </p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-3">
        <div class="h-full p-6 md:p-12 border bg-red-50">
          <div class="flex items-center">
          <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="37" height="37" viewbox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.9845 11.6702C33.7519 10.3368 32 9.60814 30.0543 9.60814H24.9767V6.75543C24.9767 6.2438 24.5581 5.8252 24.0465 5.8252H0.930233C0.418605 5.8252 0 6.2438 0 6.75543V27.2128C0 27.7244 0.418605 28.143 0.930233 28.143H4.63566C4.93798 29.864 6.43411 31.174 8.24031 31.174C10.0465 31.174 11.5426 29.864 11.845 28.143H24.0465H26.0853C26.3876 29.864 27.8837 31.174 29.6899 31.174C31.4961 31.174 32.9922 29.864 33.2946 28.143H36.0698C36.5814 28.143 37 27.7244 37 27.2128V17.6004C36.9922 15.143 36.3023 13.0888 34.9845 11.6702ZM1.86047 7.68566H23.1163V10.5384V26.2903H11.6822C11.1783 24.8795 9.82171 23.864 8.24031 23.864C6.65892 23.864 5.30233 24.8795 4.79845 26.2903H1.86047V7.68566ZM8.24031 29.3136C7.24806 29.3136 6.44186 28.5074 6.44186 27.5151C6.44186 26.5229 7.24806 25.7167 8.24031 25.7167C9.23256 25.7167 10.0388 26.5229 10.0388 27.5151C10.0388 28.5074 9.23256 29.3136 8.24031 29.3136ZM29.6899 29.3136C28.6977 29.3136 27.8915 28.5074 27.8915 27.5151C27.8915 26.5229 28.6977 25.7167 29.6899 25.7167C30.6822 25.7167 31.4884 26.5229 31.4884 27.5151C31.4884 28.5074 30.6822 29.3136 29.6899 29.3136ZM35.1318 26.2826H33.1318C32.6279 24.8717 31.2713 23.8562 29.6899 23.8562C28.1085 23.8562 26.7519 24.8717 26.2481 26.2826H24.9845V11.4686H30.062C33.1938 11.4686 35.1395 13.8174 35.1395 17.6004V26.2826H35.1318Z" fill="white"></path>
              </svg> */}
                                        <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class="mb-4 text-xl font-bold font-heading  text-red-700">AIEEA </h3>
              <p class="text-gray-800"> National Level B.Sc.Ag courses by ICAR </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* ----------------------------------------------------------------------------- */}
<section class="relative py-10 2xl:py-10 overflow-hidden ">
  <img class="hidden md:block absolute top-0 left-0 px-20" src={Ban1} alt=""/>
  <div class="relative container px-4 mx-auto">
    <div>
      <div class="flex flex-wrap -mx-10 md:px-28">
        <div class="relative w-full lg:w-1/2 px-10">
          <img class="lg:hidden absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt=""/>
          <div class="px-10 pt-3 pb-5 rounded-lg">
            <h3 class=" mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Start Date</h3>
            <p class="text-lg text-gray-800 md:text-white mb-2">Every year – First Week of May</p>
            <p class="text-lg text-gray-800 md:text-white">This Year COVID  Delay – Third week of June/First week of July</p>
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>

        <div class="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <img class="hidden lg:block absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt=""/>
          <div class="px-10 pt-3 pb-5 rounded-lg">           
            <h3 class=" mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Selection Procedure</h3>
            <p class="text-lg text-gray-800 md:text-white">ESSAT (EduShrine Scholarship cum Admission Test)</p>
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>

        <div class="w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <div class="px-10 pb-5  rounded-lg">        
            <h3 class="md:mt-4 mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Eligibility</h3>
            <p class="text-lg text-gray-800 md:text-white">Class-X from any board with minimum of 75% marks</p>
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>
        <div class=" ml-32 md:ml-20  text-center"><a class="inline-block py-5 px-12 mr-4 text-xl bg-red-900 hover:bg-orange-600 rounded-lg text-white font-bold transition duration-200" href="/contact">Enroll now</a></div>
      </div>
    </div>
  </div>
</section>

{/* ------------------------------------------------------------------------------ */}
<section class="relative pt-10 pb-20 md:pb-28 overflow-hidden  bg-gradient-to-b from-gray-900 via-gray-900 to-red-800"  style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
  <p class="mb-10  text-center text-3xl md:text-4xl text-white font-bold">Program <span className='text-red-600'>Locations</span></p>
    <div class="flex flex-wrap max-w-6xl mx-auto -m-7">
      <div class="w-full md:w-1/2 xl:w-1/3 p-7 ">
        <div class=" text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
          <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg> </div>
        <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Nagadevanahalli (Kengeri) – Bengaluru</h3>
          <p class="text-sm text-gray-500">7090772512</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="xl:relative xl:top-16 text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg></div> 
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Rajajinagar – Bengaluru </h3>
          <p class="text-sm text-gray-500">7090772503 / 8217447029</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg></div>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Jayanagar – Bengaluru</h3>
          <p class="text-sm text-gray-500">7090772509</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg> </div>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Sarjapura – Bengaluru</h3>
          <p class="text-sm text-gray-500">7090772507/9066642288</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="xl:relative xl:top-16 text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg> </div>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Tumkur</h3>
          <p class="text-sm text-gray-500">987654321</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg> </div>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Madhugiri – Tumkur-Dt</h3>
          <p class="text-sm text-gray-500">8296022825</p>
        </div>
      </div>
    </div>
  </div>
</section>
{/* <div class="mt-14 lg:mt-12 text-center"><a class="inline-block py-5 px-12 mr-4 bg-red-900 hover:bg-blue-600 rounded-full text-white font-bold transition duration-200 text-xl" href="#">Program Locations</a></div> */}
{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
 
  <div class="container mx-auto md:px-28 pb-10 md:pb-16 md:py-24 bg-white" style={{fontFamily:'Manrope'}}>
    <div class="flex flex-wrap justify-between -m-6">
      <div class="w-full lg:w-5/12 xl:w-1/2 p-6">
        {/* <p class="mb-20 text-center text-4xl text-gray-800 font-bold">Course <span className='text-red-600'> Highlights</span></p> */}
        <div class="w-full mt-4 lg:w-5/12 xl:w-1/2 p-6 lg:mt-24">
        {/* <p class=" mb-4 text-sm md:text-md font-heading text-gray-800 font-medium ">The course is primarily based on practical work</p> */}
        <p class="mb-12 text-center text-3xl md:text-7xl text-gray-800 font-bold">Course <span className='text-red-600'> Highlights</span></p>
        <div class="flex flex-wrap -m-3 lg:w-96 pl-4 md:mb-20">

      <p className=' mb-10 md:mb-20 text-left text-lg text-gray-800 font-semibold '> Edu-Shrine’s Study Material is the key factor of its Academic Programme. The Study Material by itself generates a System that enables the Management to monitor Faculty and Faculty and Parents to monitor the Students.</p>
        </div>
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
           
          </div>
        </div>
        
      </div>
        
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
          </div>
        </div>
      </div>
      <div class="w-auto lg:w-7/12 xl:w-1/2  p-2 md:p-6">
        <div class="max-w-max mx-auto">
          <div class="flex flex-wrap justify-center -m-3 mb-3">
            <div class="w-full p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                   <div class="w-28 p-2">
                    <img src={Img1} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800 ">Meticulous<br/> Schedule </p>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img2} alt=""/>
                  </div>
                  <div class="w-auto p-2">
                    <p class="font-heading font-bold text-gray-800">Highly <br/>Researched & <br/>Developed Study <br/>content</p>
                 
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 md:bg-red-500 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img3} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-black md:text-white">Passion-driven <br/>faculty team </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3 xl:-ml-20">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 md:bg-red-500  transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img4} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-black md:text-white"> Comprehensive <br/>Assessment</p>
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Shedule} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800">Rigorous Monitoring <br/>& Mentoring System </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Monitor} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-3">
                    <p class="font-heading font-bold text-gray-800">Student Counseling<br/> & Mental<br/> Strengthening</p>
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 md:bg-red-500 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Study} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-3">
                    <p class="font-heading font-bold text-black md:text-white">One Stop <br/>solution for all the <br/>academic needs </p>
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img4} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800">No need for any <br/>additional tuitions </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

       

{/* ----------------------------------------------------------------------------------------------- */}
<section class="pt-10 pb-24 px-10 md:px-24 bg-red-50 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
  <p class="mb-20 text-center text-3xl md:text-5xl text-gray-800 font-bold">Why <span className='text-red-600'> EDUShrine?</span></p>

  {/* <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-6xl sm:text-3xl text-red-700">Why EDUShrine?</h2> */}
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teacher's Institution</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Ethical Coaching Practices</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Student-Centered, Dynamic Academic System</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Perfect balance between Education & Training</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Concept-Oriented, Self-Sufficient Teaching System</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">6</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teachers with genuine empathy towards student's growth</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">7</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Cordial Relationship with parents and students</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">8</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Most Reasonable fee structure for the quality delivered</h3>
      </div>
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------------------ */}

<h2 class=" ml-20 md:ml-44 pt-8 text-3xl md:text-5xl font-bold font-heading tracking-px-n text-gray-900 "><span className='text-red-600'> Course </span> Overview</h2>

<section class="pt-10 pb-10 md:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       
       <div class="container px-4 mx-auto">
         <div class="md:flex md:grid-cols-2">
         <div class=" md:w-1/3 md:p-8 ">
     
             <img class="hidden md:block md:mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src={Overview} alt=""/> ;
     
                         </div>
           <div class="w-full md:w-1/3 md:px-12 md:mt-12 text-gray-900 ">
             
             <div class="md:max-w-lg">
               <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">20 Months Course with 7-Months in I PUC & 13-Months in II PUC</h3>
                       </div>
                     </div>
                   </div>
                 </div>
              
            
               </div>
               <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Theory + Objective + Practical in each subject as may be applicable</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
               
            <div class="flex flex-wrap -m-5 mb-2 ">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">7 to 8 Hours of Classes per day, 6 days as week</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
            
            <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">3 Months of Pre-Board Revision course during II PUC after syllabus completion for both Board and NEET/K-CET exam</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
            </div>
           </div>
     
           <div class="w-full md:w-1/3 md:px-14 md:mt-12 text-gray-900">
           <div class="md:max-w-lg">
           <div class="flex flex-wrap -m-5 mb-2">
               <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">2 Months of Post-Board Crash Course for Entrance Exams</h3>
                       </div>
                     </div>
                   </div>
                 </div>
     
           <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">6</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Over 80 Topic-Wise & 15 Cumulative Board Exam Tests in Two Years</h3>
                       </div>
                     </div>
                   </div>
                 </div>
     
           <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">7</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Over 100 Topic-Wise Mock Tests & 30 Cumulative Mock Tests for Entrances</h3>
                       </div>
                     </div>
                   </div>
                 </div>
              </div>
           </div>
             </div>
         </div>
       </div>
     </section>









{/* ------------------------------------------------------------------------------------------------------- */}
<section class="bg-white py-5 md:py-20 md:px-24" style={{fontFamily:'manrope'}}>
  <div class="container mx-auto">
    <div class="relative py-11 md:px-16 rounded-md overflow-hidden">
    <img class="hidden md:block absolute top-0 left-0 px-20" src={Ban1} alt=""/>

      <div class="relative z-20 flex flex-wrap items-center">
        <div class="w-full md:w-1/2">
        <p class=" md:mt-5 mb-10 px-16 text-3xl md:text-5xl text-gray-800 md:text-white font-bold">Get EduShrine<br/><span className='text-gray-800 md:text-white'> Advantage</span></p>
        </div>
        <div class="w-full md:w-1/2 p-3">
                    <p class="font-semibold text-xl text-gray-800 md:text-white text-center">"Academic Scholarships worth<i>1 Crore. </i>"</p>
                              <p class=" mt-5 md:mt-0 font-semibold text-xl px-10  text-gray-800 md:text-white">Up to 100% Scholarship on the Fee based on the performance in ESSAT </p>

          <NavLink to='/contact'>
          <button class=" ml-20 block mt-3 md:ml-60 px-10 py-3 font-semibold text-lg text-white bg-red-600 hover:bg-orange-600 rounded-md">Contact Now</button>
          </NavLink>
        </div>
      </div>
    </div>
  </div>
</section>
{/* -------------------------------------------------------------------------------------- */}

<Stories/>
{/* -------------------------------------------------------------------------------------- */}


<div class="p-4 pt-8 md:pt-16 pb-12 md:px-24 " style={{fontFamily:'manrope'}}>
<p class="mb-10 md:mb-20 text-center text-3xl md:text-5xl text-gray-800 font-bold">Watch Online <span className='text-red-600'> Demo Class</span></p>
  <div class="aspect-w-16 aspect-h-8 rounded-lg md:pl-32 block md:hidden">
      <iframe width="360" height="200"   src="https://www.youtube.com/embed/O5cnEBUvQnI" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      <div class="aspect-w-16 aspect-h-8 rounded-lg md:pl-32 hidden md:block">
      <iframe width="1220" height="520"   src="https://www.youtube.com/embed/O5cnEBUvQnI" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>

{/* -------------------------------------------------------------------------------------------- */}


{/* ---------faq */}

<section class="py-12 md:py-12 md:px-28 bg-gray-100">
<h2 class="mt-4 pb-8 text-3xl md:text-9xl font-bold font-heading opacity-30 text-center md:text-left ">Frequently asked <span className='text-red-600 '>questions</span></h2>
  <div class="container px-4 mx-auto">
  <div class="flex flex-wrap -mx-4 mb-12">
      <div class="w-full lg:w-full md:px-28 md:mb-8 lg:mb-0">
  <Accordion allowMultipleExpanded={false} allowZeroExpanded={true}>

        
{heading.map((item,i) => (
    <AccordionItem key={i}>

      
        <AccordionItemHeading>
            <AccordionItemButton>
              <div class="block w-full px-6 sm:px-16 pb-2 pt-8 sm:pt-6 mb-8 text-left bg-white rounded-xl relative">
            <button >
          <span class="absolute top-0 right-0  m-6 md:m-8">
            <svg width="40" height="40" viewbox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="18.75" fill="white" stroke="#FF0000" stroke-width="2.5"></circle>
              <g clip-path="url(#clip0_1312_4108)">
                <path d="M26.6344 18.6468H21.5375H20.0683C19.0459 18.6468 20.1541 18.6473 19.3978 18.6473C18.6416 18.6473 18.0664 18.6476 18.8063 18.6467L18.8 18.6468H13.3625C12.6125 18.6468 12 19.2593 12 20.0156C12 20.7718 12.6125 21.3843 13.3687 21.3843H18.8063L18.5 20.5002C18.5 21.2565 18.05 21.3843 18.8063 21.3843C19.5625 21.3843 18.5 21.3843 17 21.3843H21.5437H26.6406C27.3969 21.3843 28.0094 20.7718 28.0094 20.0156C28 19.2593 27.3906 18.6468 26.6344 18.6468Z" fill="#FF0000" ></path>
              </g>
              <defs><rect width="16" height="16" fill="white" stroke="#FF0000" transform="translate(12 12)"></rect></defs>
            </svg>
          </span>
          {/* <span class="text-sm text-gray-500">Question #1</span> */}
          <h4 class=" float-left text-lg md:text-3xl font-bold text-red-700 mt-2 mb-2">{item.question}</h4>
        
        </button>    </div>          
        
        </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
        <div class="block w-full px-8 sm:px-14 pb-12 pt-16 sm:pt-12 mb-8 text-left bg-white rounded-xl relative">
        <p class="max-w-full font-semibold text-xl text-gray-800">{item.answer}</p>
       
        </div>
        </AccordionItemPanel>
    </AccordionItem>
))}
</Accordion>
  
    
</div>
     
     </div>
   </div>
 </section>


{/* --------------------------------------------------------------------------------------- */}
<section class="py-10 md:px-28 bg-white" style={{fontFamily:'manrope'}}>
  <div class="container px-8 mx-auto">
    <div class="flex flex-wrap -mx-4">
      <div class="w-full px-4 mb-10">
        <div class=" justify-between items-center">
          <div class="w-full md:w-full mb-10 md:mb-0">
          {/* <p class="mb-20 text-center text-4xl text-gray-800 font-bold">Why <span className='text-red-600'> EDUShrine?</span></p> */}
            <h3 class="mb-5 md:mb-0 p-0 md:p-8 text-3xl md:text-4xl text-gray-800 font-bold text-center">Study <span className='text-red-600'> Material</span></h3>
            <p class="text-lg md:text-xl leading-8 text-gray-700 font-semibold">Edu-Shrine’s Study Material is the key factor of its Academic Programme. The Study Material by itself generates a System that enables the Management to monitor Faculty and Faculty and Parents to monitor the Students.</p>
          </div>
         
        </div>
      </div>
      <h3 class=" ml-5 md:ml-0 mb-4 text-3xl md:text-3xl text-red-600 font-semibold">Edu-Shrine Practice 'n' Learn (EPL);<span className='text-gray-800'>A Unique Study Material<br/> Cum Workbook</span></h3>

      <div class="w-full lg:w-1/2 px-4 mb-5 lg:mb-0">
        <p class="mb-5 text-lg font-medium leading-7 text-gray-700">Major reason for students not performing their best in academics is not experiencing the subject as soon as it is taught. There can be no debate on the fact that “Sooner they revise Longer they retain”. The EPL aims at achieving this. The book ensures that students experience the subject as soon as teacher teaches it and takes all the measures to even monitor their progress with the subject.</p>
        <h3 class="mb-4 text-3xl md:text-3xl text-gray-800 font-semibold ">Features of EPL</h3>
       
            <li><span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b>Sessions:</b> Each chapter is taught in the form of selected number of sessions, each containing a sub topic that covers similar concepts.</span></li>
        <li><span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Synopsis:</b> Lucid yet comprehensive synopsis. Everything is not readily in printed form. Certain concepts like mathematical derivations, graphs, diagrams etc to be filled in by students while understanding the lesson.</span></li>
        <li><span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Review Exercise:</b>  A set of questions to be solved in the presence of teacher to revise previous concepts that need to be mastered to understand the upcoming concepts.</span></li>
        <li><span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Learning Exercise:</b>  A set of application based questions from a given concept which should be solved in the presence of teacher as soon as the concept is taught.</span></li>
<li> <span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Numerical Exercise:</b>  A set of problem based questions to be solved in the presence of teacher after a formula based concept is taught.</span></li>
<li><span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Home Exercise:</b>  Additional practice for what students have learnt from Learning and Numerical Exercises to be solved at home. Questions in Home Exercise will be a replica of those in Learning and Numerical Exercises to build confidence.</span></li>
<li> <span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Exam Corner:</b>  An insight into all the possible questions in Board Exams from the concepts taught so far. Application & Reasoning based questions to be answered in EPL book at home. Knowledge & Understanding based questions have to be studied from synopsis and learnt.</span></li>
       <li> <span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Additional Concepts for Entrances:</b>  Printed separately in boxes for students to differentiate the curriculum and study based on the test they are preparing for.</span></li>
       <li><span class="mb-5 text-lg font-medium leading-7 text-gray-700"><b> Mind Map:</b>  A sequential sum up of all the topics sub topics and important formulae of the chapter. Highly useful for students to evaluate their familiarity with the concepts at the end of the chapter or before any examination they face.</span></li>
          
        <h3 class="mb-4 mt-5 text-3xl md:text-3xl text-gray-800 font-bold ">Assessment  System</h3>
            <p class="mb-5 text-lg font-medium leading-7 text-gray-700">Practice through tests is the key for success in examinations. Our testing pattern ensures better performance of students both in board exams and entrance exams.</p>
      </div>
      <div class="w-full lg:w-1/2 px-4 md:py-10">
        <p class="mb-5 text-lg font-semibold leading-7 text-gray-700">
        <h3 class="mb-4 text-3xl md:text-3xl text-Gray-800 font-bold ">Advantages of EPL</h3>

          <ol class="mb-5 list-decimal list-inside text-lg font-medium leading-7 text-gray-700">
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b>Equal Justice to all the Chapters:</b> Since EPL is prepared as per the Macro Plan, distribution of exercises and questions will be done as per the blue print to do justice to all the chapters in practice.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b>Equal Justice to all the Batches:</b> Since there are highly Learning centred exercises, students will digest the concepts well, irrespective of experience or the effectiveness of the Lecturer teaching them.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b>Lesser Books carrying burden to students:</b> Since one EPL book per chapter is given, students can carry only the relevant book to the class.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b>Easy Monitoring of Students:</b> As the space is provided below the questions for solving, Lecturer and Parent can monitor easily if students skip the exercises.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b>Easy Revision in case of Absentees:</b> If students are getting absent on a particular day, missed out concepts are printed and exercises are available to ensure immediate learning of the concepts.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b>Convenient and Effective Completion of Syllabus:</b> Since the burden of Notes and Questions dictation is reduced for lecturers, they can spend more time on ensuring understanding of concepts and finish syllabus well within stipulated time.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700"><b> Enhancement in the Performances of Students:</b> As the efforts and involvement of students is closely monitored, students standards will be uplifted much better as compared to the conventional methods.</span>
            </li>
          </ol>
        </p>
        <h3 class="mb-4 text-3xl md:text-3xl text-coolGray-900 font-bold">Edu-Shrine's Objective Study Material
</h3>
<li>
              <span class="text-lg font-medium leading-8 text-gray-700">A separate objective study material will be provided to complement EPL for Entrance Exam Preparation.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">Two levels of Objective Study Material will be provided by Edu-Shrine.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">EduShrine-Zenith
For Initial level objective Practice that helps in effectively answering exams like CET/COMED-K etc.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">EduShrine-Transcend
JEE/NEET Level Material to ensure higher level practice and rank enhancement.</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">Questions are provided with sub-topic wise seggregation.
</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">Each Chapter contains GUIDANCE CORNER, a set of class work questions covering all the possible models.
</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">Guidance corner is supported by PRACTICE CORNER, all the models repeated with large number of questions for self practice at home.
</span>
            </li>
            <li>
              <span class="text-lg font-medium leading-8 text-gray-700">Detailed hints and solutions are provided for all the questions of practice corner.
</span>
            </li>
           

      </div>
    </div>
  </div>
</section>

{/* ---------------------------------------------------------------------------------------------------- */}
<section class="bg-gray-50 py-8 p-5 md:px-28 hidden md:block" style={{fontFamily:'manrope'}}>
  <table class="table-auto w-full bg-white shadow rounded">
    <thead class="border-b border-gray-900 ">
      <tr>
        <th class="pl-6 py-6 border-r border-gray-200">
          <a class="flex items-center text-lg text-red-600" href="#">
            <p>Type of test</p>
            
          </a>
        </th>
        <th class="pl-6 border-r border-gray-200">
          <a class="flex items-center justify-center text-lg text-red-600" href="#">
            <p>Description</p>
           
          </a>
        </th>
        <th class="pl-6 border-r border-gray-200">
          <a class="flex items-center justify-center text-lg text-red-600" href="#">
            <p>No of subjects</p>
          
          </a>
        </th>
        <th class="pl-6 border-r border-gray-200">
          <a class="flex items-center text-lg justify-center text-red-600" href="#">
            <p>Day of conduct</p>
           
          </a>
        </th> 
      </tr>
    </thead>
    <tbody className='text-gray-900 font-semibold'>
      <tr class="text-semibold border">
        <td class="pl-6 py-6 border-l-0 border border-gray-200"> GTE</td>
        <td class="pl-6 border border-gray-200 text-red-600 font-semibold">	Grand Test for Entrance exams:
<span class="text-gray-800 font-medium"> (An objective test to provide a firsthand experience in writing entrance exams)</span> </td>
        <td class="pl-6 border border-gray-200">3 subjects-PCM (JEE)PCB (NEET) </td>
        <td class="text-center border border-gray-200">After completion three cycles of PTEs in all the subjects</td>
        
      </tr>
      <tr class="text-semibold border">
        <td class="pl-6 py-6 border-l-0 border border-gray-200">PTE</td>
        <td class="pl-6 border border-gray-200 text-red-600 font-semibold">Periodic Test for Entrance Exams: <span class='text-gray-800 font-medium'>(An objective test to ensure time management skills and conceptual strength for competitive exams)</span></td>
        <td class="pl-6 border border-gray-200">(An objective test to ensure time management skills and conceptual strength for competitive exams)subjects- PCM (JEE)PCB (NEET)</td>
        <td class="text-center border border-gray-200">Every Monday</td>
        
      </tr>
      <tr class="text-semibold border">
        <td class="pl-6 py-6 border-l-0 border border-gray-200">PTB</td>
        <td class="pl-6 border border-gray-200 text-red-600 font-semibold">	Periodic Test for Board Exam <span class='text-gray-800 font-medium'>(A subjective test to ensure good presentation practice and scoring in board exams)</span></td>
        <td class="pl-6 border border-gray-200">Any one subject</td>
        <td class="text-center border border-gray-200">Every Saturday </td>
        
      </tr>
      <tr class="text-semibold">
        <td class="pl-6 py-6 border border-gray-200">Pre-Final Examination*</td>
        <td class="pl-6 border-l border-r border-gray-200">A final Grand test for the Board examination</td>
        <td class="pl-6 border-l border-r border-gray-200">	1 subject per day</td>
        <td class="text-center border border-gray-200">Tentative</td>
        
      </tr>
      <tr class="text-semibold">
        <td class="pl-6 py-6 border border-gray-200">GTB Quarterly Examination</td>
        <td class="pl-6 border-l border-r border-gray-200">	A Grand test for the Board examination</td>
        <td class="pl-6 border-l border-r border-gray-200">1 subject per day</td>
        <td class="text-center border-l border-r border-gray-200">Will be Mentioned in the schedule</td>
             </tr>

      <tr class="text-semibold">
        <td class="pl-6 py-6 border border-gray-200">GTB Mid Term Examination</td>
        <td class="pl-6 border-l border-r border-gray-200">A Grand test for the Board examination</td>
        <td class="pl-6 border-l border-r border-gray-200">1 subject per day</td>
        <td class="text-center border-l border-r border-gray-200">Will be Mentioned in the schedule</td>
             </tr>
    </tbody>
  </table>
</section>

  </div>
  )
}
