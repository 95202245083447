import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { fetchAllnews, newsSelector} from '../../api/news'

import {Link,useParams} from 'react-router-dom'
 

export default function News() {



  const {id} = useParams()
  console.log(id)

  const dispatch = useDispatch()
  const {all_news, current_news} = useSelector(newsSelector) 
  


  console.log({all_news,current_news});

  
useEffect(()=>{
  dispatch(fetchAllnews(id))
}, [id])


  return (
    <div className='bg-white'>




    <section class="pt-5 md:pt:20 pb-16 md:pb-32 px:4 md:px-24 bg-white overflow-hidden">
      <div class="container mx-auto px-4">
        <div class="md:max-w-lg mx-auto text-center mb-10">
        <h2 class="mb-4 font-heading font-bold text-black text-4xl sm:text-5xl">Latest <span className='text-red-600'>News</span> </h2>
          <p class="text-xl text-gray-800">EDUSHRINE :: Intensive Coaching...Stress Free Learning</p>
        </div>
        
      </div>
    
    {/* ----------------------------------------------------- */}
    <div class=" max-w-screen-xl mx-auto grid md:grid-cols-3 gap-7  md:mt-20 "  style={{fontFamily:'Manrope'}} >
            {
              all_news.map((item,i)=>{
                    return <Link to={`/news/${item?._id}`} className=' h-68 w-full p-2  mx-auto rounded bg-slate-50 shadow-md'>
                                
                                <div>
                                <img class="transform hover:scale-105 transition ease-in-out rounded-lg duration-1000" src={item?.image} alt=""/>
    
                                <p class="mr-8 mt-3 text-xl text-gray-900 inline-block font-bold " aria-current="page">{item?.title}</p>
                                <div>
                                </div>
                                </div>
                          </Link>
                })
            }
        </div>
    
            </section> 
    </div>
  )
}
