import React from 'react'
import Fig from '../images/margadarshi.png'
import Logo from '../images/empower.png'
import Logo1 from '../images/LOL.png'
import Logo2 from '../images/bhavishya.png'

import Logo4 from '../images/EYC.png'
import Logo5 from '../images/Pathashaala.png'
import Empower from '../images/student.jpg'

import LOL from '../images/lol.jpg'
import Bhavishya from '../images/bhavishya.jpg'
import EYC from '../images/eyc.jpg'
import Pathashaala from '../images/patha.png'

import image1 from '../images/empower1.jpg'
import image2 from '../images/empower2.jpg'
import image3 from '../images/empower3.jpg'
import image4 from '../images/lol1.jpg'
import image5 from '../images/lol2.jpg'
import image6 from '../images/lol3.jpg'
import image7 from '../images/lol4.jpg'
import image8 from '../images/lol5.jpg'
import image9 from '../images/lol6.jpg'
import image10 from '../images/bhavishya1.jpg'
import image11 from '../images/bhavishya2.jpg'
import image13 from '../images/eyc1.jpg'
import image14 from '../images/eyc2.jpg'
import image15 from '../images/eyc3.jpg'
import image16 from '../images/patha1.jpg'
import image17 from '../images/patha2.jpg'


export default function Margadarshi() {
  return (
    <div>
              <div className='relative hidden sm:block object-cover bg-cover w-full sm:w-full   justify-center items-center' style={{backgroundImage:`url(${Fig})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}>
</div>
        <section class=" py-4 sm:py-20 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <h2 class="mb-5 text-4xl sm:text-7xl text-black xl:text-10xl text-center font-bold font-heading font-heading tracking-px-n leading-none">The<span className='text-red-600'>  Margadarshi</span></h2>
    <p class="mb-4 text-md sm:text-2xl  text-black text-center font-semibold leading-normal md: max-w-5xl mx-auto underline">Guidance & Counselling For Schools </p>
   
  </div>
</section>

<section class="px-8 pb-28 pt-1  sm:pt-4 sm:pb-32 sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="hidden sm:block container mx-auto px-4">
    <div class="flex grid-cols-6 justify-center -m-10">
      <a href='#empower'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
        <img src={Logo} alt=""/>
      </div></a>
      <a href='#LOL'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo1} alt=""/>
      </div></a>
      <a href='#bhavishya'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo2} alt=""/>
      </div></a>
    
      <a href='#eyc'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo4} alt=""/>
      </div></a>
      <a href='#pathashaala'><div class="w-auto h-auto p-6 pt-20 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo5} alt=""/>
      </div></a>

    </div>
  </div>

  <div class="block sm:hidden container mx-2 ">
    <div class=" justify-center grid grid-cols-2 ">
      <a href='#empower'>
        <div class="w-full h-full  transform hover:translate-y-2 ease-in-out duration-100">
        <img src={Logo} alt=""/>
      </div></a>
      <a href='#LOL'><div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo1} alt=""/>
      </div></a>
      </div>
      <div class=" justify-center grid grid-cols-2 ">
      <a href='#bhavishya'><div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo2} alt=""/>
      </div></a>
     

      <div class=" justify-center grid grid-cols-1 ">
      <a href='#eyc'>
        <div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo4} alt=""/>
      </div>
      </a>
      </div>
      <div>
      <a href='#pathashaala'>
    <div class=" w-full h-full mr-10 justify-center transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo5} alt=""/>
      </div></a>
      </div>
      </div>
  </div>

</section>

<section id='empower' class=" px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center">
      <div class="w-full md:w-1/2 ">
        <img class="mx-auto w-full h-full transform hover:translate-y-3 transition ease-in-out duration-1000" src={Empower} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8 pl-2 sm:pl-28 sm:pt-10  ">
        <div class="md:max-w-xl">
          {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
          <h2 class=" text-3xl sm:text-6xl font-bold font-heading tracking-px-n leading-tight text-red-600">Empower<span className='text-black'>  Gurus</span></h2>
         </div>
         <div class="md:max-w-xl sm:mt-4">
          {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
          <h2 class=" text-xl sm:text-2xl font-bold font-heading tracking-px-n leading-tight text-red-600 underline">AN ANNUAL TEACHERS’ WORKSHOP</h2>
         </div>
         <ul class="text-gray-900 font-bold mt-10 text-xl sm:text-2xl">
              <li class="flex mb-10">
                <svg class="sm:mr-2 w-20 h-20  sm:w-6 sm:h-6 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span>Won’t your teaching faculty empathize with students…?</span>
              </li>
              <li class="flex mb-10">
              <svg class="sm:mr-2 w-20 h-20  sm:w-6 sm:h-6 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span>Wish to strengthen your teachers’ Academic Skills…?</span>
              </li>
              <li class="flex mb-10">
              <svg class="sm:mr-2 w-20 h-20  sm:w-6 sm:h-6 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span>Wish to honor your teachers with awards and laurels...?</span>
              </li>
            </ul>
      

      </div>
    </div>
  </div>
</section>

<section class=" pb-16  sm:pb-16 sm:px-24 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800 overflow-hidden" style={{fontFamily:'Manrope'}}>
<div class="container mx-auto px-4">
    <div class="flex flex-wrap items-center max-w-6xl mx-auto -m-9">
      <div class="w-full md:flex-1 sm:p-11 mt-12 sm:mt-6">
      <svg
      viewBox="0 0 24 24"
      fill="red"
      height="2em"
      width="2em"
     fontWeight="bold"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179z" />
    </svg>
        <h2 class="sm:mb-4 mb-6 mt-4 sm:mt-6 font-heading font-medium text-3xl sm:text-6xl text-white ">True teachers are those who help us think for ourselves</h2>
        <p class="text-xl text-slate-200 font-bold"> - Dr. Sarvepalli Radhakrishnan</p>
      </div>
   
    </div>
  </div>

  </section>
<div className=' sm:pt-12 bg-red-50'>
  <section class="pt-10 pb-24  px-4 md:px-24  overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">

  <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-3xl sm:text-4xl text-black">A comprehensive teach the teachers Programme</h2>
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Subject Workshops</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Child Psychology Training</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teacher Olympiads</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Useful Worksheets and Study Content</h3>
      </div>
      
      
    
     
    </div>
  </div>
</section>
</div>

<div class="flex flex-wrap sm:px-16 sm:pt-16 pt-8 bg-white">
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image1} alt=""/>
           
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image2} alt=""/>
            
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image3} alt=""/>
             
            </div>
          </a>
        </div>
     
       
     
       
      </div>







<div>
     
<section id='LOL' class=" px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center">
      <div class="w-full md:w-1/2 ">
        <img class="mx-auto w-full h-full transform hover:translate-y-3 transition ease-in-out duration-1000" src={LOL} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8 pl-2 sm:pl-28 sm:pt-10  ">
        <div class="md:max-w-xl">
          {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
          <h2 class=" text-3xl sm:text-6xl font-bold font-heading tracking-px-n leading-tight text-red-600">LOL<span className='text-black'> - Learn Out Of Love</span></h2>
         </div>
         <div class="md:max-w-xl sm:mt-4">
          {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
          <h2 class=" text-xl sm:text-2xl font-bold font-heading tracking-px-n leading-tight text-red-600 underline">Face Your Exams With PLAN Not FEAR</h2>
         </div>
         <ul class="text-gray-900 font-bold mt-10 text-xl sm:text-2xl">
              <li class="flex mb-10">
                <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span> Are your students highly distracted by Gadgets, Digital & Social Media...?</span>
              </li>
              <li class="flex mb-10">
              <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span>Won’t you love it, if all your students study by WILL not by FORCE...?</span>
              </li>
              <li class="flex mb-10">
              <svg class="sm:mr-2 w-20 h-20  sm:w-10 sm:h-10 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span> Do you wish to add value to students’ life by strengthening their Personality & Emotional skills...?
</span>
              </li>
            </ul>
      

      </div>
    </div>
  </div>
</section>
<section class=" pb-16  sm:pb-16 sm:px-24 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800 overflow-hidden" style={{fontFamily:'Manrope'}}>
<div class="container mx-auto px-4">
    <div class="flex flex-wrap items-center max-w-6xl mx-auto -m-9">
      <div class="w-full md:flex-1 sm:p-11 mt-12 sm:mt-6">
      <svg
      viewBox="0 0 24 24"
      fill="red"
      height="2em"
      width="2em"
     fontWeight="bold"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179z" />
    </svg>
        <h2 class="sm:mb-4 mb-6 mt-4 sm:mt-6 font-heading font-medium text-3xl sm:text-6xl text-white ">Don’t wish it were easier..... Wish you were better</h2>
        <p class="text-xl text-slate-200 font-bold"> - Jim Rohn</p>
      </div>
   
    </div>
  </div>

  </section>


  <div className=' sm:pt-12 bg-red-50'>
  <section class="pt-10 pb-24  px-4 md:px-24  overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">

  <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-3xl sm:text-4xl text-black">An experiential workshop to realise SELF</h2>
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Sokt Skills Programme</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Activities and Games with Life Messages</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Exam Preparation Tips</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Memory Enhancement Principles</h3>
      </div>
      
      
    
     
    </div>
  </div>
</section>
</div>


<div class="flex flex-wrap bg-white sm:px-24  sm:pt-16 pt-8">
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image4} alt=""/>
              
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image5} alt=""/>
            
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image6} alt=""/>
             
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image7} alt=""/>
            
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image8} alt=""/>
              
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image9} alt=""/>
             
            </div>
          </a>
        </div>
        {/* <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={image10} alt=""/>
            
            </div>
          </a>
        </div> */}
      </div>




    </div>



    <div>
     
<section id='bhavishya' class=" px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center">
      <div class="w-full md:w-1/2 ">
        <img class="mx-auto w-full h-full transform hover:translate-y-3 transition ease-in-out duration-1000" src={Bhavishya} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8 pl-2 sm:pl-28 sm:pt-8  ">
      <div class="md:max-w-xl">
          <h2 class=" text-3xl sm:text-6xl font-bold font-heading tracking-px-n leading-tight text-red-600">Bhavishya</h2>
         </div>
         <div class="md:max-w-xl sm:mt-4">
          <h2 class=" text-xl sm:text-2xl font-bold font-heading tracking-px-n leading-tight text-black underline">FUTURISTIC FOUNDATION FOR HIGH SCHOOL</h2>
         </div>

         <ul class="text-gray-900 font-bold mt-10 text-xl sm:text-2xl">
              <li class="flex mb-10">
                <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span> Let your students think beyond board exams</span>
              </li>
              <li class="flex mb-10">
              <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span>Let them bring laurels to school by achieving scholarships</span>
              </li>
              <li class="flex mb-10">
              <svg class="sm:mr-2 w-20 h-20  sm:w-10 sm:h-10 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                </svg>
                <span> Entrance exams need a special subject aptitude. Let students develop it before hand</span>
              </li>
              
            </ul>
      

      </div>
    </div>
  </div>
</section>
<section class=" pb-16  sm:pb-16 sm:px-24 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800 overflow-hidden" style={{fontFamily:'Manrope'}}>
<div class="container mx-auto px-4">
    <div class="flex flex-wrap items-center max-w-6xl mx-auto -m-9">
      <div class="w-full md:flex-1 sm:p-11 mt-12 sm:mt-6">
      <svg
      viewBox="0 0 24 24"
      fill="red"
      height="2em"
      width="2em"
     fontWeight="bold"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179z" />
    </svg>
        <h2 class="sm:mb-4 mb-6 mt-4 sm:mt-6 font-heading font-medium text-3xl sm:text-6xl text-white ">The most reliable way to predict the future is to create it</h2>
        <p class="text-xl text-slate-200 font-bold"> - Abraham Lincoln</p>
      </div>
   
    </div>
  </div>

  </section>


  <div className=' sm:pt-12 bg-red-50'>
  <section class="pt-10 pb-24  px-4 md:px-24  overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">

  <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-2xl sm:text-4xl text-black">An In-house integrated foundation for Entrance Exam</h2>
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Online / Ofline NTSE Programmes</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">IIT - Medical Foundation from 8th to 10th</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Worksheets and Study Content</h3>
      </div>
     
      
      
    
     
    </div>
  </div>
</section>
</div>


<div class="flex flex-wrap sm:px-16 sm:pt-24 pt-8 bg-white">
        <div class="w-full sm:w-1/2 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image10} alt=""/>
           
            </div>
          </a>
        </div>
        <div class="w-full sm:w-1/2 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image11} alt=""/>
            
            </div>
          </a>
        </div>
     
   
       
     
       
      </div>




    </div>



    <div>
     
     <section id='eyc' class=" px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       <div class="container px-4 mx-auto">
         <div class="flex flex-wrap xl:items-center">
           <div class="w-full md:w-1/2 ">
             <img class="mx-auto w-full h-full transform hover:translate-y-3 transition ease-in-out duration-1000" src={EYC} alt=""/>
           </div>
           <div class="w-full md:w-1/2 p-8 pl-2 sm:pl-28 sm:pt-20 ">
           <div class="md:max-w-xl">
               <h2 class=" text-3xl sm:text-6xl font-bold font-heading tracking-px-n leading-tight text-red-600">EYC <span className='text-black'>-Empower Your Child</span></h2>
              </div>
              <div class="md:max-w-xl sm:mt-4">
               <h2 class=" text-xl sm:text-2xl font-bold font-heading tracking-px-n leading-tight text-black underline">Rearing your child into a rare student</h2>
              </div>
     
              <ul class="text-gray-900 font-bold mt-10 text-xl sm:text-2xl">
                   <li class="flex mb-10">
                     <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span>You’re providing Best Home @ School.</span>
                   </li>
                   <li class="flex mb-10">
                   <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span>Are their parents providing Best School @ Home...?</span>
                   </li>
                   <li class="flex mb-10">
                   <svg class="sm:mr-2 w-20 h-20  sm:w-10 sm:h-10 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span> Don’t you think many parents need better emotional intelligence...?</span>
                   </li>
                   <li class="flex mb-10">
                   <svg class="sm:mr-2 w-20 h-20  sm:w-10 sm:h-10 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span> Do you agree that running school would be much easier, if every parents knows the SCIENCE OF PARENTING...?</span>
                   </li>
                   
                 </ul>
           
     
           </div>
         </div>
       </div>
     </section>
     <section class=" pb-16  sm:pb-16 sm:px-24 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800 overflow-hidden" style={{fontFamily:'Manrope'}}>
     <div class="container mx-auto px-4">
         <div class="flex flex-wrap items-center max-w-6xl mx-auto -m-9">
           <div class="w-full md:flex-1 sm:p-11 mt-12 sm:mt-6">
           <svg
           viewBox="0 0 24 24"
           fill="red"
           height="2em"
           width="2em"
          fontWeight="bold"
         >
           <path fill="none" d="M0 0h24v24H0z" />
           <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179z" />
         </svg>
             <h2 class="sm:mb-4 mb-6 mt-4 sm:mt-6 font-heading font-medium text-3xl sm:text-6xl text-white ">Dear Child ! Let me walk in the light of your faith in me</h2>
             <p class="text-xl text-slate-200 font-bold"> - Haim Ginott</p>
           </div>
        
         </div>
       </div>
     
       </section>
     
     
       <div className=' sm:pt-12 bg-red-50'>
       <section class="pt-10 pb-24  px-4 md:px-24  overflow-hidden" style={{fontFamily:'Manrope'}}>
       <div class="container px-4 mx-auto">
     
       <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-3xl sm:text-4xl text-black">Empowering the Parents to Empower their Child</h2>
       
         <div class="flex flex-wrap -m-8 md:px-28">
           <div class="w-full md:w-1/2 lg:w-1/3 p-8">
             <div class="flex flex-wrap items-center mb-7 -m-2">
               <div class="w-auto p-2">
                 <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
                   <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                 </div>
               </div>
               <div class="flex-1 p-2">
                 <div class="w-full h-px bg-gray-400"></div>
               </div>
             </div>
             <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Parenting Workshops</h3>
           </div>
           <div class="w-full md:w-1/2 lg:w-1/3 p-8">
             <div class="flex flex-wrap items-center mb-7 -m-2">
               <div class="w-auto p-2">
                 <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
                   <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
                   <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                 </div>
               </div>
               <div class="flex-1 p-2">
                 <div class="w-full h-px bg-gray-400"></div>
               </div>
             </div>
             <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Family Bash (couples program)</h3>
           </div>
           <div class="w-full md:w-1/2 lg:w-1/3 p-8">
             <div class="flex flex-wrap items-center mb-7 -m-2">
               <div class="w-auto p-2">
                 <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
                   <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                 </div>
               </div>
               <div class="flex-1 p-2">
                 <div class="w-full h-px bg-gray-400"></div>
               </div>
             </div>
             <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Psychometric Assessment for Parents</h3>
           </div>
          
           
           
         
          
         </div>
       </div>
     </section>
     </div>
     
     
     <div class="flex flex-wrap sm:px-16 sm:pt-24 pt-8 bg-white">
        <div class="w-full sm:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image13} alt=""/>
           
            </div>
          </a>
        </div>
        <div class="w-full sm:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image14} alt=""/>
            
            </div>
          </a>
        </div>
     
        <div class="w-full sm:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image15} alt=""/>
            
            </div>
          </a>
        </div>
       
     
       
      </div>
     
     
     
     
         </div>



         <div>
     
     <section id='pathashaala' class=" px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       <div class="container px-4 mx-auto">
         <div class="flex flex-wrap xl:items-center">
           <div class="w-full md:w-1/2 ">
             <img class="mx-auto w-full h-full transform hover:translate-y-3 transition ease-in-out duration-1000" src={Pathashaala} alt=""/>
           </div>
           <div class="w-full md:w-1/2 p-8 pl-2 sm:pl-28 sm:pt-20 ">
           <div class="md:max-w-xl">
               <h2 class=" text-3xl sm:text-6xl font-bold font-heading tracking-px-n leading-tight text-red-600">Pathashaala</h2>
              </div>
              <div class="md:max-w-xl sm:mt-4">
               <h2 class=" text-xl sm:text-2xl font-bold font-heading tracking-px-n leading-tight text-black underline">DON’T SEARCH FOR NO.1 FIELD...SEARCH FOR A FIELD WHERE YOU’RE NO.1</h2>
              </div>
     
              <ul class="text-gray-900 font-bold mt-10 text-xl sm:text-2xl">
                   <li class="flex mb-10">
                     <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span>Let your students cherish the school for the rest of their life</span>
                   </li>
                   <li class="flex mb-10">
                   <svg class="sm:mr-2 w-20 h-20  sm:w-7 sm:h-7 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span>Gift then with a Comprehensive, Self-Oriented,Person-specific CAREER GUIDANCE</span>
                   </li>
                   <li class="flex mb-10">
                   <svg class="sm:mr-2 w-20 h-20  sm:w-10 sm:h-10 text-red-600 mt-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                       <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                     </svg>
                     <span> Let your Alumni be enriched with a Plethora of diverse professions</span>
                   </li>
                 
                   
                 </ul>
           
     
           </div>
         </div>
       </div>
     </section>
     <section class=" pb-16  sm:pb-16 sm:px-24 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800 overflow-hidden" style={{fontFamily:'Manrope'}}>
     <div class="container mx-auto px-4">
         <div class="flex flex-wrap items-center max-w-6xl mx-auto -m-9">
           <div class="w-full md:flex-1 sm:p-11 mt-12 sm:mt-6">
           <svg
           viewBox="0 0 24 24"
           fill="red"
           height="2em"
           width="2em"
          fontWeight="bold"
         >
           <path fill="none" d="M0 0h24v24H0z" />
           <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5 3.871 3.871 0 01-2.748-1.179z" />
         </svg>
             <h2 class="sm:mb-4 mb-6 mt-4 sm:mt-6 font-heading font-medium text-3xl sm:text-6xl text-white ">If you fail to Plan, you are planning to fail</h2>
             <p class="text-xl text-slate-200 font-bold"> - Benjamin Franklin</p>
           </div>
        
         </div>
       </div>
     
       </section>
     
     
       <div className=' sm:pt-12 bg-red-50'>
  <section class="pt-10 pb-24  px-4 md:px-24  overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">

  <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-2xl sm:text-4xl text-black">Personalised guidance for a personality-appropriate career</h2>
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Career Workshops</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Psychometric Assessment</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Personalised Counselling</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Coaching & Guidance for relevant Competitive exams from NTSE to UPSC</h3>
      </div>
      
      
    
     
    </div>
  </div>
</section>
</div>
     
     
<div class="flex flex-wrap sm:px-16 sm:pt-24 pt-8 bg-white">
        <div class="w-full sm:w-1/2 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image16} alt=""/>
           
            </div>
          </a>
        </div>
        <div class="w-full sm:w-1/2 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class=" w-full h-full object-cover" src={image17} alt=""/>
            
            </div>
          </a>
        </div>
     
   
       
     
       
      </div>
     
     
     
     
         </div>






    </div>




    
  )
}
