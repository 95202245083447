import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_ptbs:[],
    current_ptbs:[],
    loading:false,
    hasError:false,
}

export const PTBSlice = createSlice({

  name: 'PTBresults',
  initialState,

  reducers: {
    getresults: state => {
      state.loading = true;
    },

    getAll_results_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_ptbs = payload
    },

    get_results_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_ptbs = payload
  
  },

  },
})


export const { getresults ,getAll_results_success,getCurrentSuccess, get_results_Failure } = PTBSlice.actions;

export const PTBSelector = state => state.PTBresults;



export const fetchAllPTBresults = (id) => async dispatch => {
  dispatch(getresults())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/getAllPTBResults`)
   console.log({data});
   dispatch(getAll_results_success(data));

  } catch (error) {
 dispatch(get_results_Failure())
  }
 };



 export const fetchOnePTBResults = (id) => async dispatch => {

  dispatch(getresults())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/getOnePTBResults/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_results_Failure())
  }
 };



export default PTBSlice.reducer;