import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_banner:[],
    current_banner:null,
    loading:false,
    hasError:false,
}

export const bannerSlice = createSlice({

  name: 'banner',
  initialState,

  reducers: {
    getbanner: state => {
      state.loading = true;
    },

    getAll_banner_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_banner = payload
    },

    get_banner_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_banner = payload
  
  },

  },
})


export const { getbanner ,getAll_banner_success,getCurrentSuccess, get_banner_Failure } = bannerSlice.actions;

export const bannerSelector = state => state.banner;



export const fetchAllbanner = (id) => async dispatch => {
  dispatch(getbanner())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/banner`)
   console.log({data});
   dispatch(getAll_banner_success(data));

  } catch (error) {
 dispatch(get_banner_Failure())
  }
 };



 export const fetchOneBanner = (id) => async dispatch => {

  dispatch(getbanner())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/banner/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_banner_Failure())
  }
 };



export default bannerSlice.reducer;