import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_broadcast:[],
    current_broadcast:null,
    loading:false,
    hasError:false,
}

export const broadcastSlice = createSlice({

  name: 'broadcast',
  initialState,

  reducers: {
    getbroadcast: state => {
      state.loading = true;
    },

    getAll_broadcast_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_broadcast = payload
    },

    get_broadcast_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_broadcast = payload
  
  },

  },
})


export const { getbroadcast ,getAll_broadcast_success,getCurrentSuccess, get_broadcast_Failure } = broadcastSlice.actions;

export const broadcastSelector = state => state.broadcast;



export const fetchAllbroadcast = () => async dispatch => {
  dispatch(getbroadcast())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/broadcast`)
   console.log({data});
   dispatch(getAll_broadcast_success(data));

  } catch (error) {
 dispatch(get_broadcast_Failure())
  }
 };



 export const fetchOnebroadcast = (id) => async dispatch => {

  dispatch(getbroadcast())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/broadcast/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_broadcast_Failure())
  }
 };


 export const fetchBroadcastStudent =()=> async dispatch =>{
    dispatch((getbroadcast()))
    try{
        const {data} = await axios.get(keyUri.BACKEND_URI+`/broadcast-student`)
        dispatch(getCurrentSuccess(data))
    }
    catch(error){
        dispatch(get_broadcast_Failure())
    }
 }


 export const fetchBroadcastParent =()=> async dispatch =>{
    dispatch((getbroadcast()))
    try{
        const {data} = await axios.get(keyUri.BACKEND_URI+`/broadcast-parent`)
        dispatch(getCurrentSuccess(data))
    }
    catch(error){
        dispatch(get_broadcast_Failure())
    }
 }



export default broadcastSlice.reducer;