import React from 'react'

export default function testimonialvideos() {
  return (
    <div>
        <section>

<div class="py-10 bg-red-50 " style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
    <div class="mb-8 md:mb-16 max-w-lg mx-auto text-center">
      <h3 class="mb-6 text-2xl sm:text-5xl font-bold font-heading text-black">Watch Our <span className='text-red-600'>Testimonial Videos</span></h3>
    
    </div>

    <div className='hidden sm:block'>
    <div class="flex flex-wrap mb-8  gap-10 justify-center">
      <div class=" w-auto md:w-auto lg:w-1/4 mb-4">
        <a href="#">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/icjN8PI6xAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </a>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-4">
        <a href="#">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/ywsMvOt6bkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </a>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/xwkwkmcbUfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>         
        </div>
      </div>
     
     
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/KFvvOaF-A8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </div>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/ebVpN3cX1Og" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </div>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/WgPaujFcWMY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </div>
      </div>
    
     
     
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/RBXpy0Mq4Ro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
</div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/5dRr-OcPBHk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>

     
    </div>
    </div>

{/* ------------------------------------------------------------------------------------------ */}
    <div className='block sm:hidden'>
    <div class=" mb-6 px-2   gap-10 justify-center">
      
      <div class=" w-auto md:w-auto lg:w-1/4 mb-4">
        <a href="#">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/icjN8PI6xAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </a>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-4">
        <a href="#">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/ywsMvOt6bkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </a>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/xwkwkmcbUfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>         
        </div>
      </div>
      
     
   
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/KFvvOaF-A8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </div>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/ebVpN3cX1Og" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </div>
      </div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/WgPaujFcWMY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        </div>
      </div>
    
     
     
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/RBXpy0Mq4Ro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
</div>
      <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/5dRr-OcPBHk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
    
    </div>
    </div>


  </div>
</div>

</section>

    </div>
  )
}
