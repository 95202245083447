import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllbanner,bannerSelector } from '../../api/banner';

import Loadable from '@loadable/component';
import { useState } from 'react';
import { fetchAllmobbanner, mobbannerSelector } from '../../api/mobBanner';


const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
   
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 3000,
    responsive: {
        0:{
            items:1,
        },
        768:{
            items:1,
        },
        1200:{
            items:1,
        }
    }
}

export default function Banner() {

  const dispatch = useDispatch()
  const {all_banner} = useSelector(bannerSelector)
  const {all_mobbanner} = useSelector(mobbannerSelector)
 

  useEffect(()=>{
    dispatch(fetchAllbanner())
  },[dispatch])

  useEffect(()=>{
    dispatch(fetchAllmobbanner())
  },[dispatch])

  console.log(all_mobbanner)

 


  return (
    <div>
 <section class="hidden sm:block bg-gray-50 text-slate-700 bg-blueGray-100" style={{fontFamily:'Manrope'}}>
  <OwlCarousel 
            {...options}>
           
            {
              all_banner?.map((item)=>{
                   return (  <img  key = {item._id} class=" sm:h-1/2 md:h-1/2 sm:w-full md:w-full " src={item.banner_image} alt=""/>)

                })
            }
       </OwlCarousel> 

</section>

<section class=" block sm:hidden bg-gray-50 text-slate-700 bg-blueGray-100" style={{fontFamily:'Manrope'}}>
  <OwlCarousel 
          {...options}
      >
         
          {
            all_mobbanner?.map((item)=>{
                 return (  <img  key = {item._id} class=" h-auto w-auto " src={item.banner_image} alt=""/>)

              })
          }
        </OwlCarousel> 
</section>
</div>
)
}




