import React from 'react'
import {Button,Tabs} from 'antd'
import {Link} from 'react-router-dom'
import Parentlogin from './parentLogin'
import Studentlogin from './studentLogin'

const {TabsPane} = Tabs;

function selectOne() {
  return (
    <div >

    <Tabs className=' h-72 flex  items-center '>
    <TabsPane tab = "Student Login" key = "1">
    <Studentlogin/>
    </TabsPane>
    <TabsPane tab = "Parent Login" key = "2">
    <Parentlogin/>


    </TabsPane>
    </Tabs>
    </div>
   
  )
}

export default selectOne