import React from 'react'
import Fig from '../images/fig.png'
import Ban1 from '../images/ban1.png'
import Img1 from '../images/img1.png'
import Img2 from '../images/img2.png'
import Img3 from '../images/img3.png'
import Img4 from '../images/img4.png'
import Monitor from '../images/monitor.png'
import Study from '../images/study.png'
import Shedule from '../images/shedule.png'
import Overview from '../images/overview.jpg'
import Book1 from '../images/book1.png'
import { NavLink } from 'react-router-dom'
import Stories from '../homepage/stories'


export default function Ntseonline() {
  return (
    <div>
        <div className='bg-white'>
       <div className='object-cover bg-cover w-full md:w-full flex justify-center items-cente' style={{backgroundImage:`url(${Fig})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}></div>
</div>
{/* -------------------------------------------------------------------------------- */}
<section class="py-4 overflow-x-hidden bg-white" style={{fontFamily:'Manrope'}}>
<p class=" py-5 md:py-10 max-w-6xl mx-auto font-heading font-bold text-center md:mb-10 px-16 text-3xl md:text-4xl text-gray-800 "> NTSE ONLINE - Target Exams<span className='text-red-600'>  & Courses</span></p>
</section>
{/* ------------------------------------------------------------------------------------------- */}
<section class="relative py-10 2xl:py-2 overflow-hidden bg-white">
  <img class="hidden md:block absolute top-0 left-0 px-20" src={Ban1} alt=""/>
  <div class="relative container px-4 mx-auto">
    <div>
      <div class="flex flex-wrap -mx-10 md:px-28">
        <div class="relative w-full lg:w-1/2 px-10">
          <img class="lg:hidden absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt=""/>
          <div class="px-10 pt-3 pb-5 rounded-lg">
            <h3 class=" mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Start Date</h3>
            <p class="text-lg text-gray-800 md:text-white mb-2">Every year – First Week of March</p>
            {/* <p class="text-lg text-gray-800 md:text-white">This Year COVID  Delay – Third week of June/First week of July</p> */}
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>

        <div class="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <img class="hidden lg:block absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt=""/>
          <div class="px-10 pt-3 pb-5 rounded-lg">           
            <h3 class=" mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Selection Procedure</h3>
            <p class="text-lg text-gray-800 md:text-white">ESSAT (EduShrine Scholarship cum Admission Test) based on Class-VIII & IX syllabus</p>
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>

        <div class="w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <div class="px-10 pb-5  rounded-lg">        
            <h3 class="mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Eligibility</h3>
            <p class="text-lg text-gray-800 md:text-white">Class-IX from the respective board with minimum of 60% marks</p>

          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>
        <div class=" ml-32 md:ml-20  text-center"><a class="inline-block py-5 px-12 mr-4 text-xl bg-red-900 hover:bg-orange-600 rounded-lg text-white font-bold transition duration-200" href="/contact">Enroll now</a></div>
      </div>
    </div>
  </div>
</section>
{/* --------------------------------------------------------------------------------------- */}
<section class="relative pt-10 pb-20 md:pb-14  bg-gradient-to-b from-gray-900 via-gray-900 to-red-800"  style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
  <p class="mb-10  text-center text-3xl md:text-4xl text-white font-bold">Program <span className='text-red-600'>Locations</span></p>
    <div class="flex flex-wrap max-w-6xl mx-auto md:mx-96 -m-7">
      <div class="w-full md:w-1/2 xl:w-1/2 p-7 ">
      <div class=" text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
          <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg> </div>
        <h3 class="mb-1 font-heading font-bold text-gray-800 text-lg md:text-3xl p-5">Pan India</h3>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/2 p-7">
        <div class="text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
          <h3 class="mb-5 font-heading font-bold text-gray-900 text-xl">Class Timings</h3>
        <h3 class=" mb-9 font-heading font-bold text-gray-500 text-lg">Weekends Saturday & Sunday Each Day 3 Hours</h3>

        </div>
      </div>
    </div>
  </div>
</section>
{/* ----------------------------------------------------------------------------------------- */}
<div className='bg-white'>
<section class="container mx-auto md:px-28 pb-10 md:pb-16 md:py-24 bg-white" style={{fontFamily:'Manrope'}}>
    <div class="flex flex-wrap justify-between -m-6">
      <div class="w-full lg:w-5/12 xl:w-1/2 p-6">
        {/* <p class="mb-20 text-center text-4xl text-gray-800 font-bold">Course <span className='text-red-600'> Highlights</span></p> */}
        <div class="w-full mt-4 lg:w-5/12 xl:w-1/2 p-6 lg:mt-24">
        {/* <p class=" mb-4 text-sm md:text-md font-heading text-gray-800 font-medium ">The course is primarily based on practical work</p> */}
        <p class="mb-12 text-center text-3xl md:text-7xl text-gray-800 font-bold">Course <span className='text-red-600'> Highlights</span></p>
        <div class="flex flex-wrap -m-3 lg:w-96 pl-4 md:mb-20">

      <p className=' mb-10 md:mb-20 text-left text-lg text-gray-800 font-semibold '> Edu-Shrine’s Study Material is the key factor of its Academic Programme. The Study Material by itself generates a System that enables the Management to monitor Faculty and Faculty and Parents to monitor the Students.</p>
      <p class=" text-center text-3xl md:text-3xl text-red-700 font-bold">[" Overall, Intensive Coaching....Stress-Free Learning "]</p>

        </div>
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
           
          </div>
        </div>
        
      </div>
        
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
          </div>
        </div>
      </div>
      <div class="w-auto lg:w-7/12 xl:w-1/2  p-2 md:p-6">
        <div class="max-w-max mx-auto">
          <div class="flex flex-wrap justify-center -m-3 mb-3">
            <div class="w-full p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                   <div class="w-28 p-2">
                    <img src={Img1} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800 ">Meticulous<br/> Schedule </p>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img2} alt=""/>
                  </div>
                  <div class="w-auto p-2">
                    <p class="font-heading font-bold text-gray-800">Exhaustive Study <br/>Content with large<br/> number of Practice<br/> questions as per the<br/> Latest Exam Pattern</p>
                 
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 md:bg-red-500 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img3} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-black md:text-white">Passion-driven <br/>faculty team </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3 xl:-ml-20">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 md:bg-red-500  transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img4} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-black md:text-white">Periodic Tests with<br/> immediate feedback,<br/> follow up and<br/> fine tuning</p>
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Shedule} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800">Rigorous Monitoring <br/>& Mentoring System </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Monitor} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-3">
                    <p class="font-heading font-bold text-gray-800">Student Counseling<br/> & Mental<br/> Strengthening</p>
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 md:bg-red-500 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Study} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-3">
                    <p class="font-heading font-bold text-black md:text-white">Round the clock<br/> availability of<br/> teachers for the <br/>doubt clarification</p>
                   
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
{/* ------------------------------------------------------------------------------------------------- */}

<div className='bg-white'>
<h2 class=" ml-20 md:ml-44 pt-8 text-3xl md:text-5xl font-bold font-heading tracking-px-n text-gray-900 "><span className='text-red-600'> Course </span> Overview</h2>
</div>
<section class="pt-10 pb-10 md:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       
       <div class="container px-4 mx-auto">
         <div class="md:flex md:grid-cols-2">
         <div class=" md:w-1/2 md:p-8 ">
     
             <img class="hidden md:block md:mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src={Overview} alt=""/> ;
     
                         </div>
           <div class="w-full md:w-1/3 md:px-12 md:mt-40 text-gray-900 ">
             
             <div class="md:max-w-lg">
               <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">6 Months Course from May to October (NTSE Exam will be First Sunday of November)</h3>
                       </div>
                     </div>
                   </div>
                 </div>
              
            
               </div>
               <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Conceptual teaching + Exam based counselling + Classroom Practice + Tests + Feedback</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
               
            <div class="flex flex-wrap -m-5 mb-2 ">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">3 Hours of Classes per day, 2 days a week</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
            
            <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Over 20 Topic-Wise Practice Tests & 10 Mock Tests</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
            </div>
           </div>
         </div>
       </div>
     </section>
     {/* ------------------------------------------------------------------------------------------- */}
     <section class="pt-10 pb-24 px-10 md:px-24 bg-red-50 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
  <p class="mb-20 text-center text-3xl md:text-5xl text-gray-800 font-bold">Why <span className='text-red-600'> EDUShrine?</span></p>

  {/* <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-6xl sm:text-3xl text-red-700">Why EDUShrine?</h2> */}
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teacher's Institution</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Ethical Coaching Practices</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Student-Centered, Dynamic Academic System</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Perfect balance between Education & Training</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Concept-Oriented, Self-Sufficient Teaching System</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">6</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teachers with genuine empathy towards student's growth</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">7</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Cordial Relationship with parents and students</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">8</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Most Reasonable fee structure for the quality delivered</h3>
      </div>
    </div>
  </div>
</section>
{/* ----------------------------------------------------------------------------------------------------- */}
<section class="bg-white py-5 md:py-20 md:px-24" style={{fontFamily:'manrope'}}>
  <div class="container mx-auto">
    <div class="relative py-11 md:px-16 rounded-md overflow-hidden">
    <img class="hidden md:block absolute top-0 left-0 px-20 md:h-96" src={Ban1} alt=""/>

      <div class="relative z-20 flex flex-wrap items-center">
        <div class="w-full md:w-1/2">
        <p class=" md:mt-5 mb-10 px-16 text-3xl md:text-5xl text-red-600 md:text-white font-bold">Get EduShrine<br/><span className='text-gray-800 md:text-white'> Advantage</span></p>
        </div>
        <div class="w-full md:w-1/2 p-3">
                    <p class="font-semibold text-xl text-gray-800 md:text-white text-center mb-1">" <i>STAGE-II COACHING FREE FOR THE STUDENTS CLEARING <br/>STAGE-I</i> "</p>
                              <p class=" mt-5 md:mt-0 font-semibold text-xl px-10  text-gray-800 md:text-white mb-2">Enroll for Free NTSE Orientation Class Today </p>
                              

          <NavLink to='/contact'>
          <button class=" ml-20 block mt-3 md:ml-60 px-10 py-3 font-semibold text-lg text-white bg-red-600 hover:bg-orange-600 rounded-md">Contact Now</button>
          </NavLink>
        </div>
      </div>
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------------ */}
<Stories/>
{/* ------------------------------------------------------------------------------------------------ */}

<div class="p-4 pt-8 md:pt-16 pb-12 md:px-24 bg-white" style={{fontFamily:'manrope'}}>
<p class="mb-10 md:mb-20 text-center text-3xl md:text-5xl text-gray-800 font-bold">Watch Online <span className='text-red-600'> Demo Class</span></p>
  <div class="aspect-w-16 aspect-h-8 rounded-lg md:pl-32 block md:hidden">
      <iframe width="360" height="200"   src="https://www.youtube.com/embed/O5cnEBUvQnI" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      <div class="aspect-w-16 aspect-h-8 rounded-lg md:pl-32 hidden md:block">
      <iframe width="1220" height="520"   src="https://www.youtube.com/embed/O5cnEBUvQnI" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>

{/* -------------------------------------------------------------------------------------------------- */}
<section class="py-10 md:py-10 bg-gray-50 overflow-hidden" style={{fontFamily:'manrope'}}>
  <div class="container mx-auto px-4 md:px-40">
    <div class="bg-white overflow-hidden rounded-10 shadow-5xl">
      <div class="flex flex-wrap items-center -m-6">
        <div class="w-full md:w-1/2">
          <img class=" hidden md:block relative" src={Book1} alt=""/>
        </div>
        <div class="w-full md:w-1/2 p-12">
          <div class="md:max-w-md">
            <h2 class="mb-2.5 font-heading font-semibold text-4xl sm:text-5xl text-gray-800">Book a Free Class</h2>
            <p class="mb-9 text-gray-700">Enroll for Free Bridge Course Classes</p>
            {/* <p class="text-sm text-gray-900 mb-3">Email</p> */}
            <div class="flex flex-wrap">
              <div class="w-full md:w-auto p-1.5">
                <button class="group relative font-heading py-4 px-9 block w-full md:w-auto text-base text-white font-medium bg-red-600 overflow-hidden rounded-lg">
                  <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full transition ease-in-out duration-500 bg-gradient-cyan"></div>
                  <p class="relative z-10">Book Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
