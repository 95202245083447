import React from 'react'
import Facultyone from '../images/Pankaj1.png'
import Facultytwo from '../images/Praveenkumar.png'
import Facultythree from '../images/Suresh1.png'
import Facultyfour from '../images/Adisiva.png'


export default function faculty() {
  return (
    <div>
        <section>


  <div class="py-12 px-4 sm:py-20 bg-red-50 sm:px-24 " style={{fontFamily:'Manrope'}}>
    <div class="container mx-auto px-4">
      <div class="mb-8 md:mb-16 max-w-lg mx-auto text-center">
        <h2 class="mb-6 text-4xl sm:text-5xl font-bold font-heading text-black ">Our <span className='text-red-600 font-bold'>Faculty</span></h2>
       
      </div>
      <div class="flex flex-wrap -mx-4 mb-8">
        <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <div class="flex flex-wrap -mx-4 -mb-8">
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultyone} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Pankaj Tamang</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Physics</h2>
                  </div>
                </div>
              </a>
            </div>
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultytwo} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">R.Praveen Kumar Reddy</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Chemistry</h2>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 px-4">
        <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
          <div class="flex flex-wrap -mx-4 -mb-8">
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultythree} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Suresh Kumar</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Mathematics</h2>
                  </div>
                </div>
              </a>
            </div>
         
           
          </div>
        </div>
        </div>
      </div>
      <div class="text-center"><a class="inline-block py-2 px-6 leading-loose rounded-l-xl rounded-t-xl bg-red-600 hover:bg-red-700 text-gray-50 font-bold tansition duration-200" href="/faculty">View all faculties</a></div>
    </div>
  </div>

</section>
    </div>
  )
}
