import React from 'react'

export default function () {
  return (
    <div>
    <section class="bg-gradient-to-b from-black to-red-700 2xl:py-16" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="max-w-6xl mx-auto">
      <div class="max-w-xl mb-14 lg:mb-28">
        <span class="text-lg text-black font-semibold">Testimonials</span>
        <h2 class="mt-8 text-5xl sm:text-5xl text-red-50 font-bold font-heading">Our <span className='text-red-600'>Video Testimonials</span></h2>
      </div>
    <div className='hidden sm:block'>
      <div className=' flex grid-cols-3  gap-7'> 
    
        <div class=" w-full sm:w-1/2 p-2">
        <iframe width="560" height="312" src="https://www.youtube.com/embed/icjN8PI6xAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="w-full sm:w-1/2 p-2">
        <iframe width="560" height="312" src="https://www.youtube.com/embed/pN2c_MJKpZ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        
      </div>
      </div>

      <div className='block sm:hidden pl-2'> 
    
    <div class=" w-full sm:w-1/2 p-2">
    <iframe width="320" height="200" src="https://www.youtube.com/embed/icjN8PI6xAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="w-full sm:w-1/2 p-2">
    <iframe width="320" height="200" src="https://www.youtube.com/embed/pN2c_MJKpZ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    
  </div>

      <div class="sm:mt-20 mt-14 text-center">
        <a class="inline-block px-6 py-8 sm:py-4 sm:px-12 text-red-50 font-bold text-xl underline" href="/testimonialvideos">View all video testimonials</a>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
