import React from 'react'
import Edushrine from '../images/edushrine1.png'
import ASC from '../images/ASC.png'
import carmel from '../images/carmel.png'
import oxford from '../images/oxford.png'
import cordial from '../images/cordial.png'




export default function academiccenters() {
  return (
    <div>
        <section class=" bg-gradient-to-br from-gray-900 via-gray-900 to-red-600 py-12 sm:py-20 "  style={{fontFamily:'Manrope' }}>
        
  <div class="container px-4 mx-auto">
    <div class=" mx-auto">
      <div class="max-w-xl mb-14 lg:mb-28">
        {/* <span class="text-lg text-blue-400 font-semibold">Our Works</span> */}
        <h2 class="mt-2 sm:mt-8 text-3xl sm:text-5xl text-white font-bold font-heading">Academic Centers <span className=' text-red-500'>(Edushrine Affiliated)</span></h2>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="w-full lg:w-1/3 p-4">
          <img class="w-full h-96 object-cover rounded-lg" src={Edushrine} alt=""/>
        </div>
        <div class="w-full lg:w-1/3 p-4">
          <img class="w-full h-96 object-cover rounded-lg" src={ASC} alt=""/>
        </div>
        {/* <div class="w-full lg:w-1/3 p-4">
          <img class="w-full h-96 object-cover rounded-lg" src={''} alt=""/>
        </div> */}
        {/* <div class="w-full lg:w-1/3 p-4">
          <img class="w-full h-96 object-cover rounded-lg" src={oxford} alt=""/>
        </div> */}
        <div class="w-full lg:w-1/3 p-4">
          <img class="w-full h-96 object-cover rounded-lg" src={cordial} alt=""/>
        </div>
      </div>
    
    </div>
  </div>
</section>
    </div>
  )
}
