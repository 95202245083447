import React from 'react'
import { BsFillArrowDownCircleFill } from 'react-icons/bs';

export default function   sectionone() {
  return (
    <div>
      <section class="py-10 sm:py-4 sm:pt-20 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container sm:px-6 px-4 sm:mx-auto">
   
    <div class="max-w-5xl mx-auto text-center mb-12">
      <h1 class=" text-4xl sm:text-6xl md:text-5xl text-red-600 font-bold">Edushrine has wide range of programs,<br/><span className='text-black text-xl sm:text-2xl'>click to read what interests you.</span></h1>
   
    
    </div>
 
    <span className=' block sm:hidden text-5xl mb-10 text-center px-36 text-red-600 font-bold '>   
    < BsFillArrowDownCircleFill/>
    </span>
   
    <div >
      <div class="flex flex-wrap -mx-4 relative z-10 mb-12">
      <div class="w-full 2xl:w-1/3 px-4">
          <div class="max-w-md mx-auto relative">
            <div class="absolute top-0 left-0 -mt-2 w-full">
              <div class="mx-5 rounded-t-xl h-4 bg-red-600"></div>
            </div>
            <div class="py-12 px-8 sm:p-12 bg-white rounded-xl relative">
              <div class="sm:flex mb-8">
                <div class="flex-shrink-0 flex items-center justify-center w-24 h-24 rounded-full bg-white border border-red-600">
                  <div class="flex items-center justify-center w-18 h-18 rounded-full bg-gray-100">
                    <div class="flex items-center justify-center w-12 h-12 rounded-full bg-red-600 text-white">1</div>
                  </div>
                </div>
                <div class="mt-6 ml-6 font-semibold">
                  <h6 class="text-2xl mb-2 text-red-600">PUC+NEET+CET</h6>
                  <p class="text-lg text-gray-600">Looking for 2-Years</p>
                  <p class="text-lg text-gray-600">course after 10th</p>
                 <a href="/medico"> <p class="mt-4 text-left font-bold text-red-600 underline text-xl" >Learn more</p></a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full 2xl:w-1/3 px-4">
          <div class="max-w-md mx-auto relative">
            <div class="absolute top-0 left-0 -mt-2 w-full">
              <div class="mx-5 rounded-t-xl h-4 bg-red-600"></div>
            </div>
            <div class="py-12 px-8 sm:p-12 bg-white rounded-xl relative">
              <div class="sm:flex mb-8">
                <div class="flex-shrink-0 flex items-center justify-center w-24 h-24 rounded-full bg-white border border-red-600">
                  <div class="flex items-center justify-center w-18 h-18 rounded-full bg-gray-100">
                    <div class="flex items-center justify-center w-12 h-12 rounded-full bg-red-600 text-white">2</div>
                  </div>
                </div>
                <div class="mt-6 ml-6 font-semibold">
                  <h6 class="text-2xl mb-2 text-red-600">PUC+JEE+CET</h6>
                  <p class="text-lg text-gray-600">Looking for 2-Years</p>
                  <p class="text-lg text-gray-600">course after 10th</p>
                  <a href="/techno"> <p class="mt-4 text-left font-bold text-red-600 underline text-xl">Learn more</p></a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full 2xl:w-1/3 px-4">
          <div class="max-w-md mx-auto relative">
            <div class="absolute top-0 left-0 -mt-2 w-full">
              <div class="mx-5 rounded-t-xl h-4 bg-red-600"></div>
            </div>
            <div class="py-12 px-8 sm:p-12 bg-white rounded-xl relative">
              <div class="sm:flex mb-8">
                <div class="flex-shrink-0 flex items-center justify-center w-24 h-24 rounded-full bg-white border border-red-600">
                  <div class="flex items-center justify-center w-18 h-18 rounded-full bg-gray-100">
                    <div class="flex items-center justify-center w-12 h-12 rounded-full bg-red-600 text-white">3</div>
                  </div>
                </div>
                <div class="mt-6 ml-6 font-semibold">
                  <h6 class="text-2xl mb-2 text-red-600">PUC Tutorials</h6>
                  <p class="text-lg text-gray-600">Looking for complete</p>
                  <p class="text-lg text-gray-600">PUC Tutorials</p>
                  <a href="/aboard"> <p class="mt-4 text-left font-bold text-red-600 underline text-xl">Learn more</p></a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div >
      <div class="flex flex-wrap sm:mx-24  relative z-10">
      <div class="w-full 2xl:w-1/2 px-4 ">
          <div class="max-w-md mx-auto relative">
            <div class="absolute top-0 left-0 -mt-2 w-full">
              <div class="mx-5 rounded-t-xl h-4 bg-red-600 "></div>
            </div>
            <div class="py-12 px-8 sm:p-12 bg-white rounded-xl relative">
              <div class="sm:flex mb-8">
                <div class="flex-shrink-0 flex items-center justify-center w-24 h-24 rounded-full bg-white border border-red-600">
                  <div class="flex items-center justify-center w-18 h-18 rounded-full bg-gray-100">
                    <div class="flex items-center justify-center w-12 h-12 rounded-full bg-red-600 text-white">4</div>
                  </div>
                </div>
                <div class="mt-6 ml-6 font-semibold">
                  <h6 class="text-2xl mb-2 text-red-600">9th & 10th Tutorials</h6>
                  <p class="text-lg text-gray-600">Looking for full term</p>
                  <p class="text-lg text-gray-600">9th & 10th</p>
                  <a href="/aboard"></a><p class="mt-4 text-left font-bold text-red-600 underline text-xl">Learn more</p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full 2xl:w-1/2 px-4 ">
          <div class="max-w-md mx-auto relative">
            <div class="absolute top-0 left-0 -mt-2 w-full">
              <div class="mx-5 rounded-t-xl h-4 bg-red-600"></div>
            </div>
            <div class="py-12 px-8 sm:py-12  bg-white rounded-xl relative">
              <div class="sm:flex mb-8">
                <div class="flex-shrink-0 flex items-center justify-center w-24 h-24 rounded-full bg-white border border-red-600">
                  <div class="flex items-center justify-center w-18 h-18 rounded-full bg-gray-100">
                    <div class="flex items-center justify-center w-12 h-12 rounded-full bg-red-600 text-white">5</div>
                  </div>
                </div>
                <div class="mt-6 ml-6 font-semibold">
                  <h6 class="text-2xl mb-2 text-red-600">School Management</h6>
                  <p class="text-lg text-gray-600">Looking for</p>
                  <p class="text-lg text-gray-600">Counselling Service</p>
                  <a href="/margadarshi"> <p class="mt-4 text-left font-bold text-red-600 underline text-xl" href="/margadarshi">Learn more</p></a>

                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
     
    </div>
   
    </div>


    
  </div>
</section>

    </div>
  )
}
