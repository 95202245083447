import React from 'react'
import Contact from '../contactpage/contact'

export default function index() {
  return (
    <div>
        <Contact/>
    </div>
  )
}
