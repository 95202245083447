import React from 'react'
import { useSelector } from 'react-redux'
import { authenticateSelector } from '../../api/authSlice'
import { course_materialSelector } from '../../api/courseMaterial'
import {HiOutlineDownload} from 'react-icons/hi'
import { useLocation } from 'react-router-dom'

export default function Studymaterial() {
  const {pathname} =useLocation()
  // const {current_course_material} = useSelector(course_materialSelector)

  // console.log(current_course_material.course.attachments)
  const {user} = useSelector(authenticateSelector)
  console.log(pathname)
  return (
    <div>

<section class="pt-5 pb-8">
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap items-center mb-8">
    </div>
    <div class="flex flex-wrap -mx-4 -mb-6">
    {user?.course_materials.map((item)=>{
      
           return item.material?.material_type == 'Study Material' && item.material?.course_subject == 'Physics' && pathname == '/phys'?
           <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div class="relative p-6 bg-white rounded-xl">  
          <h4 class="text-2xl text-gray-600 font-bold mb-3">{item?.material?.chapter}</h4>
          <p class="text-red-800 text-4xl font-bold mb-5">{item?.material?.course_title}</p>
          <div class="flex items-center mb-6"> 
            <span class="text-lg font-bold text-gray-600 bg-red-100 rounded-lg px-2">Study Material</span>
          </div>

          <div class="flex rounded-xl  bg-red-800">
          <a class=" py-4 px-12 text-left text-white font-bold text-lg leading-6 w-full flex justify-around   transition duration-200" href={item?.material?.attachments}>Download Material<span class="text-2xl "><HiOutlineDownload/></span></a>
          </div>

        </div>
      </div>: item.material?.material_type == 'Study Material' && item.material?.course_subject == 'Chemistry' && pathname == '/chem' ?<div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div class="relative p-6 bg-white rounded-xl">  
          <h4 class="text-2xl text-gray-600 font-bold mb-3">{item?.material?.chapter}</h4>
          <p class="text-red-800 text-4xl font-bold mb-5">{item?.material?.course_title}</p>
          <div class="flex items-center mb-6"> 
            <span class="text-lg font-bold text-gray-600 bg-red-100 rounded-lg px-2">Study Material</span>
          </div>

          <div class="flex rounded-xl  bg-red-800">
          <a class=" py-4 px-12 text-left text-white font-bold text-lg leading-6 w-full flex justify-around   transition duration-200" href={item?.material?.attachments}>Download Material<span class="text-2xl "><HiOutlineDownload/></span></a>
          </div>

        </div>
      </div> :item.material?.material_type == 'Study Material' && item.material?.course_subject == 'Maths' && pathname =='/maths'?<div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div class="relative p-6 bg-white rounded-xl">  
          <h4 class="text-2xl text-gray-600 font-bold mb-3">{item?.material?.chapter}</h4>
          <p class="text-red-800 text-4xl font-bold mb-5">{item?.material?.course_title}</p>
          <div class="flex items-center mb-6"> 
            <span class="text-lg font-bold text-gray-600 bg-red-100 rounded-lg px-2">Study Material</span>
          </div>

          <div class="flex rounded-xl  bg-red-800">
          <a class=" py-4 px-12 text-left text-white font-bold text-lg leading-6 w-full flex justify-around   transition duration-200" href={item?.material?.attachments}>Download Material<span class="text-2xl "><HiOutlineDownload/></span></a>
          </div>

        </div>
      </div> : item.material?.material_type == 'Study Material' && item.material?.course_subject == 'Biology' && pathname == '/combination4' ?<div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div class="relative p-6 bg-white rounded-xl">  
          <h4 class="text-2xl text-gray-600 font-bold mb-3">{item?.material?.chapter}</h4>
          <p class="text-red-800 text-4xl font-bold mb-5">{item?.material?.course_title}</p>
          <div class="flex items-center mb-6"> 
            <span class="text-lg font-bold text-gray-600 bg-red-100 rounded-lg px-2">Study Material</span>
          </div>

          <div class="flex rounded-xl  bg-red-800">
          <a class=" py-4 px-12 text-left text-white font-bold text-lg leading-6 w-full flex justify-around   transition duration-200" href={item?.material?.attachments}>Download Material<span class="text-2xl "><HiOutlineDownload/></span></a>
          </div>

        </div>
      </div> :item.material?.material_type == 'Study Material' && item.material?.course_subject == 'Computer Science' && pathname == '/combination4' ?<div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div class="relative p-6 bg-white rounded-xl">  
          <h4 class="text-2xl text-gray-600 font-bold mb-3">{item?.material?.chapter}</h4>
          <p class="text-red-800 text-4xl font-bold mb-5">{item?.material?.course_title}</p>
          <div class="flex items-center mb-6"> 
            <span class="text-lg font-bold text-gray-600 bg-red-100 rounded-lg px-2">Study Material</span>
          </div>

          <div class="flex rounded-xl  bg-red-800">
          <a class=" py-4 px-12 text-left text-white font-bold text-lg leading-6 w-full flex justify-around   transition duration-200" href={item?.material?.attachments}>Download Material<span class="text-2xl "><HiOutlineDownload/></span></a>
          </div>

        </div>
      </div>:  item.material?.material_type == 'Study Material' && item.material?.course_subject == 'Electronics' && pathname == '/combination4'?<div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div class="relative p-6 bg-white rounded-xl">  
          <h4 class="text-2xl text-gray-600 font-bold mb-3">{item?.material?.chapter}</h4>
          <p class="text-red-800 text-4xl font-bold mb-5">{item?.material?.course_title}</p>
          <div class="flex items-center mb-6"> 
            <span class="text-lg font-bold text-gray-600 bg-red-100 rounded-lg px-2">Study Material</span>
          </div>

          <div class="flex rounded-xl  bg-red-800">
          <a class=" py-4 px-12 text-left text-white font-bold text-lg leading-6 w-full flex justify-around   transition duration-200" href={item?.material?.attachments}>Download Material<span class="text-2xl "><HiOutlineDownload/></span></a>
          </div>

        </div>
      </div> : ""
    })}
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------- */}
</div>
  )
}
