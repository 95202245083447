import React, { useEffect, useState } from 'react'
import Accordion from '../components/results/accordion'
import {authenticateSelector} from '../api/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import {Link } from 'react-router-dom'
import { Collapse,Table } from 'antd';
import Title from 'antd/es/skeleton/Title'
import '../index.css'

const { Panel } = Collapse;



export default function Index() {
  
    const {user} = useSelector(authenticateSelector)
    const [active, setActive]= useState('0')
  

    const keyHandler=(e)=>{
      console.log(e)
      setActive(e)
    }


  return (  
    <div>
    {
      user?.ptb_results?.map((item,i)=>{
 
  
       return  <Collapse accordion= {true} destroyInactivePanel={true} className=' mx-auto w-[80%]' activeKey={active} onChange={(e)=>keyHandler(e)}>
      <Panel header={item?.exam_name} extra = {item?.percentage} key={i}>
      <table className=' mx-auto align-center'>
          <tr className=' text-center'>
            <th className=' p-3'>Subjects</th>
            <th>Maximum Marks</th>
            <th>Marks Secured</th>
          </tr>
          <tr className=' text-center'>
            <td className=' p-3'>Language 1</td>
            <td>{item?.lang_marks}</td>
            <td>{item?.lang_marks_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-3'>English</td>
            <td>{item?.eng_marks}</td>
            <td>{item?.eng_marks_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-3'>Physics</td>
            <td>{item?.phy_marks}</td>
            <td>{item?.phy_marks_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-3'>Chemistry</td>
            <td>{item?.chem_marks}</td>
            <td>{item?.chem_marks_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-3'>Mathematics</td>
            <td>{item?.math_marks}</td>
            <td>{item?.math_marks_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-3'>Bio/C.Sc/Ele</td>
            <td>{item?.combination4_marks}</td>
            <td>{item?.combination4_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-4'>Total</td>
            <td>{item?.total_marks}</td>
            <td>{item?.total_marks_obtained}</td>
          </tr>
          <tr className=' text-center'>
            <td className=' p-4'>Percentage</td>
            <td colSpan={2}>{item?.percentage}%</td>
           
          </tr>
    </table>

      </Panel>
    </Collapse>
  }) } 
  </div>
  )
}
 