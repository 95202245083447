import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Icon from '../src/components/images/fav-icon.png'
import ReactGA from 'react-ga4';


import { Route, Routes } from 'react-router-dom'
import Homepage from '../src/components/homepage/index'
import Successstories from '../src/components/SuccessStories/index'
import AcademicCenters from './components/academiccenters/index'
import Blog from './components/blog/index'
import BlogsPage from './components/blog/sectionone'
import ContactPage from './components/contactpage/index'
import News from './components/news/index'
import Aboutus from './components/pages/aboutus'
import Faculty from './components/pages/faculty'
import Gallery from './components/pages/gallery'
import Appwrap from './components/global/appwrap'
import Courses from './components/pages/course'
import Margadarshi from './components/pages/margadarshi'
import Testimonials from './components/Testimonials/reviews'
import Videos from './components/Testimonials/testimonialvideos'
import Kcetvideos from './components/Testimonials/kcetvideos'


import NewsPage from './components/news/newsone'

import Profile from './components/Profile/index'
import ParentLogin from './components/Profile/parentLogin'
import StudentLogin from './components/Profile/studentLogin'
import UserProfile from './components/pages/LoggedIn/index'

import Medico from './components/courses/medico'
import Aboard from './components/courses/aboard'
import Aboardonline from './components/courses/aboardonline'
import Crash from './components/courses/crash'
import Kcet from './components/courses/kcet'
import Neet from './components/courses/neet'
import Neetonline from './components/courses/neetonline'
import Ntse from './components/courses/ntse'
import Ntseonline from './components/courses/ntseonline'
import Onlineneetandcet from './components/courses/onlineneetandcet'
import Techno from './components/courses/techno'
import ResultPage from './components/results/resultsNew'
import FeeDetailsPage from './components/feeDetails/index'
import Dashboard from './components/student/dashboard'
import MyProfile from './components/student/myprofile'
import Noty from './components/student/noty'



import Phys from '../../Edushrine1/src/material/phys'
import Chem from '../../Edushrine1/src/material/chem'
import Maths from '../../Edushrine1/src/material/maths'
import Bio from '../../Edushrine1/src/material/bio'


import Broadcast from './components/broadcast/index'




import ScrollToTop from "react-scroll-to-top"


import Studentheader from '../src/components/student/studentheader'



export default function App() {

  return (


    <div className="App">
      
 <FloatingWhatsApp
        phoneNumber="7090772505"
        accountName="Edushrine"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={Icon}
        
        
      />


<div>
   

      <ScrollToTop  smooth className="mb-12 pl-2" color ="red" />
    </div>
     <div>
      
          <TawkMessengerReact 
            propertyId="5fa921d50a68960861bd1603"
            widgetId="default"
            />
      
<Appwrap > 

       <Routes >

       <Route exact path="/" element={<Homepage/>}/>
       <Route exact path="/Successstories" element={<Successstories/>}/>
       <Route exact path='/academiccenters' element={<AcademicCenters/>}/>

       <Route exact path='/reviews' element={<Testimonials/>}/>
       <Route exact path='/testimonialvideos' element={<Videos/>}/>
       <Route exact path='/kcetvideos' element={<Kcetvideos/>}/>

       {/* <Route path="/profile" element={<StudentLogin/>} /> */}
       <Route path = "/parent-login" element={<ParentLogin/>}/>
       <Route path = "/student-login" element={<StudentLogin/>}/>
       <Route path = '/user-profile' element={<UserProfile/>}/>
       <Route exact path="/aboutus" element={<Aboutus/>}/>
       <Route exact path="/faculty" element={<Faculty/>}/>
       <Route exact path="/gallery" element={<Gallery/>}/>
       <Route exact path="/course" element={<Courses/>}/>
       <Route exact path="/margadarshi" element={<Margadarshi/>}/>


       <Route exact path="/contact" element={<ContactPage/>}/>
       <Route exact path = "/medico" element= {<Medico/>}/>
       <Route exact path = "/techno" element= {<Techno/>}/>
       <Route exact path = "/neet" element= {<Neet/>}/>
       <Route exact path = "/neetonline" element= {<Neetonline/>}/>
       <Route exact path = "/aboard" element= {<Aboard/>}/>
       <Route exact path = "/aboardonline" element= {<Aboardonline/>}/>
       <Route exact path = "/ntse" element= {<Ntse/>}/>
       <Route exact path = "/ntseonline" element= {<Ntseonline/>}/>
       <Route exact path = "/onlineneetandcet" element= {<Onlineneetandcet/>}/>
       <Route exact path = "/crash" element= {<Crash/>}/>
       <Route exact path = "/kcet" element= {<Kcet/>}/>


       <Route exact path="/blogs" element={<Blog/>}/>
       <Route path="/blog/:id" element={<BlogsPage/>}/>

       <Route exact path="/news" element={<News/>}/>
       <Route exact path = "/news/:id" element={<NewsPage/>}/>

   
       <Route exact path = "fee-details/:id" element ={<FeeDetailsPage/>}/>

       <Route exact path = "/dashboard" element ={<Dashboard/>}/>
       <Route exact path = "/results" element ={<ResultPage/>}/>
       <Route exact path = "/myprofile" element ={<MyProfile/>}/>
       <Route exact path = "/noty" element ={<Noty/>}/>

       
       <Route exact path = "/phys" element ={<Phys/>}/>
       <Route exact path = "/chem" element ={<Chem/>}/>
       <Route exact path = "/maths" element ={<Maths/>}/>
       <Route exact path = "/combination4" element ={<Bio/>}/>

       <Route exact path = "/broadcast" element ={<Broadcast/>}/>

  

       </Routes>
       
       </Appwrap>

    </div>

    
    </div>
  )
}