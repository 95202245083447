import {React, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authenticateSelector } from '../api/authSlice'
// import Course from '../images/course1.png'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Studymaterial from '../components/input/studymaterial'
import Assignment from '../components/input/assignment'
import TaskSheet from '../components/input/tasks'
import { fetchOnecourse_material,course_materialSelector } from '../api/courseMaterial'

export default function Maths() {

  const [isMenuOpen, setIsMenuOpen] = useState('Study');
 
  console.log(isMenuOpen)

  const {user} = useSelector(authenticateSelector)

  console.log({user})
  
  return (

<div className='bg-white sm:pb-64'>

<section class="relative pt-5 pb-10 md:pb-10 overflow-hidden" >
  <div class="container mx-auto">
    <div class="flex flex-wrap max-w-7xl mx-auto -m-7">
    <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-gray-700 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('Study')}>Study Material</h3>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-gray-700 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('Assign')}>Assignment</h3>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-gray-700 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
       </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('Task')}>View tasks</h3>
        </div>
      </div>
      
    </div>
  </div>
  </section>

<div>
  {
    isMenuOpen==='Study'?
    (<div><Studymaterial/></div>):(<></>)
  }
  {
    isMenuOpen==='Assign'?
    (<div><Assignment/></div>):(<></>)
  }
  {
    isMenuOpen==='Task'?
    (<div><TaskSheet/></div>):(<></>)
  }

</div>
</div>


  )
}
 