import { createSlice } from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios'
import {keyUri,config} from '../key'

export const initialState = {

    loading: false,
 
    hasErrors: false,
    all_contact:[],
    currentenquiry:null,
}

export const contactSlice = createSlice({
    name:"contact",
    initialState,
    reducers:{

        getcontact: state =>{

            state.loading = true
        },

        getcontactSuccess: (state, {payload}) =>{
          console.log(payload)
            state.loading = false
            state.all_contact = payload 
        },

        getCurrentcontact: (state, {payload}) =>{
          console.log({k:payload});

            state.loading = false
            state.currentenquiry = payload
            
        },
        getcontactFailure: (state) =>{

            state.loading = false
            // state. enquiry = payload
        
            
        },
      

    }
})

export const {getcontact, getcontactSuccess, getCurrentcontact, getcontactFailure } = contactSlice.actions
      
export const contactSelector = state => state.contact

  
  
  
  


  export const  createcontact = (values) => async dispatch =>{
    console.log(values);
    const key = " contact"
    dispatch(getcontact())
    // message.loading({ content: 'loading...', key })
    
  try {

      const data = await axios.post(keyUri.BACKEND_URI +'/contact', values, config);
      console.log(data)
 
      dispatch(getcontactSuccess(data))
      
      toast.success('Your enquiry has been submitted successfully, we will get in touch with you', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
     
      // setTimeout(() => {
      //   toast.success("Hello")
      //   // message.success({ content: data.msg, key, duation: 2 });
      // }, 500) 
      
    //   window.location.reload()
      
  } catch ({response}) {
    toast.error(' You have already sent your enquiry!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
       dispatch(getcontactFailure())
  }
  
}
export default contactSlice.reducer
