
const heading =[
    {
        
        question:"What is the difference between PUC-I & II and Class XI & XII?",
        answer:"If it is the question of syllabus, after 2012 both are same. Qualifying examination is conducted by Karnataka PU Board in case of PUC and by CBSE board in case of Class-XI & XII. CBSE Board examination contains more application and skill based questions and is relatively tougher. While PU Board examination contains more Knowledge and understanding based questions and is easier."
},


{
        
    question:"What are the various courses available for students at PUC level?",
    answer:" For Science  CombinationSubjectsPCMBPhysics, Chemistry, Mathematics & BiologyPCMEPhysics, Chemistry, Mathematics & ElectronicsPCMCPhysics, Chemistry, Mathematics & Computer SciencePCMGPhysics, Chemistry, Mathematics & GeologyPCBHPhysics, Chemistry, Biology & Home SciencePCBSPhysics, Chemistry, Mathematics & StatisticsCBPHChemistry, Biology, Psychology & Home Science"
},
{
        
    question:"What are the entrances medical aspirants can appear?",
    answer:"KCET and COMED-K for joining MBBS/BDS/Veternary/B.Sc. Agriculture in Karnataka. AIPMT (All India Pre-Medical Test) – 15% merit seats in MBBS and BDS in India.AIIMS-MBBS Entrance for joining MBBS in All India Institute of Medical Sciences (AIIMS) in New Delhi, Bhopal, Bhubaneswar, Jodhpur, Patna, Raipur, Rishikesh JIPMER-MBBS Entrance for joining MBBS in Jawaharlal Institute for Postgraduate Medical Education and Research, Puducherry."
},

{
        
    question:"What is an integrated Coaching Programme?",
    answer:"Training students simultaneously for board examinations as well as entrance examinations. This is how an integrated coaching programme has to function.The lecturer who is experienced in teaching entrance examinations should teach from the fundamental of the chapter and for both board exams and entrances. Separate classroom practice should be given simultaneously for board and entrances.Tests should be conducted in both subjective (for board) and objective (for entrances) patterns.Regular analysis of performance should be done to diagnose strengths and weaknesses of students to help them best cope up with the programme.Advantages:Since same teacher teaches from fundamentals to advanced concepts, there will be sequential building of concepts.Since it is a single institution for both preparations, physical stress of travelling is reduced.Learning at two different places confuses student due to different approaches towards the same concept. Also revision becomes difficult since two different concepts will be taught simultaneously. Such confusions are avoided in integrated programme. Mental stress will be lesser as separate exam schedules and time slots are given for board and entrance tests in same premises. In case of two institutions there is always chance for overlapping of tests and classes and demands sacrifice of anyone.Student gets ample time do self-revision at home."
}


]


export {heading}


