import React from 'react'
import Logo from '../images/logo1.png'
import Logo1 from '../images/logo2.png'
import Logo2 from '../images/logo3.png'

import Logo4 from '../images/logo5.png'
import Edu from '../images/educollege.png'

import ASC from '../images/ASCcollege.png'
import Carmel from '../images/carmelcollege.png'

import Cordial from '../images/cordialcollege.png'





export default function edu() {
  return (
    <div>
        <section class=" py-4 sm:py-20 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <h2 class="mb-5 text-4xl sm:text-7xl text-red-600 xl:text-10xl text-center font-bold font-heading font-heading tracking-px-n leading-none">Academic <span className='text-gray-900'>Centers</span></h2>
    <p class="mb-4 text-sm sm:text-md  text-black text-center font-semibold leading-normal md: max-w-5xl mx-auto">Apart from its own academic center in Nagadevanahalli, Kengeri, EDUSHRINE has associated with such institutions that are eminent for their academics, provide a state of art infrastructure and support EDUSHRINE execute its academic programs to the best.</p>
   
  </div>
</section>

<section class="px-8 pb-28 pt-1  sm:pt-4 sm:pb-32 sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="hidden sm:block container mx-auto px-4">
    <div class="flex grid-cols-6 justify-center -m-10">
      <a href='#Edushrine'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
        <img src={Logo} alt=""/>
      </div></a>
      <a href='#ASC'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo1} alt=""/>
      </div></a>
      <a href='#carmel'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo2} alt=""/>
      </div></a>
      {/* <a href='#oxford'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo3} alt=""/>
      </div></a> */}
      <a href='#cordial'><div class="w-auto h-auto p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo4} alt=""/>
      </div></a>
    </div>
  </div>

  <div class="block sm:hidden container mx-2 ">
    <div class=" justify-center grid grid-cols-2 ">
      <a href='#Edushrine'>
        <div class="w-full h-full  transform hover:translate-y-2 ease-in-out duration-100">
        <img src={Logo} alt=""/>
      </div></a>
      <a href='#ASC'><div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo1} alt=""/>
      </div></a>
      </div>
      <div class=" justify-center grid grid-cols-2 ">
      <a href='#carmel'><div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo2} alt=""/>
      </div></a>
      {/* <a href='#oxford'><div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo3} alt=""/>
      </div></a> */}

      <div class=" justify-center grid grid-cols-1 ">
      <a href='#cordial'><div class="w-full h-full transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Logo4} alt=""/>
      </div></a>
      </div>
      </div>
  </div>

</section>

<section id='Edushrine' class=" px-4  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center">
      <div class="w-full md:w-1/2 ">
        <img class="mx-auto transform hover:translate-y-3 transition ease-in-out duration-1000" src={Edu} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8 pl-2 sm:pl-28 ">
        <div class="md:max-w-xl">
          {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
          <h2 class=" text-3xl sm:text-6xl font-bold font-heading tracking-px-n leading-tight text-red-600">Edushrine<span className='text-black'>Academy</span></h2>
         </div>
         <div class="pt-8  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium"><span className='text-red-600 font-bold'>EDU-SHRINE ACADEMY </span>provides Effective Coaching and mentoring for High School & PUC Students for their Board as well as Competitive Examinations like NTSE, KVPY, JEE, NEET, Olympiads etc.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium">We also conduct Two year Integrated Coaching Programmes in association with the Colleges.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium"><span className='text-red-600 font-bold'>Bhavishya</span> – a Futuristic Foundation for high school, Margdarshak, a Career Education Council are unique initiatives of the academy.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium">Meticulous schedule, Comprehensive Result-oriented study content, Passionate faculty with scientific approach are our strengths.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium">All the Programmes we implement are student centred and it is not the success, but the process of achieving it the students would enjoy most with us.</p>
            </div>

      </div>
    </div>
  </div>
</section>

<section class="px-2 pb-4  sm:pb-24 sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="hidden sm:block container mx-auto px-4">
    <div class="flex flex-wrap items-center -m-6">
      <div class="w-full md:w-1/2 p-6">
        <div class="max-w-lg">
          {/* <h2 class="mb-5 px-6 font-heading font-bold text-2xl  sm:text-3xl text-black underline">Courses</h2> */}
          <div class="flex grid-cols-5  ">
          <div class="flex-shrink-0 p-4 mb-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl   text-center underline">CLASS-X </p>
          <p class="text-black text-lg text-center font-semibold ">CBSE, ICSE, SSLC, NTSE </p>

        </div>
        </div>

        <div class="flex-shrink-0 p-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl underline text-center">PU Science </p>
          <p class="text-black text-lg text-center font-semibold ">PCMB, NEET, JEE, CET & AIIMS </p>
       

        </div>
        </div>

        <div class="flex-shrink-0 p-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl  text-center underline">PU Commerce </p>
          <p class="text-black text-lg text-center font-semibold ">ABMSE, CA, CS, CMA & CLAT </p>
       
        </div>
        </div>

        <div class="flex-shrink-0 p-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl  text-center underline">BHAVISHYA </p>
          <p class="text-black text-lg text-center font-semibold ">VIII & IX Foundation, CBSE,  </p>
      
        </div>
        </div>
         </div>
        </div>
      </div>
      

    </div>
  </div>

  <div class=" block sm:hidden container mx-2 pr-4">
    <div>
      <div class="w-full md:w-1/2 p-6">
        <div class="max-w-sm">
          {/* <h2 class="mb-5 px-6 font-heading font-bold text-2xl  sm:text-3xl text-black underline">Courses</h2> */}
          <div >
          <div class="p-4 mb-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold t ext-xl   text-center underline">CLASS-X </p>
          <p class="text-black text-lg text-center font-semibold ">CBSE, ICSE, SSLC, NTSE </p>

        </div>
        </div>

        <div class="flex-shrink-0 p-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl underline text-center">PU Science </p>
          <p class="text-black text-lg text-center font-semibold ">PCMB, NEET, JEE, CET & AIIMS </p>
       

        </div>
        </div>

        <div class="flex-shrink-0 p-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl  text-center underline">PU Commerce </p>
          <p class="text-black text-lg text-center font-semibold ">ABMSE, CA, CS, CMA & CLAT </p>
       
        </div>
        </div>

        <div class="flex-shrink-0 p-4">
        <div class="max-w-sm bg-red-50 py-10 px-9 rounded-10">
          <p class="text-red-600 font-bold text-xl  text-center underline">BHAVISHYA </p>
          <p class="text-black text-lg text-center font-semibold ">VIII & IX Foundation, CBSE,  </p>
      
        </div>
        </div>
         </div>
        </div>
      </div>
      

    </div>
  </div>

</section>

<div className=' hidden sm:block'>
<section class="  relative flex grid-cols-3 px-4 2xl:py-14 pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
  <div class="container w-1/3 px-4 mx-auto">
  <h2 class="mt-8 pb-6 text-3xl font-bold font-heading text-white">Course Timings</h2>
    <div class="max-w-5xl mx-auto mb-20 overflow-x-auto overflow-y-hidden">
      <table class="table-auto w-full">
        <thead class="bg-red-700">
          <tr class="text-lg text-white text-left"><th class=" text-center py-2 font-medium ">Batch  &amp;  Time</th><th class=" text-center font-medium">Class  &amp;  Course</th></tr>
        </thead>
        <tbody class="bg-gray-900">
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                
                <h4 class="text-xl text-white text-center">6:00 am– 8:00 am</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">	✦ SSLC ✦ CBSE ✦ ICSE</td>
          </tr>
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                <h4 class="text-xl text-white">6:00 pm– 8:00 pm</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">✦ SSLC ✦ CBSE</td>
          </tr>
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                <h4 class="text-xl text-white">4:00 pm– 6:00 pm</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">	✦ I / II PU Commerce</td>
          </tr>
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                <h4 class="text-xl text-white">5:30 pm– 8:30 pm</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">	✦ I / II PU Science (Regular)</td>
          </tr>
         
        
        </tbody>
      </table>
    </div>
  </div>

 <div class="w-full md:w-1/2  pb-10 ">
        <div class="md:max-w-lg ml-16">
        
            <div class="mb-10   bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-4 font-heading font-semibold text-white text-xl">Contact <span className='text-white'>Coordinator:</span> </h3>
                        <p class="text-white font-normal">#20, I Floor, Kengeri Ring Road, R R Layout, Nagadevanahalli main road, Jnana Jyothi Nagar, Bangalore 560056</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white font-normal">080-28485416/7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
         
            <div class="mb-10  p-px bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                        <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandra layout, Bengaluru-560072</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white text-base">080-28485416 / 7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
        
        </div>
      </div>
</section>
</div>


<div className=' block sm:hidden'>
<section class="  relative px-2 py-4  sm:px-4 sm:py-14 sm:pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
  <div class="container w-full">
  <h2 class="mt-8 pb-6 text-4xl text-center sm:text-3xl font-bold font-heading text-white">Course Timings</h2>
    <div class="max-w-5xl mx-auto mb-20 overflow-x-auto overflow-y-hidden">
      <table class="table-auto w-full">
        <thead class="bg-red-700">
          <tr class="text-lg text-white text-left"><th class=" text-center py-2 font-medium ">Batch  &amp;  Time</th><th class=" text-center font-medium">Class  &amp;  Course</th></tr>
        </thead>
        <tbody class="bg-gray-900">
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                
                <h4 class="text-xl text-white text-center">6:00 am– 8:00 am</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">	✦ SSLC ✦ CBSE ✦ ICSE</td>
          </tr>
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                <h4 class="text-xl text-white">6:00 pm– 8:00 pm</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">✦ SSLC ✦ CBSE</td>
          </tr>
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                <h4 class="text-xl text-white">4:00 pm– 6:00 pm</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">	✦ I / II PU Commerce</td>
          </tr>
          <tr class="border-b border-gray-500">
            <td class="w-1/2 py-4 pl-10 text-left border-r border-gray-500">
              <div class="flex items-center">
                <h4 class="text-xl text-white">5:30 pm– 8:30 pm</h4>
              </div>
            </td>
            <td class="w-1/2 pl-14 px-4 text-lg text-gray-200 border-r border-gray-500">	✦ I / II PU Science (Regular)</td>
          </tr>
         
        
        </tbody>
      </table>
    </div>
  </div>

 <div class="w-full   pb-6 ">
        <div>
        
            <div class="mb-8 mx-2   bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-4 font-heading font-semibold text-white text-xl">Contact <span className='text-white'>Coordinator:</span> </h3>
                        <p class="text-white font-normal">#20, I Floor, Kengeri Ring Road, R R Layout, Nagadevanahalli main road, Jnana Jyothi Nagar, Bangalore 560056</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white font-normal">080-28485416/7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
         
            <div class="mb-8 mx-2 bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                        <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandra layout, Bengaluru-560072</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white text-base">080-28485416 / 7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
        
        </div>
      </div>
</section>
</div>

<div>
     
<section id='ASC' class=" pt-16 pb-10 px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center">
      <div class="w-full md:w-1/2 ">
        <img class="mx-auto transform hover:translate-y-3 transition ease-in-out duration-1000 " src={ASC} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-4  pl-2 sm:pl-28">
        <div class="md:max-w-xl">
          <h2 class=" text-3xl sm:text-4xl font-bold font-heading tracking-px-n leading-tight text-red-600">ASC  <span className='text-black'>Independent PU College  </span></h2>
         </div>
         <div class="pt-8  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium">ASC is a premier institution that has arrived on the education scenario with excellent academic and curricular results since its inception in 2012. Established by a team of academicians with a vision to impart Excellence with Values in the fields of Science, Technology and Commerce. Easily reachable, the College is located at the end of the West of Chord Road, near the tollgate, Rajajinagar, Bangalore.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg">
              <p class="text-gray-900 text-md font-medium">Edu-Shrine is conducting extensive integrated Coaching Programmes for Medical, Engineering and KVPY aspirants as well as CET coaching for all the batches in ASC.</p>
            </div>


      </div>
    </div>
  </div>
</section>


<div className='hidden sm:block'>
<section class="relative flex grid-cols-3 px-4 2xl:py-14 pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
  <div class="container list-disc w-1/3 px-4 mx-auto">
  <h2 class="mt-8 pb-6 text-3xl font-extrabold font-heading text-white">Courses</h2>
  <ul className='ml-4 list-disc'>
            <ul className='mb-6 text-md lg:text-lg font-bold text-white list-disc '>
              <li className='mb-4'>PU Science <span className='text-red-300'>+ CET / COMED-K</span> </li>
              <li className='mb-4'> PU Science  <span className='text-red-300'>+ NEET (Medical Entrance)</span></li>
              <li className='mb-4'>PU Science  <span className='text-red-300'>+ JEE-Mains </span></li>
              <li className='mb-4'> PU Science  <span className='text-red-300'>+ JEE-Advanced</span></li>
              <li className='mb-4'> PU Commerce  <span className='text-red-300'>+ CA-Foundation</span></li>
              <li className='mb-4'> PU Commerce  <span className='text-red-300'>+ CS-Foundation</span> </li>
              <li className='mb-4'> PU Commerce  <span className='text-red-300'>+ CLAT</span> </li>
            </ul>
          </ul>
  </div>

 <div class="w-full md:w-1/2  pb-12 ">
        <div class="md:max-w-lg ml-16">
        
            <div class="mb-10   bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-4 font-heading font-semibold text-white text-xl">Contact <span className='text-white'>Coordinator:</span> </h3>
                        <p class="text-white font-normal">#A-3,1st Main Road, 6thBlock, Rajajinagar Industrial Estate, Bengaluru-560010</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white font-normal">7090772503/8217447029</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
         
            <div class="mb-10  p-px bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                        <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandralayout, Bengaluru-560072</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white text-base">080-28485416 / 7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
        
        </div>
      </div>
</section>
</div>


<div className='block sm:hidden'>
<section class="relative  sm:px-4 py-4 sm:py-14 sm:pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
  <div class="container list-disc w-full px-6 ">
  <h2 class="sm:mt-8 sm:pb-6 sm:text-3xl text-3xl pb-4 mt-4  font-extrabold font-heading text-white">Courses</h2>
  <ul className='ml-4 py-4  list-disc'>
            <ul className='mb-6 text-md lg:text-lg font-bold text-white list-disc '>
              <li className='mb-4'>PU Science <span className='text-red-300'>+ CET / COMED-K</span> </li>
              <li className='mb-4'> PU Science  <span className='text-red-300'>+ NEET (Medical Entrance)</span></li>
              <li className='mb-4'>PU Science  <span className='text-red-300'>+ JEE-Mains </span></li>
              <li className='mb-4'> PU Science  <span className='text-red-300'>+ JEE-Advanced</span></li>
              <li className='mb-4'> PU Commerce  <span className='text-red-300'>+ CA-Foundation</span></li>
              <li className='mb-4'> PU Commerce  <span className='text-red-300'>+ CS-Foundation</span> </li>
              <li className='mb-4'> PU Commerce  <span className='text-red-300'>+ CLAT</span> </li>
            </ul>
          </ul>
  </div>

 <div class="w-full  pb-6 ">
        <div >
        
            <div class="mb-8 mx-4   bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-4 font-heading font-semibold text-white text-xl">Contact <span className='text-white'>Coordinator:</span> </h3>
                        <p class="text-white font-normal">#A-3,1st Main Road, 6thBlock, Rajajinagar Industrial Estate, Bengaluru-560010</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white font-normal">7090772503/8217447029</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
         
            <div class="mb-8 mx-4 bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                        <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandralayout, Bengaluru-560072</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white text-base">080-28485416 / 7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
        
        </div>
      </div>
</section>
</div>

    </div>



    <div>
     






<div className='block sm:hidden'>
<section class="relative  py-4 px-2  sm:px-4 sm:py-14 sm:pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
  <div class="container list-disc w-full px-6">
  <h2 class="sm:mt-8 sm:pb-6 sm:text-3xl text-3xl pb-4 mt-4 font-extrabold font-heading text-white">Courses</h2>
  <ul className='ml-4 py-4 mx-6 list-disc'>
            <ul className='mb-6 text-md lg:text-lg font-bold text-white list-disc '>
              <li className='mb-4'>PU Science <span className='text-red-300'>: PCMB / PCMC</span> </li>
              
            <li className='mb-4'> PU Commerce  <span className='text-red-300'>: SEBA,  CEBA, ABMS</span></li>
             
            </ul>
          </ul>
  </div>

 <div class="w-full   pb-8 ">
        <div>
        
            <div class="mb-8 mx-4  bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-4 font-heading font-semibold text-white text-xl">Contact <span className='text-white'>Coordinator:</span> </h3>
                        <p class="text-white font-normal">East end Main road, 4th T Block, Jayanagar, BENGALURU-560041</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white font-normal">7090772509</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
         
            <div class="mb-8 mx-4 bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-700 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                        <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandralayout, Bengaluru-560072</p>
                      
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-white text-base">080-28485416 / 7090772512</p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
        
        </div>
      </div>
</section>
</div>

    </div>



    <div>
     
   

    




     
     <section id='cordial' class=" pt-16 pb-8 px-2  sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       <div class="container px-4 mx-auto">
         <div class="flex flex-wrap xl:items-center">
           <div class="w-full sm:w-1/2 ">
             <img class="mx-auto transform hover:translate-y-3 transition ease-in-out duration-1000 " src={Cordial} alt=""/>
           </div>
           <div class="w-full sm:w-1/2 p-8 sm:pl-28 pl-2 ">
             <div class="md:max-w-xl ">
               {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
               <h2 class=" text-3xl sm:text-3xl font-bold font-heading tracking-px-n leading-tight text-red-600">Cordial <span className='text-black'>International Residential School & PU College</span></h2>
              </div>
              <div class="pt-8  lg:max-w-lg">
                   <p class="text-gray-900 text-md font-medium">CORDIAL SCHOOL, MADHUGIRI is a twenty year old prime school in Madhurgiri that has been consistently producing 100% results in SSLC and state top ranks. In 2015, the management of CORDIAL has extended its service to education in the form of a 5 Acre lush green campus in the outskirts of Madhugiri (Harihararoppa) in the name CORDIAL INTERNATIONAL SCHOOL (CBSE).</p>
                 </div>
     
                 <div class="pt-8  lg:max-w-lg">
                   <p class="text-gray-900 text-md font-medium">From the academic year 2019-20 CIS is launching IIT-OLYMPIAD FOUNDATION for Classes VIII to X and an integrated PU College in Academic Alliance with EDU-SHRINE.</p>
                 </div>
     
                 <div class="pt-8  lg:max-w-lg">
                   <p class="text-gray-900 text-md font-medium">CORDIAL and EDUSHRINE aim to transform this Residential campus into a centre for Excellence.</p>
                 </div>
     
         </div>
         </div>
       </div>
     </section>
     
     
     <div className='hidden sm:block'>
     <section class="relative flex grid-cols-3 px-4 2xl:py-14 pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
       <div class="container list-disc w-1/3 px-4 mx-auto">
       <h2 class="mt-8 pb-6 text-3xl font-extrabold font-heading text-white">Courses</h2>
       <ul className='ml-4 list-disc'>
                 <ul className='mb-6 text-md lg:text-lg font-bold text-white list-disc '>
                   <li className='mb-4'>Class-I to X <span className='text-red-300'>: CBSE</span> </li>
                   <li className='mb-4'> PU Science  <span className='text-red-300'>: PCMB & PCMC</span></li>
                 <li className='mb-4'> PU Commerce  <span className='text-red-300'>: CEBA</span></li>
                 </ul>
               </ul>
       </div>
     
      <div class="w-full md:w-1/2  pb-12 ">
             <div class="md:max-w-lg ml-16">
             
                 <div class="mb-10   bg-gradient-cyan rounded-md">
                   <div class="p-4 bg-red-700 rounded-md">
                     <div class="flex flex-wrap justify-between -m-3">
                       <div class="w-auto p-3">
                         <div class="flex flex-wrap items-center -m-3">
                           <div class="w-auto p-3">
                             <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                           </div>
                           <div class="w-auto p-3">
                             <h3 class="mb-4 font-heading font-semibold text-white text-xl pr-10">Contact <span className='text-white'>Coordinator:</span> </h3>
                             <p class="text-white font-normal pr-10">Harihara Roppa Road, Tumkur, Madhugiri-572132, Karnataka</p>
                           
                           </div>
                           <div class="w-auto p-3">
                             <p class="text-white font-normal">7090772507/9741214476</p>
                           
                           </div>
                         </div>
                       </div>
                      
                     </div>
                   </div>
                 </div>
              
                 <div class="mb-10  p-px bg-gradient-cyan rounded-md">
                   <div class="p-4 bg-red-700 rounded-md">
                     <div class="flex flex-wrap justify-between -m-3">
                       <div class="w-auto p-3">
                         <div class="flex flex-wrap items-center -m-3">
                           <div class="w-auto p-3">
                             <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                           </div>
                           <div class="w-auto p-3">
                             <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                             <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandralayout, Bengaluru-560072</p>
                           
                           </div>
                           <div class="w-auto p-3">
                             <p class="text-white text-base">080-28485416 / 7090772512</p>
                           
                           </div>
                         </div>
                       </div>
                      
                     </div>
                   </div>
                 </div>
             
             </div>
           </div>
     </section>
     </div>

     <div className='block sm:hidden'>
     <section class="relative py-4 px-2  sm:px-4 sm:py-14 sm:pt-12 bg-gradient-to-b from-gray-900 via-gray-900 to-red-800">
       <div class="container list-disc sm:px-4  px-6">
       <h2 class="mt-8 pb-6 text-3xl font-extrabold font-heading text-white">Courses</h2>
       <ul className='ml-4 list-disc'>
                 <ul className='mb-6 text-md lg:text-lg font-bold text-white list-disc '>
                   <li className='mb-4'>Class-I to X <span className='text-red-300'>: CBSE</span> </li>
                   <li className='mb-4'> PU Science  <span className='text-red-300'>: PCMB & PCMC</span></li>
                 <li className='mb-4'> PU Commerce  <span className='text-red-300'>: CEBA</span></li>
                 </ul>
               </ul>
       </div>
     
      <div class="w-full  pb-8 ">
             <div>
             
                 <div class="mb-8 mx-4   bg-gradient-cyan rounded-md">
                   <div class="p-4 bg-red-700 rounded-md">
                     <div class="flex flex-wrap justify-between -m-3">
                       <div class="w-auto p-3">
                         <div class="flex flex-wrap items-center -m-3">
                           <div class="w-auto p-3">
                             <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                           </div>
                           <div class="w-auto p-3">
                             <h3 class="mb-4 font-heading font-semibold text-white text-xl pr-10">Contact <span className='text-white'>Coordinator:</span> </h3>
                             <p class="text-white font-normal pr-10">Harihara Roppa Road, Tumkur, Madhugiri-572132, Karnataka</p>
                           
                           </div>
                           <div class="w-auto p-3">
                             <p class="text-white font-normal">7090772507/9741214476</p>
                           
                           </div>
                         </div>
                       </div>
                      
                     </div>
                   </div>
                 </div>
              
                 <div class="mb-8 mx-4  p-px bg-gradient-cyan rounded-md">
                   <div class="p-4 bg-red-700 rounded-md">
                     <div class="flex flex-wrap justify-between -m-3">
                       <div class="w-auto p-3">
                         <div class="flex flex-wrap items-center -m-3">
                           <div class="w-auto p-3">
                             <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                           </div>
                           <div class="w-auto p-3">
                             <h3 class="mb-1 font-heading font-medium text-white text-xl">Admin. Office:</h3>
                             <p class="text-white text-base">No.33, 4th Cross, Maruthi Nagar, Chandralayout, Bengaluru-560072</p>
                           
                           </div>
                           <div class="w-auto p-3">
                             <p class="text-white text-base">080-28485416 / 7090772512</p>
                           
                           </div>
                         </div>
                       </div>
                      
                     </div>
                   </div>
                 </div>
             
             </div>
           </div>
     </section>
     </div>
     
         </div>

    </div>




    
  )
}
