import React from 'react'
import Course from '../images/medicoout.png'
import Course1 from '../images/technoout.png'
import Course2 from '../images/course3.png'
import Cboard from '../images/cboard.png'
import Cabonline from '../images/cabonline.png'
import Cneet from '../images/cneet.png'
import Cneeto from '../images/cneeto.png'



export default function courses() {
  return (
    <div>
        <section class="relative px-4 pb-8 pt-6 sm:pt-16 sm:pb-12 sm:px-12 overflow-hidden bg-red-50" style={{fontFamily:'Manrope'}}>
  <div class="container sm:mx-auto sm:px-4 mx-2 sm:mb-12 mb-8 pr-12 sm:pr-0">
    <h2 class=" mb-2 sm:max-w-xl sm:mx-auto font-heading font-bold text-center text-5xl sm:text-5xl text-red-600">Courses</h2>
   
   
    <div class="sm:flex justify-center">
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/medico">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full  transition ease-out duration-500" src={Course} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>NEET Two-Year</span> Classroom Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline" href="/medico">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/techno">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Course1} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>JEE Two-Year</span> Classroom Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/ntseonline">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Course2} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>NTSE</span> Online Training</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      

    </div>


    <div class="sm:flex justify-center">
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/ntse">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full  transition ease-out duration-500" src={Course} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>NTSE</span> Classroom Training</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/crash">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Course1} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'> NEET-CET</span> Post Board Crash Course - Classroom</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/onlineneetandcet">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Course2} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>NEET-CET</span> Post Board Crash Course - Online</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      

    </div>



    <div class="sm:flex justify-center">
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/aboardonline">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full  transition ease-out duration-500" src={Cabonline} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>Aboard Online</span> Class-X SSLC/CBSE/ICSE Online Live Class Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/aboard">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Cboard} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>Aboard</span> Class-X SSLC/CBSE/ICSE Classroom Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/neet">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Cneet} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-2xl text-center"><span className=' text-red-600'>REPEAT the NEET </span> One-Year Residential Classroom Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      

    </div>



    <div class="sm:flex justify-center">
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/neetonline">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full  transition ease-out duration-500" src={Cneeto} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-3xl text-center"><span className=' text-red-600'>REPEAT the NEET Online </span> One Year Live & Self-Paced Online Learning</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
    
      <div class="w-full sm:w-2/4 p-8   m-4 bg-gradient-to-br from-gray-900 via-gray-900 to-red-600 py-16 sm:py-20 rounded-xl">
        <a class="group" href="/contact">
        <p class="sm:mb-5 mb-4 text-white font-extrabold sm:text-5xl text-4xl text-left">For more Information on <span className=' text-red-500'>courses </span> </p>

          
          <button class=" py-2  text-center text-xl font-extrabold text-red-600 bg-red-50 rounded-2xl  px-6">Contact Us</button>
        </a>
      </div>
      

    </div>



  </div>








</section>
    </div>




  )
}

