import React from 'react'
import Stories from '../homepage/stories'

import Fig from '../images/fig.png'
import image5 from '../images/Suresh1.png'
import image6 from '../images/Krishnamurthy.png'
import image13 from '../images/Ashica1.png'
import image9 from '../images/Manjunath.png'
import Book1 from '../images/book1.png'
import Vid from '../images/vid.webp'
import Silver11 from '../images/Silver11.png'
import Gold1 from '../images/Gold1.png'
import Bronze from '../images/Bronze.png'

export default function Kcet() {


  return (
    <div>
<div className='bg-white' style={{fontFamily:'Manrope'}}>
       <div className='object-cover bg-cover w-full md:w-full flex justify-center items-cente' style={{backgroundImage:`url(${Fig})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}></div>
</div>
{/* ------------------------------------------------------------------------------------------ */}
<section class="hidden md:block pt-20 md:pt-10   bg-gradient-to-br from-gray-900 via-gray-900 to-red-600 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto mb-20 xs:mb-32">
    <div class="text-center mb-24 md:mb-12">
    <h1 class="text-5xl sm:text-7xl md:text-6xl font-heading font-semibold text-white">CONQUER KCET <span></span>Video Course</h1>

      <div class="inline-flex items-center mb-8">
        <span class="md:mt-5 block w-2 h-2 mr-2 rounded-full bg-blue-500"></span>
        <span class=" md:mt-5 text-xl text-gray-200 font-medium">Commencement – Soon after II PUC Board Exam Every year</span>
      </div>
      <div class="text-center"><a class="inline-block py-2 px-6 leading-loose rounded-l-xl rounded-t-xl bg-red-800 hover:bg-red-700 text-gray-50 font-bold tansition duration-200" href="/contact">Enroll Now</a></div>

    </div>
    
    <div class="flex flex-wrap items-center xl:items-start -mx-4">
      <div class="w-full lg:w-1/3 xl:w-1/4 px-4 mb-20 lg:mb-0">
        <div class="max-w-sm mx-auto">
          <div class="flex mb-24">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8"> 50 Hours of Self-Paced Learning Videos</h4>
              {/* <p class="text-gray-300">The house by the pond cras ornare</p> */}
            </div>
          </div>
          <div class="flex">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Elaborate detailing of concepts around the questions </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/3 xl:w-2/4 px-4 mb-20 lg:mb-0">
        <div>
          <img class="w-full max-w-md xl:max-w-lg mx-auto" src={Vid} alt=""/>
        </div>
      </div>
      <div class="w-full lg:w-1/3 xl:w-1/4 px-4">
        <div class="max-w-sm mx-auto">
          <div class="flex mb-24">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Rational Teaching by the Dynamic Team Leads of EduShrine </h4>
            </div>
          </div>
          <div class="flex">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8"> 700+ Authentic & Original KCET Questions Discussed</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 




</section>
{/* ----------------------------------------------------------------------------- */}
<section class="block md:hidden pt-20 md:pt-20 bg-gray-900 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto mb-20 xs:mb-32">
    <div class="text-center mb-24 md:mb-32">
    <h1 class="text-5xl sm:text-7xl md:text-6xl font-heading font-semibold text-white"> CONQUER KCET Video Course</h1>

      <div class="inline-flex items-center mb-8">
        <span class="md:mt-5 block w-2 h-2 mr-2 rounded-full bg-blue-500"></span>
        <span class=" md:mt-5 text-xl text-gray-200 font-medium">Commencement – Soon after II PUC Board Exam Every year</span>
      </div>
      <div class="text-center"><a class="inline-block py-2 px-6 leading-loose rounded-l-xl rounded-t-xl bg-red-800 hover:bg-red-700 text-gray-50 font-bold tansition duration-200" href="/contact">Enroll Now</a></div>

    </div>
    <div class="w-full lg:w-1/3 xl:w-2/4 px-4 mb-20 lg:mb-0">
        <div>
          <img class="w-full max-w-md xl:max-w-lg mx-auto" src={Vid} alt=""/>
        </div>
      </div>
    <div class="flex flex-wrap items-center xl:items-start -mx-4">
      <div class="w-full lg:w-1/3 xl:w-1/4 px-4 mb-20 lg:mb-0">
        <div class="max-w-sm mx-auto">
          <div class="flex mb-5 md:mb-24">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8"> 50 Hours of Self-Paced Learning Videos</h4>
              {/* <p class="text-gray-300">The house by the pond cras ornare</p> */}
            </div>
          </div>
          <div class="flex ">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold md:mb-8">Elaborate detailing of concepts around the questions </h4>
            </div>
          </div>
        </div>
      </div>
     
      <div class="w-full lg:w-1/3 xl:w-1/4 px-4">
        <div class="max-w-sm mx-auto">
          <div class="flex mb-5 md:mb-24">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Rational Teaching by the Dynamic Team Leads of EduShrine </h4>
            </div>
          </div>
          <div class="flex">
            <span class="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full"></span>
            <div>
              <h4 class="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8"> 700+ Authentic & Original KCET Questions Discussed</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

{/* ------------------------------------------------------------------------------ */}
<section class="py-10 md:py-20 bg-white" style={{fontFamily:'Manrope'}}>
<div class="container px-4 mx-auto">
<div class="text-center mb-16">
      <h1 class="font-heading text-3xl md:text-4xl text-gray-900 font-bold">Team Leads of <span class="text-red-600">EduShrine</span></h1>
    </div>
<div class="flex flex-wrap -mx-4 mb-8">
        <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <div class="flex flex-wrap -mx-4 -mb-8">
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image5} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Suresh Kumar</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Mathematics</h2>
                    {/* <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">MSc</h2> */}
                  </div>
                </div>
              </a>
            </div>
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image6} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Krishnamurthy</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Physics</h2>
                    {/* <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">MSc B.Ed</h2> */}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 px-4">
        <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
          <div class="flex flex-wrap -mx-4 -mb-8">
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image13} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Ashica V</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Biology</h2>
                    {/* <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">MSc</h2> */}
                  </div>
                </div>
              </a>
            </div>
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image9} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Manjunatha K C</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Electronics</h2>
                    {/* <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">MSc</h2> */}
                  </div>
                </div>
              </a>
            </div>
           
          </div>
        </div>
        </div>
      </div>
</div>
  </section>


{/* ------------------------------------------------------------------------------- */}


{/* -------------------------------prize (mobile)-------------------------------------------- */}
{/* <section class=" block md:hidden pb-5 md:pb-16  md:pt-20 bg-red-50 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap items-center -mx-4">
      <div class="md:flex md:justify-between">
      <div class="w-full lg:w-2/5 px-4 mb-20 lg:mb-0">
        <div class="lg:max-w-lg">
          <h2 class="mt-8 mb-8 text-3xl 2xl:text-4xl font-bold font-heading text-gray-800">August’ 20 & AUGUST’ 22 (Best of Two)</h2>
          <h2 class="mt-8 mb-5 text-3xl 2xl:text-4xl font-bold font-heading text-gray-800"><span class="text-red-500">One-Lakh </span> Worth Cash Scholarships</h2>

          <p class="text-2xl text-gray-800"><i>"50 Consolation Prizes"</i></p>
        </div>
        <button class=" mt-5 md:mt-6 md:ml-20 group relative font-heading py-4 px-9 block w-full md:w-60 text-base text-white font-medium bg-red-600 overflow-hidden rounded-lg">
                  <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full transition ease-in-out duration-500 bg-gradient-cyan"></div>
                  <p class="relative z-10">Enroll Now</p>
                </button>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-10">
        <div class="xl:relative xl:top-16 text-center bg-red-50 transform hover:-translate-y-3 rounded-10 shadow-7xl transition ease-out duration-1000">
          <img class="mb-3 mx-auto w-60" src={Gold1} alt=""/>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-2xl">25,000</h3>
          <p class="text-lg text-red-600 font-bold">1st Prize</p>
        </div>
      </div>
    <div class="flex flex-wrap max-w-6xl mx-auto -m-7">
      <div class="w-full md:w-1/2 xl:w-1/3 p-10">
        <div class="text-center bg-red-50 transform hover:-translate-y-3 rounded-10 shadow-7xl transition ease-out duration-1000">
          <img class="mb-3 mx-auto w-60" src={Silver11} alt=""/>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-2xl">15,000</h3>
          <p class="text-lg text-red-600 font-bold">2nd Prize</p>
        </div>
      </div>
      
      <div class="w-full md:w-1/2 xl:w-1/3 p-10">
        <div class="text-center bg-red-50 transform hover:-translate-y-3 rounded-10 shadow-7xl transition ease-out duration-1000">
          <img class="mb-3 mx-auto w-60" src={Bronze} alt=""/>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-2xl">10,000</h3>
          <p class="text-lg text-red-600 font-bold">3rd Prize</p>
        </div>
      </div>
    </div>
    </div>
        </div>
  </div>
</section> */}
{/* ----------------------------------------------------------------------------------------- */}
<section class="bg-red-50 2xl:py-16" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="max-w-6xl mx-auto ">
      <div class="max-w-xl mb-14 lg:mb-28 ml-5 md:ml-[25%] ">
        <h2 class="mt-8 text-4xl sm:text-5xl text-red-600 font-bold font-heading ">Glimpses <span class="text-gray-800">of the Course</span></h2>
      </div>
    <div className='hidden sm:block'>
      <div className=' flex grid-cols-3  gap-7'> 
    
        <div class=" w-full sm:w-1/2 p-2">
        <iframe width="560" height="312" src="https://www.youtube.com/embed/uIAynUU-fpI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="w-full sm:w-1/2 p-2">
        <iframe width="560" height="312" src="https://www.youtube.com/embed/ogkfEU8G35E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        
      </div>
      </div>

      <div className='block sm:hidden pl-2'> 
    
    <div class=" w-full sm:w-1/2 p-2">
    <iframe width="320" height="200" src="https://www.youtube.com/embed/uIAynUU-fpI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="w-full sm:w-1/2 p-2">
    <iframe width="320" height="200" src="https://www.youtube.com/embed/ogkfEU8G35E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    
  </div>

      <div class="sm:mt-10  text-center">
        <a class="inline-block px-6 py-8 sm:py-4 sm:px-12 text-gray-800 font-bold text-xl underline" href="/kcetvideos">View all videos</a>
      </div>
    </div>
  </div>
</section>

 {/* <section class="hidden sm:block pb-5 md:pb-16  md:pt-20 bg-gray-900 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap items-center -mx-4">
      <div class="md:flex md:justify-between">
      <div class="w-full lg:w-2/5 px-4 mb-20 lg:mb-0">
        <div class="lg:max-w-lg">
          <h2 class="mt-8 mb-8 text-3xl 2xl:text-4xl px-10 font-bold font-heading text-white">August’ 20 & AUGUST’ 22 (Best of Two)</h2>
          <h2 class="mt-8 mb-5 text-3xl 2xl:text-4xl px-10 font-bold font-heading text-white"><span class="text-red-500">One-Lakh </span> Worth Cash Scholarships</h2>

          <p class="text-2xl text-white px-10"><i>"50 Consolation Prizes"</i></p>
        </div>
        <button class=" mt-5 md:mt-6 sm:ml-12  group relative font-heading py-4 px-9 block w-full md:w-60 text-base text-white font-medium bg-red-600 overflow-hidden rounded-lg">
                  <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full transition ease-in-out duration-500 bg-gradient-cyan"></div>
                  <p class="relative z-10">Enroll Now</p>
                </button>
      </div>
      
    <div class="flex flex-wrap max-w-6xl mx-auto -m-7">
      <div class="w-full md:w-1/2 xl:w-1/3 p-10">
        <div class="text-center bg-red-50 transform hover:-translate-y-3 rounded-10 shadow-7xl transition ease-out duration-1000">
          <img class="mb-3 mx-auto w-60" src={Silver11} alt=""/>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-2xl">15,000</h3>
          <p class="text-lg text-red-600 font-bold">2nd Prize</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-10">
        <div class="xl:relative xl:top-16 text-center bg-red-50 transform hover:-translate-y-3 rounded-10 shadow-7xl transition ease-out duration-1000">
          <img class="mb-3 mx-auto w-60" src={Gold1} alt=""/>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-2xl">25,000</h3>
          <p class="text-lg text-red-600 font-bold">1st Prize</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-10">
        <div class="text-center bg-red-50 transform hover:-translate-y-3 rounded-10 shadow-7xl transition ease-out duration-1000">
          <img class="mb-3 mx-auto w-60" src={Bronze} alt=""/>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-2xl">10,000</h3>
          <p class="text-lg text-red-600 font-bold">3rd Prize</p>
        </div>
      </div>
    </div>
    </div>
        </div>
  </div>
</section> */}

{/* --------------------------------------------------------------------------------------- */}
<Stories/>
{/* ------------------------------------------------------------------------------------------------ */}
<section class="bg-gradient-to-b from-black to-red-700 2xl:py-16" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="max-w-6xl mx-auto">
      <div class="max-w-xl mb-14 lg:mb-28">
        <span class="text-lg text-black font-semibold">Testimonials</span>
        <h2 class="mt-8 text-5xl sm:text-5xl text-red-50 font-bold font-heading">Our Video  <span className='text-red-600'>Testimonials</span></h2>
      </div>
    <div className='hidden sm:block'>
      <div className=' flex grid-cols-3  gap-7'> 
    
        <div class=" w-full sm:w-1/2 p-2">
        <iframe width="560" height="312" src="https://www.youtube.com/embed/icjN8PI6xAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="w-full sm:w-1/2 p-2">
        <iframe width="560" height="312" src="https://www.youtube.com/embed/pN2c_MJKpZ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        
      </div>
      </div>

      <div className='block sm:hidden pl-2'> 
    
    <div class=" w-full sm:w-1/2 p-2">
    <iframe width="320" height="200" src="https://www.youtube.com/embed/icjN8PI6xAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="w-full sm:w-1/2 p-2">
    <iframe width="320" height="200" src="https://www.youtube.com/embed/pN2c_MJKpZ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    
  </div>

      <div class="sm:mt-20 text-center">
        <a class="inline-block px-6 py-8 sm:py-4 sm:px-12 text-red-50 font-bold text-xl underline" href="/testimonialvideos">View all video testimonials</a>
      </div>
    </div>
  </div>
</section>
{/* -------------------------------------------------------------------------- */}
<section class="py-10 md:py-10 bg-gray-50 overflow-hidden" style={{fontFamily:'manrope'}}>
  <div class="container mx-auto px-4 md:px-40">
    <div class="bg-white overflow-hidden rounded-10 shadow-5xl">
      <div class="flex flex-wrap items-center -m-6">
        <div class="w-full md:w-1/2">
          <img class=" hidden md:block relative" src={Book1} alt=""/>
        </div>
        <div class="w-full md:w-1/2 p-12">
          <div class="md:max-w-md">
            <h2 class="mb-2.5 font-heading font-semibold text-3xl sm:text-4xl text-gray-800">Book a physical or virtual appointment with the counselor</h2>
            {/* <p class="mb-9 text-gray-700">Enroll for Free Bridge Course Classes</p> */}
            {/* <p class="text-sm text-gray-900 mb-3">Email</p> */}
            <div class="flex flex-wrap">
              <div class="w-full md:w-auto p-1.5">
                <button class="group relative font-heading py-4 px-9 block w-full md:w-auto text-base text-white font-medium bg-red-600 overflow-hidden rounded-lg">
                  <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full transition ease-in-out duration-500 bg-gradient-cyan"></div>
                  <p class="relative z-10">Enroll Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
