import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { fetchAllblog, blogSelector} from '../../api/blog'
import {Link} from 'react-router-dom'


export default function Index() {

    const dispatch = useDispatch()
    const {all_blog} = useSelector(blogSelector) 


    console.log({all_blog});

    
  useEffect(()=>{
    dispatch(fetchAllblog())
  }, [dispatch])



    return (
        <div className='bg-white'>
<section class="pt-5 md:pt:20 pb-16 md:pb-32 px:4 md:px-24 bg-white overflow-hidden">
  <div class="container mx-auto px-4">
    <div class="md:max-w-lg mx-auto text-center mb-10">
      <h2 class="mb-4 font-heading font-bold text-black text-4xl sm:text-5xl">Latest from <span className='text-red-600'>our blog</span> </h2>
    </div>
    
  </div>

{/* ----------------------------------------------------- */}
<div className='hidden sm:block'>
<div class=" max-w-screen-xl mx-auto grid grid-cols-3 gap-7  md:mt-20 "  style={{fontFamily:'Manrope'}} >
        {
            all_blog.map((item,i)=>{
                return <Link to={`/blog/${item?._id}`} className=' h-84 w-full p-2  mx-auto rounded bg-slate-50 shadow-md'>
                            
                            <div>
                            <img class="transform hover:scale-105 transition ease-in-out rounded-lg duration-1000" src={item?.image} alt=""/>

                            <p class="mr-8 mt-3 text-xl text-gray-900 inline-block font-bold " aria-current="page">{item?.title}</p>
                            <div>
                            </div>
                            </div>
                      </Link>
            })
        }
    </div>
    </div>


    <div className='block sm:hidden'>
<div class=" max-w-full mx-6 gap-8  md:mt-20 "  style={{fontFamily:'Lato'}} >
        {
            all_blog.map((item,i)=>{
                return <Link to={`/blog/${item?._id}`} className=' h-84 w-full p-2  mx-auto rounded bg-slate-50 shadow-md'>
                            
                            <div>
                            <img class="transform hover:scale-105 transition ease-in-out rounded-lg duration-1000" src={item?.image} alt=""/>

                            <p class="mr-8 mt-3 text-xl text-gray-900 inline-block font-bold " aria-current="page">{item?.title}</p>
                            <div>
                            </div>
                            </div>
                      </Link>
            })
        }
    </div>
    </div>

        </section> 
</div>
)
}

