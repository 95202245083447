import React from 'react'
import Edu1 from '../images/edu1.png'
import Edu2 from '../images/edu2.png'
import Edu3 from '../images/edu3.png'
import Edu4 from '../images/edu4.png'
import Edu5 from '../images/edu5.png'
import Edu6 from '../images/edu6.png'
import Edu7 from '../images/edu7.png'


export default function gallery() {
  return (
    <div>
       <section class="py-14 bg-white">
  <div class="container px-4 mx-auto">
    <div class="max-w-md lg:max-w-6xl mx-auto">
      <div class="flex flex-wrap items-center justify-center -mx-4 mb-16">
        <div class="w-full sm:w-auto px-4 mb-8 sm:mb-0">
          <h1 class="font-heading text-4xl sm:text-5xl font-black text-red-600 flex justify-center"> EduShrine <span class= "text-gray-800"> Gallery </span></h1>
        </div>
       
      </div>
      <div class="flex flex-wrap -mx-4 -mb-8">
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu1} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">EduShrine Premier League (EPL) Talent Hunt Event_Rajajinagar, BENGALURU</span>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu2} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">EduShrine Director Mr. KIRAN SIDDE addressing the parents of RR nagar at Vagdevi Tutorials venue</span>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu3} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">EduShrine Premier League (EPL) Talent Hunt Event_Yelahanka, BENGALURU</span>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu4} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">EduShrine Team Building activity</span>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu5} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">EduShrine Team Outing</span>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu6} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">EduShrine Premier League (EPL) Talent Hunt Event_Vijayanagar, BENGALURU</span>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-1/3 px-4 mb-8">
          <a class="relative group block" href="#">
            <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
            <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0  rounded-md overflow-hidden transition duration-500">
              <img class="img-fluid w-full h-112 object-cover" src={Edu7} alt=""/>
              <div class="absolute bottom-0 left-0 pt-3 pb-4 w-full text-center bg-red-600 transition duration-500 ease-out">
                <span class="text-sm text-white font-black">News Coverage on the Class-X Exam Guidance seminar by EduShrine Director KIRAN SIDDE as BEOs special invitee</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
