import React from 'react'
import Header from './header'
import Footer from './footer'
import StudentHeader from '../student/studentheader'


// import Navbar from './navbar'
import { useSelector } from 'react-redux'

import { authenticateSelector } from '../../api/authSlice'
import Navbar from './navbar'

export default function Appwrap({children}) {
  const {user,isAuthenticate} = useSelector(authenticateSelector)

  // console.log({user});

  return (
    <div>
    {/* <Navbar/> */}
        {/* {user?.role === 'parent' ?<Header/>: user?.role === 'student'? */}
        { isAuthenticate ?
        <>
        <div className='flex justify-start w-full'>
          <StudentHeader/>
            <div className='w-full md:w-[80vw]'>
            <Navbar/>

               {children }
            </div>
        </div>
        </>
        :
        <><Header/>
        {children }
        <Footer/></>
        }
       
    </div>
  )
}
