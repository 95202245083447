import React from 'react'
import Google from '../images/google.png'

export default function reviews() {
  return (
    <div>
          <div class="mb-1 md:mb-2 sm:py-10 py-8 max-w-full mx-auto text-center bg-red-50" style={{fontFamily:'Manrope'}}>
        <h2 class="mb-6 text-4xl lg:text-5xl font-bold font-heading text-black ">Google <span className='text-red-600 font-bold'>Reviews</span></h2>
       
      </div>
        <section class="py-2 sm:py-2 bg-gradient-to-br from-gray-900 via-gray-900 to-red-600 " style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">

    <div class="  sm:pb-24 px-4 md:px-8 lg:px-20  rounded-3xl">
      
      <div>
        <div class="flex flex-wrap justify-center -mx-3 mb-14 md:mb-28">
          <div class="flex-shrink-0 w-full lg:w-1/3 px-3 mb-3 lg:mb-0 ">
            <div class="pt-3 pb-4 md:pb-1 px-4 md:px-8 ">
              
            </div>
            <div class="p-8 bg-white shadow-lg rounded-3xl overflow-hidden">
            <div class="flex items-center mb-3 ">
                <img class="mr-6 w-10 h-10" src={Google} alt=""/>
                <h4 class="mr-auto text-xl font-heading font-bold text-red-600">Harsha R</h4>
              </div>
              
            <p class="mb-6 text-sm text-gray-800 leading-loose">" If im being brutally honest in this review , then this institution is the perfect place for students who want to be the best in whatever aspiration they pursue, in the field of science . The teachers truly are the best not because of any glorified statements but because all of them truly love their respective subjects . This was my opinion , as a student, about the institution ."</p>
            <div class="flex mb-2 items-center">
                <span class="mr-6 text-xl font-heading font-bold text-red-600">5.0</span>
              <div class="flex">
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block text-gray-200" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                </div>
            </div>
            </div>
            </div>
          
            <div class="flex-shrink-0 w-full lg:w-1/3 px-3 mb-3 lg:mb-0 ">
            <div class="pt-3 pb-4 md:pb-1 px-4 md:px-8 ">
              
            </div>
            <div class="p-8 bg-white shadow-lg rounded-3xl overflow-hidden">
            <div class="flex items-center mb-3 ">
                <img class="mr-6 w-10 h-10" src={Google} alt=""/>
                <h4 class="mr-auto text-xl font-heading font-bold text-red-600">NAVEEN BALAJI</h4>
              </div>
              
            <p class="mb-6 text-sm text-gray-800 leading-loose">" EDUSHRINE is an excellent coaching center for all Engineering and Medical aspirants. Teachers are passionate and enthusiastic! They give us exposure to various examinations and help students personally and teachers clear our doubts even at mid nights. Apart from teaching, teachers also motivate us. In conclusion, edushrine kept their words! ."</p>
            <div class="flex mb-2 items-center">
                <span class="mr-6 text-xl font-heading font-bold text-red-600">5.0</span>
              <div class="flex">
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block text-gray-200" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                </div>
            </div>
            </div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-1/3 px-3 mb-3 lg:mb-0 ">
            <div class="pt-3 pb-4 md:pb-1 px-4 md:px-8 ">
              
            </div>
            <div class="p-8 bg-white shadow-lg rounded-3xl overflow-hidden">
            <div class="flex items-center mb-3 ">
                <img class="mr-6 w-10 h-10" src={Google} alt=""/>
                <h4 class="mr-auto text-xl font-heading font-bold text-red-600">dance Cp</h4>
              </div>
              
            <p class="mb-6 text-sm text-gray-800 leading-loose">" Edushrine is a wonderful platform for engineering and medical aspirents, the teachers are very enthusiastic and they help us even in midnight .  They help us to excell both in boards and competitive exams, they not only help us with lessons also to boost are confidence.Environment is very friendly. Feel very lucky to be a part of this journey towards excellence. "</p>
            <div class="flex mb-2 items-center">
                <span class="mr-6 text-xl font-heading font-bold text-red-600">4.0</span>
                <div class="flex">
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block text-gray-200" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="currentColor"></path>
                    </svg>
                  </a>
                </div>
            </div>
            </div>
            </div>

            <div class="flex-shrink-0 w-full lg:w-1/3 px-3 mb-3 lg:mb-0 ">
            <div class="pt-3 pb-4 md:pb-1 px-4 md:px-8 ">
              
            </div>
            <div class="p-8 bg-white shadow-lg rounded-3xl overflow-hidden">
            <div class="flex items-center mb-3 ">
                <img class="mr-6 w-10 h-10" src={Google} alt=""/>
                <h4 class="mr-auto text-xl font-heading font-bold text-red-600">Aakanksha Bharadwaj</h4>
              </div>
              
            <p class="mb-6 text-sm text-gray-800 leading-loose">"Being a student of edushrine I'm privileged to be a part of it. It's a good team of professional lecturers and coordinators who are very approachable and cooperative. The study material including both board and entrance syllabus is outstanding . Regular tests and exams are most helpful .Overall a good organisation for a bright future. "</p>
            <div class="flex mb-2 items-center">
                <span class="mr-6 text-xl font-heading font-bold text-red-600">5.0</span>
              <div class="flex">
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block text-gray-200" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                </div>
            </div>
            </div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-1/3 px-3 mb-3 lg:mb-0 ">
            <div class="pt-3 pb-4 md:pb-1 px-4 md:px-8 ">
              
            </div>
            <div class="p-8 bg-white shadow-lg rounded-3xl overflow-hidden">
            <div class="flex items-center mb-3 ">
                <img class="mr-6 w-10 h-10" src={Google} alt=""/>
                <h4 class="mr-auto text-xl font-heading font-bold text-red-600">Jagdeesha M.J</h4>
              </div>
              
            <p class="mb-6 text-sm text-gray-800 leading-loose">" Edushrine  is a wonderful platform to raise your future . Logical teaching and  intellectual faculty  help students to crack the entrances . Their approach to the students is marvellous.They motivate their students to achieve  qualitative and logical education . If you want your child to achieve greater things in their life then edushrine is the  best option"</p>
            <div class="flex mb-2 items-center">
                <span class="mr-6 text-xl font-heading font-bold text-red-600">5.0</span>
              <div class="flex">
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block text-gray-200" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                </div>
            </div>
            </div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-1/3 px-3 mb-3 lg:mb-0 ">
            <div class="pt-3 pb-4 md:pb-1 px-4 md:px-8 ">
              
            </div>
            <div class="p-8 bg-white shadow-lg rounded-3xl overflow-hidden">
            <div class="flex items-center mb-3 ">
                <img class="mr-6 w-10 h-10" src={Google} alt=""/>
                <h4 class="mr-auto text-xl font-heading font-bold text-red-600">Jeevika L</h4>
              </div>
              
            <p class="mb-6 text-sm text-gray-800 leading-loose">" An excellent coaching program with extraordinary teaching where teachers support, encourage students and train them not only for board exams but also for competitive exams like NEET, JEE, K-CET, KVPY etc,The best part here is the kind of study materials provided that includes all the concepts and contains questions from various previous year question papers."</p>
            <div class="flex mb-2 items-center">
                <span class="mr-6 text-xl font-heading font-bold text-red-600">5.0</span>
              <div class="flex">
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block mr-1" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                  <a class="inline-block text-gray-200" href="#">
                    <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                    </svg>
                  </a>
                </div>
            </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}


