import React from "react";


import Course from '../images/medicoout.png'
import Course1 from '../images/technoout.png'
import Course2 from '../images/course3.png'



export default function courses() {


  return (
    
    <div>
      <div data-aos="fade-right">
        <section class="relative pt-10 pb-12 sm:pt-24 sm:pb-20 pl-2 px-10 sm:px-12 overflow-hidden bg-red-50" style={{fontFamily:'Manrope'}}>
  <div class="container  sm:mx-auto sm:px-4 sm:mb-12">
    <h2  data-aos="fade-right" class="mb-20 sm:mb-24 max-w-xl mx-auto font-heading font-bold text-center text-6xl sm:text-7xl text-gray-900">Courses</h2>
   
    <div class="sm:flex sm:justify-center">
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/medico">
          <div class="sm:mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full  transition ease-out duration-500" src={Course} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-3xl text-center"><span className=' text-red-600'>NEET Two-Year</span> Classroom Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="/techno">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Course1} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-3xl text-center"><span className=' text-red-600'>JEE Two-Year</span> Classroom Program</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>
      <div class="w-full md:w-1/4 p-8  m-4 bg-white rounded-xl">
        <a class="group" href="#">
          <div class="mb-9 overflow-hidden rounded-2xl">
            <img class="transform hover:scale-110 w-full transition ease-out duration-500" src={Course2} alt=""/>
          </div>
          
          <p class="mb-5 text-black font-extrabold text-3xl text-center"><span className=' text-red-600'>Online </span>Training</p>
          <p class="mb-5 text-center font-extrabold text-red-600 underline">Learn more</p>
        </a>
      </div>

    </div>
    <div class="text-center "><a class="inline-block py-2  px-6 leading-loose rounded-l-xl rounded-t-xl bg-red-700 hover:bg-red-600 text-gray-50 font-bold tansition duration-200" href="/course">View all Courses</a></div>

  </div>
</section>
    </div>
    </div>
  )
}

