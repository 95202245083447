import {React, useState} from 'react'
// import Course from '../images/course1.png'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'



export default function Edushrine() {

  const [isMenuOpen, setIsMenuOpen] = useState('Students');


  
  return (
    <div>
        <section class="  sm:pt-12 sm:pb-32 sm:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       
  {/* <div class="container px-4 mx-auto"> */}
    <div class=" sm:flex grid-cols-2">
    <div class="hidden sm:block w-full md:w-1/3 p-8 ">
      
        {/* <img class="mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src={Course} alt=""/> ;*/}
        <h2 class="mb-6 ml-2 sm:mb-12 sm:ml-4 text-3xl sm:text-4xl font-bold font-heading tracking-px-n leading-tight text-gray-900">The<span className='text-red-600'> Edushrine</span>-Edge</h2>

        <div class="sm:mx-auto sm:w-96 p-10  bg-red-50 hover:bg-red-700 shadow-sm   border-stone-50 opacity-100 transform hover:-translate-y-1 transition ease-out duration-1000 rounded-xl">
               
                  <div class=" lg:w-auto lg:p-2">
                    {/* <img src={Course} alt="" style={{width: '90px', borderRadius: '50%'}}/> */}
                  </div>
                  <div class="w-auto p-2 cursor-pointer "onClick={() => setIsMenuOpen('Students')}  >
                  <p class="mb-1 mt-2 text-lg font-bold text-black " >The Edushrine-Edge to </p>
                  <p class="mb-1  text-4xl font-bold text-red-600 hover:text-white underline" > Students</p>
                  </div>

             
              </div>
              <div class=" sm:mx-auto sm:w-96 p-10 sm:mt-10 bg-red-50 hover:bg-red-700 shadow-sm   border-stone-50 opacity-100 transform hover:-translate-y-1 transition ease-out duration-1000 rounded-xl">
          
                  <div class=" lg:w-auto lg:p-2">
                    {/* <img src={Course} alt="" style={{width: '90px', borderRadius: '50%'}}/> */}
                  </div>
                  <div class="w-auto p-2 cursor-pointer" onClick={() => setIsMenuOpen('Parents')} >
                  <p class="mb-1 mt-2 text-lg font-bold text-black" >The Edushrine-Edge to </p>
                  <p class="mb-1  text-4xl font-bold text-red-600 hover:text-white underline" > Parents</p>
                  </div>
              
              </div>
                    </div>

{/* ----------------------------mobile responsiveness--------------- */}
<h2 class="block sm:hidden ml-8 mt-8 sm:mb-12 sm:ml-4 text-3xl sm:text-4xl font-bold font-heading tracking-px-n leading-tight text-gray-900">The<span className='text-red-600'> Edushrine</span>-Edge</h2>

                    <div class="block sm:hidden flex grid-cols-3  md:w-1/3 p-8 ">
        {/* <img class="mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src={Course} alt=""/> ;*/}

        <div class="sm:mx-auto w-2/3 sm:w-96 p-2 hover:bg-red-700 bg-red-50 shadow-sm   border-stone-50 opacity-100 transform hover:-translate-y-1 transition ease-out duration-1000 rounded-xl">
               
                 
                  <div class=" p-2 cursor-pointer "onClick={() => setIsMenuOpen('Students')}  >
                  {/* <p class="mb-1 mt-2 text-lg font-bold text-black" >The Edushrine-Edge to </p> */}
                  <p class="text-center text-lg font-bold text-red-600 hover:text-white underline" > Students</p>
                  </div>

             
              </div>
              <div class=" sm:mx-auto w-2/3 sm:w-96 hover:bg-red-700 p-2 ml-10 sm:mt-10 bg-red-50 shadow-sm   border-stone-50 opacity-100 transform hover:-translate-y-1 transition ease-out duration-1000 rounded-xl">
          
            
                  <div class=" p-2 cursor-pointer" onClick={() => setIsMenuOpen('Parents')} >
                  {/* <p class="mb-1 mt-2 text-lg font-bold text-black" >The Edushrine-Edge to </p> */}
                  <p class=" text-center text-lg font-bold text-red-600 underline hover:text-white" > Parents</p>
                  </div>
              
              </div>
                    </div>
{/* --------------------------------------------------------------------------------------------- */}

                    {  isMenuOpen=='Students'  &&   

<div className='sm:grid grid-cols-2 text-gray-900' >
    
   
      <div class="w-full px-6 sm:px-12 sm:mt-28 " id='Students'>
        
        <div class="md:max-w-lg">
          <div class="flex flex-wrap -m-5 mb-2">
            <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Highest importance to conceptual teaching</h3>
                  </div>
                </div>
              </div>
            </div>
         
       
          </div>
          <div class="flex flex-wrap -m-5 mb-2">
            <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Encouragement for Rationalistic approach towards the subjects</h3>
                  </div>
                </div>
              </div>
            </div>
       </div>
          
       <div class="flex flex-wrap -m-5 mb-2">
            <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Exclusive EduShrine Practice 'n' Learn (EPL) study material with multiple levels of practice for each concept</h3>
                  </div>
                </div>
              </div>
            </div>
       </div>
       
       <div class="flex flex-wrap -m-5 mb-2">
            <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Bridge course for building the Aptitude and Attitude needed to handle the courses</h3>
                  </div>
                </div>
              </div>
            </div>
       </div>
       </div>
      </div>



      <div class="w-full px-6 sm:px-14  sm:mt-28">
        
      <div class="md:max-w-lg"> 
      



      <div class="flex flex-wrap -m-5 mb-2">


      <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Well-Planned, meticulous schedule to guide you effectively through the academic yea</h3>
                  </div>
                </div>
              </div>
            </div>

      <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">6</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Dynamic planning that customizes regularly to students' Rate of Progress</h3>
                  </div>
                </div>
              </div>
            </div>

      <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">7</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Parental guidance by mentors and teachers</h3>
                  </div>
                </div>
              </div>
            </div>

      <div class="w-full p-5">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                   
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">8</span>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-lg">
                    <h3 class="mb-3 text-md font-semibold leading-normal">Conduct of Workshops for Stress-Free Learning</h3>
                  </div>
                </div>
              </div>
            </div>

            </div>
         </div>

      </div>



        </div>


                    }









{  isMenuOpen == 'Parents'  &&   
         
          
         <div className='sm:grid grid-cols-2 text-gray-900' >
             
            
             <div class="w-full px-6 sm:px-12 sm:mt-28 " id='Parents'>
               
               <div class="md:max-w-lg">
                 <div class="flex flex-wrap -m-5 mb-2">
                   <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">Corporate Quality Coaching with an affordable Fee structure</h3>
                         </div>
                       </div>
                     </div>
                   </div>
                
              
                 </div>
                 <div class="flex flex-wrap -m-5 mb-2">
                   <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">End-to-End responsibility of the child with no hidden costs and additional expenditures</h3>
                         </div>
                       </div>
                     </div>
                   </div>
              </div>
                 
              <div class="flex flex-wrap -m-5 mb-2">
                   <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">A second parent in the form of Mentors to closely monitor the child's progress</h3>
                         </div>
                       </div>
                     </div>
                   </div>
              </div>
              
              <div class="flex flex-wrap -m-5 mb-2">
                   <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">Continuous communication in the form of SMS, What's App groups, Phone calls, ERP App etc</h3>
                         </div>
                       </div>
                     </div>
                   </div>
              </div>
              </div>
             </div>
         
             <div class="w-full px-6 sm:px-14 sm:mt-28">
               
             <div class="md:max-w-lg"> 
             
         
         
         
             <div class="flex flex-wrap -m-5 mb-2">
         
         
             <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">Hierarchy of admin and management team to listen and implement your suggestion</h3>
                         </div>
                       </div>
                     </div>
                   </div>
         
             <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">6</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">Psychological guidance and support for students to handle the Academic Pressure</h3>
                         </div>
                       </div>
                     </div>
                   </div>
         
             <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">7</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">Career Guidance and support post completion of the course</h3>
                         </div>
                       </div>
                     </div>
                   </div>
         
             <div class="w-full p-5">
                     <div class="flex flex-wrap -m-4">
                       <div class="w-auto p-4">
                         <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                          
                           <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">8</span>
                         </div>
                       </div>
                       <div class="flex-1 p-4">
                         <div class="md:max-w-lg">
                           <h3 class="mb-3 text-md font-semibold leading-normal">Relationship-Responsibility-Accountability</h3>
                         </div>
                       </div>
                     </div>
                   </div>
                   </div>
                </div>       
             </div>
               </div>
                  }
     
    </div>
</section>
    </div>
  )
}


