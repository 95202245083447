import {React, useState} from 'react'
import { useSelector } from 'react-redux'
import { authenticateSelector } from '../../api/authSlice'
// import Course from '../images/course1.png'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'


import PTE from '../../newfiles/PTEResult';
import GTE from '../../newfiles/GTEResult'
import PTB from '../../newfiles/PTBResult'
import GTB from '../../newfiles/GTBResult'




export default function ResultPage() {

  const [isMenuOpen, setIsMenuOpen] = useState('PTE');
  console.log(isMenuOpen)

  const {user} = useSelector(authenticateSelector)

  
  return (

<div className='bg-white'>
<section class="py-4 px-20 bg-white">
  <div class="flex flex-wrap items-center">
     <div class="w-full lg:w-auto flex justify-center mb-4 lg:mb-0">
       <h2 class="text-4xl font-bold text-red-600">Results</h2>
    </div>
  </div>
</section>
<section class="relative pt-5 pb-10 md:pb-10 overflow-hidden" >
  <div class="container mx-auto">
    <div class="flex flex-wrap max-w-7xl mx-auto -m-7">
    <div class="w-full md:w-1/2 xl:w-1/4 p-2 md:p-7">



        <div class="text-center p-5 bg-blue-700 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('PTE')}>PTE</h3>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/4 p-2 md:p-7">



        <div class="text-center p-5 bg-purple-400 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('PTB')}>PTB</h3>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/4 p-2 md:p-7">


        <div class="text-center p-5 bg-amber-400 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
       </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('GTE')}>GTE</h3>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/4 p-2 md:p-7">


        <div class="text-center p-5 bg-orange-600 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
       </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg" onClick={()=>setIsMenuOpen('GTB')} >GTB</h3>
        </div>
      </div>
    </div>
  </div>
  </section>

{/* <div className='ml-3 w-[100%]'>
<button className="btn w-60 m-2 sm:text-4xl font-bold" onClick={()=>setIsMenuOpen('PTE')}>PTE</button>
<button className="btn w-60 m-2" onClick={()=>setIsMenuOpen('PTB')}>PTB</button>
<button className="btn w-60 m-2" onClick={()=>setIsMenuOpen('GTE')}>GTE</button>
<button className="btn w-60 m-2" onClick={()=>setIsMenuOpen('GTB')} >GTB</button>
</div> */}
<div>
  {
    isMenuOpen==='PTE'?
    (<div><PTE/></div>):(<></>)
  }
  {
    isMenuOpen==='PTB'?
    (<div><PTB/></div>):(<></>)
  }
  {
    isMenuOpen==='GTE'?
    (<div><GTE/></div>):(<></>)
  }
  {
    isMenuOpen==='GTB'?
    (<div><GTB/></div>):(<></>)
  }
</div>
</div>

  )
}
 

 
