import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_results:[],
    current_results:null,
    loading:false,
    hasError:false,
}

export const resultsSlice = createSlice({

  name: 'results',
  initialState,

  reducers: {
    getresults: state => {
      state.loading = true;
    },

    getAll_results_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_results = payload
    },

    get_results_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_results = payload
  
  },

  },
})


export const { getresults ,getAll_results_success,getCurrentSuccess, get_results_Failure } = resultsSlice.actions;

export const resultsSelector = state => state.results;



export const fetchAllresults = (id) => async dispatch => {
  dispatch(getresults())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/result`)
   console.log({data});
   dispatch(getAll_results_success(data));

  } catch (error) {
 dispatch(get_results_Failure())
  }
 };



 export const fetchOneResults = (id) => async dispatch => {

  dispatch(getresults())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/result/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_results_Failure())
  }
 };



export default resultsSlice.reducer;