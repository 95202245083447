import React,{useLayoutEffect,useState,useEffect} from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import Edushrine from '../images/edulogo.png'
import {Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {authenticateSelector,logOut} from '../../api/authSlice'
import {LogoutOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import {json, useParams,useNavigate} from 'react-router-dom'
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

export default function Header() {

  const dispatch = useDispatch()
  const {user,isAuthenticate} = useSelector(authenticateSelector)
  console.log({isAuthenticate})
  console.log({user})

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropabout, setDropabout] = useState(false)
  const [dropevent, setDropevent] = useState(false)
  const [dropmedia, setDropmedia] = useState(false)

  const {id} = useParams()
    useEffect( ()=>{
      id && setIsMenuOpen(false)
   },[id])

   useLayoutEffect(() => {
    window.scrollTo(0, 0)
},[]);

  return (
    <div className='sticky top-0  z-50'>
      <section>
  <div class="relative text-center text-sm bg-red-800">
    <div class="px-6 md:px-12 py-1">
    
      <div class="relative flex lg:justify-end">
        <div class="flex w-full lg:w-auto h-14 lg:h-auto justify-between items-center py-4 md:py-0">
         

<button
  aria-label="Open Menu"
  title="Open Menu"
  class="p-2 -mr-1 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
  onClick={() => setIsMenuOpen(true)}
>
<svg class=" block sm:hidden w-5 text-white" viewBox="0 0 24 24">
  <path
  fill="currentColor"
  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
  />
  <path
  fill="currentColor"
  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
  />
  <path
  fill="currentColor"
  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
  />
  </svg>
  </button>

          {/* <GiHamburgerMenu className= 'block sm:hidden text-white h-8 w-8' href="/"/> */}

          <div className='flex items-center justify-between z-20 md:hidden px-2'>

<div class="lg:hidden fixed top-0 left-0">

{  isMenuOpen  && <SidebarWrap
key="first"
    initial={{ x: -250, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{  x: -280, opacity: 1 }}
    transition={{ duration: 0.36, type:'tween', ease:"easeInOut" }}
>

<div class="absolute  w-full ">



<div className="container  lg:w-4/5 block  " style={{fontFamily:'Manrope'}}>
<div>
<div className="flex gap-0 ">

<li class="dropdown inline ml-4  text-start  cursor-pointer    ">

<div class="dropdown-menu absolute hidden h-auto mt-3  z-50   ">
<ul class="block w-full bg-[#FFFF] shadow px-6 py-4 text-gray-900 rounded">
</ul>
</div>
</li>

<Link
to="/profile"
className="flex py-6 text-gray-900  text-[20px] font-semibold rounded-lg shrink-0 "
>      
<Link to="/">
<img src={Edushrine} alt="" className=" w-28 md:hidden" />
</Link>
</Link>     
</div>       
</div>       
</div>

<hr/>


<MenuWrap>                              
<nav>
<ul class="space-y-2   text-left   text-[14px] text-gray-800" style={{fontFamily:'Manrope'}}>
  <li className="nav-item pl-2 font-bold "  >
  <NavLink activeClassName="selected" exact  to="/" onClick={()=>setIsMenuOpen(false)}  >Home </NavLink>
  </li>

  <li className="nav-item pl-2" >
<div>
<li><Link id="droptoggle" to='#'  style={{backgroundColor:dropevent ? '#f5f5f5' : '#fff' , color: dropevent ? 'var(--brandColor)' : 'text-teal-900' }}  onClick={()=>setDropevent(!dropevent)} exact ><b>About Us</b>{dropevent ? <MdKeyboardArrowUp/> :<MdKeyboardArrowDown />}</Link>
{dropevent && <div className="dropdown_menu">
<ul>
<li><Link to='aboutus' onClick={()=>setIsMenuOpen(false)}  >About</Link></li>
<li><Link to='faculty' onClick={()=>setIsMenuOpen(false)} >Faculty</Link></li>
<li><Link to='gallery' onClick={()=>setIsMenuOpen(false)} >Gallery</Link></li>
<li><Link to='testimonialvideos' onClick={()=>setIsMenuOpen(false)}>Testimonial Videos</Link></li>
<li><Link to='reviews' onClick={()=>setIsMenuOpen(false)}>Google Reviews</Link></li>
</ul>
</div>}     
</li>
</div>
</li> 


        
<li className="nav-item pl-2" >
<div>
<li><Link id="droptoggle" to='course'  style={{backgroundColor:dropabout ? '#f5f5f5' : '#fff' , color: dropabout ? 'var(--brandColor)' : 'text-teal-900' }}  onClick={()=>setDropabout(!dropabout)} exact ><b>Courses </b>{dropabout ? <MdKeyboardArrowUp/> :<MdKeyboardArrowDown />}</Link>
{dropabout && <div className="dropdown_menu">
<ul>
<li><Link to='medico' onClick={()=>setIsMenuOpen(false)}  >MEDICO</Link></li>
<li><Link to='techno' onClick={()=>setIsMenuOpen(false)}  >TECHNO</Link></li>
<li><Link to='neet' onClick={()=>setIsMenuOpen(false)}  >Repeat the NEET</Link></li>
<li><Link to='neetonline' onClick={()=>setIsMenuOpen(false)}  >Repeat the NEET Online</Link></li>
<li><Link to='aboard' onClick={()=>setIsMenuOpen(false)}  >ABOARD</Link></li>
<li><Link to='aboardonline' onClick={()=>setIsMenuOpen(false)}  >ABOARD Online</Link></li>
<li><Link to='ntse' onClick={()=>setIsMenuOpen(false)}  >NTSE Class Room Training</Link></li>
<li><Link to='ntseonline' onClick={()=>setIsMenuOpen(false)}  >NTSE Online Training</Link></li>
<li><Link to='onlineneetandcet' onClick={()=>setIsMenuOpen(false)}  >Online NEET and CET Crash Course</Link></li>
<li><Link to='crash' onClick={()=>setIsMenuOpen(false)}  >NEET and CET Crash Course</Link></li>
<li><Link to='kcet' onClick={()=>setIsMenuOpen(false)}  >CONQUER KCET-2021</Link></li>

</ul>
</div>}     
</li>
</div>
</li> 

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="academiccenters" onClick={()=>setIsMenuOpen(false)} >Academic Centers</NavLink>
  </li> 

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="news" onClick={()=>setIsMenuOpen(false)}  >News</NavLink>
  </li> 

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="blogs" onClick={()=>setIsMenuOpen(false)}  >Blogs</NavLink>
  </li> 

  
  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="Successstories" onClick={()=>setIsMenuOpen(false)}  >Success Stories</NavLink>
  </li> 


  <li className="nav-item pl-2 font-bold" >
  <NavLink to="contact" onClick={()=>setIsMenuOpen(false)} activeClassName="selected">Contact</NavLink>
  </li> 
    
</ul>
</nav>                            
</MenuWrap> 

</div>
</SidebarWrap>          
}               

    <div>
    <AnimatePresence exitBeforeEnter  >
    { (isMenuOpen) && <BackdropWrap
    initial={{  opacity: 0 }}
    animate={{  opacity: 1 }}
    exit={{  opacity: 0 }}
    transition={{ duration: 0.36, type:'tween', ease:"easeInOut" }}
    onClick={() => setIsMenuOpen(false)}/>}
      </AnimatePresence>
        </div>
</div>

{/* <Link to="/">
<img src={''} alt="logo" className=" w-28 md:hidden" />
</Link> */}

</div>


          <a class="flex items-center ml-26 text-white hover:text-darkBlueGray-100" href="#">
          {

(!isAuthenticate)?
<>
<Link to="/student-login" className="flex p-3  text-[#ffff]  text-md rounded-lg  shrink-0 ">      
         <span>Student Login </span>
</Link>

<Link to="/parent-login" className="flex p-3 text-[#ffff]  text-md rounded-lg  shrink-0 ">      
         <span>Parent Login</span>
</Link></>:

<Link href='/' className="flex p-3 text-[#ffff]  text-md rounded-lg  shrink-0 " onClick={()=>{dispatch(logOut()) }}>{user?.student_name}
</Link>

}
            <svg class="" width="8" height="5" viewbox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z" fill="currentColor"></path>
            </svg>
          </a>
        </div>
        <p class="hidden sm:block lg:absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 font-medium text-white leading-10">
          <span>Take psychometric test today & get scholarships.</span>
          <span class="text-blue-300"> Visit Today</span>
        </p>
      </div>
    </div>
  </div>
 
{/* --------------------------------------------------laptop-------------------------------------------------------- */}
  
  <div class="bg-white ">
    <div class="px-6 md:px-6 py-3">
      <nav class="flex justify-between">
        <div class="flex w-full items-center">
          <a class="inline-block" href="/">
            <img class="h-14" src={Edushrine} alt=""/>
          </a>
          <ul class="hidden  md:flex px-4 ml-20 2xl:ml-40">
            <li class="mr-10 mt-1"><a class="font-semibold text-sm text-black hover:text-red-800" href="/">Home</a></li>

 <div className="dropdown dropdown-hover flex mr-5">
            <a class="flex items-center font-semibold text-black hover:text-red-800 text-sm" href="/">
                <span class="mr-2 mt-1 ">AboutUs</span>

                <svg width="8" height="5" viewbox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z" fill="currentColor"></path>
                </svg>
              </a>

              <div class=" divide-x-2 grid grid-cols-3 dropdown-content menu absolute  top-6 p-2 pt-2 shadow bg-white text-gray-900 font-semibold rounded-box w-[190px]">

    <div tabIndex={0} className=" ">
 
  <Wrapper>

  <li class="rounded-sm relative">
  <button class="w-full text-left  flex items-center outline-none focus:outline-none bg-white">
  <Link to='/aboutus' class=" flex-1 text-gray-900 hover:text-red-700 hover:bg-gray-200 text-sm ">About</Link>

        <span class="mr-auto">
        </span>
      </button>
      </li>


      <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/faculty' class="pr-1 flex-1 text-gray-900 hover:text-red-700 hover:bg-gray-200 text-sm">Faculty</Link>

        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/gallery' class="pr-1 flex-1 text-gray-900 hover:text-red-700 hover:bg-gray-200 text-sm">Gallery</Link>

        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-44 text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/testimonialvideos' class="pr-1 flex-1 text-gray-900 hover:text-red-700 hover:bg-gray-200 text-sm">Testimonial Videos</Link>

        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-44 text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/reviews' class="pr-1 flex-1 text-gray-900 hover:text-red-700 hover:bg-gray-200 text-sm">Google Reviews</Link>

        <span class="mr-auto">
        </span>
      </button>
        </li>

    

        </Wrapper>
  </div> 
  </div>
  </div>


           
            <div className="dropdown dropdown-hover flex mr-5">
            <a class="flex items-center font-semibold text-black hover:text-red-800" href="/course">
                <span class="mr-2 text-sm mt-1">Courses</span>
                <svg width="8" height="5" viewbox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z" fill="currentColor"></path>
                </svg>
              </a>

              <div class=" divide-x-2 grid grid-cols-3 dropdown-content menu absolute  top-6 p-2 pt-5 shadow bg-white text-gray-900 font-semibold rounded-box w-[550px]">
    <div tabIndex={0} className=" ">
 
  <Wrapper>

  <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/medico' class=" flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">MEDICO</Link>
        <span class="mr-auto">
        </span>
      </button>
      </li>


      <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/techno' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">TECHNO</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/neet' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">Repeat The NEET</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/neetonline' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">Repeat The NEET Online</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>
        </Wrapper>
  </div> 

  <div tabIndex={0} className="">
  <Wrapper>
        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/aboard' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">ABOARD High School</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/aboardonline' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">ABOARD ONLINE</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>
    
        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/ntse' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">NTSE Class Room Training</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/ntseonline' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">NTSE Online Training</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>
        </Wrapper>
  </div> 

  <div tabIndex={0} className="">
  <Wrapper>
        <li class="rounded-sm relative">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/onlineneetandcet' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">Online NEET and CET Crash Course</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative ">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/crash' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">NEET and CET Crash Course</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>

        <li class="rounded-sm relative ">
  <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
  <Link to='/kcet' class="pr-1 flex-1 text-gray-900 hover:text-green-700 hover:bg-gray-200 text-sm">CONQUER KCET-2021</Link>
        <span class="mr-auto">
        </span>
      </button>
        </li>
</Wrapper>
  </div>
</div>
</div>
<li className='mr-10 mt-1'><a class="font-semibold text-black hover:text-red-800 text-sm" href="/academiccenters">Academic Centers</a></li>
<li className='mr-10 mt-1'><a class="font-semibold text-black hover:text-red-800 text-sm" href="/news">News</a></li>

{/* <li className='mr-10 mt-1'><a class="font-semibold text-black hover:text-red-800 text-sm" href="/margadarshi">Margadarshi</a></li> */}

            <li className='mr-10 mt-1'><a class="font-semibold text-black hover:text-red-800 text-sm" href="/blogs">Blogs</a></li>
            {/* <li className='mr-16'><a class="font-medium text-black hover:text-red-800" href="/">About Us</a></li> */}

                       <li className='mr-10 mt-1'><a class="font-semibold text-black hover:text-red-800 text-sm" href="/Successstories">Success Stories</a></li>


  <li className='mr-4 '><a class="font-semibold text-black hover:text-red-800 text-sm" href="/contact">Contact</a></li>
            
          </ul>
         
        <a href='/contact'>  <button class="hidden xl:inline-block py-4 px-10 ml-28 text-lg text-white font-heading font-medium tracking-tighter text-center bg-red-800 focus:ring-2 focus:ring-red-900 focus:ring-opacity-50  rounded-xl">Enquire Now </button></a>

        </div>
        <button class="navbar-burger self-center text-white xl:hidden">
          <svg width="25" height="16" viewbox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="25" height="2" fill="currentColor"></rect><rect y="14" width="25" height="2" fill="currentColor"></rect></svg>
        </button>
      </nav>
    </div>
  </div>  
</section>
    </div>
)
}


const Wrapper = styled.section`

li>ul                 { transform: translatex(100%) scale(0) }
li:hover>ul           { transform: translatex(101%) scale(1) }
li > button svg       { transform: rotate(-90deg) }
li:hover > button svg { transform: rotate(-270deg) }

.group:hover .group-hover\:scale-100 { transform: scale(1) }
.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
.scale-0 { transform: scale(0) }
.min-w-32 { min-width: 8rem }
`; 


const BackdropWrap = styled(motion.div)`

position: fixed;
top:0;
left:0;
width:100%;
height: 200vh;
background-color: #0a0a0a05;

`


const SidebarWrap = styled(motion.aside)`

position: fixed;
top:0;
left:0;
width:75%;
height: 100vh;
background-color: #ffffff;
z-index: 50;
`


const MenuWrap = styled.div`


.dropdown_menu{
text-align:left;
/* height: 167px ; */
max-height:  167px;
overflow-y: auto;
overflow-x: hidden;
background-color: #f5f5f5;

svg {
font-size: 1rem;
margin-right: 0.2rem;
}

} 


#subdroptoggle{
background-color: white;
font-size: 0.9rem;
font-weight: 600;
&:hover{

background-color:#88448A;
color: white;

}
}

ul{
padding-bottom:0.7px;
background-color: #f5f5f5;
li {
a{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0.5rem 0.5rem 0.5rem 0.5rem;
margin-bottom: 0.1rem;
transition:0.3s;

&:hover{

background-color: #ba1818;
color: white;

  }
}

list-style: none;
background-color: white;
/* color:; */
cursor: pointer;

svg {

  font-size: 1.3rem;
}


.selected{  
background-color: #88448A;
color: white;
width: 100%;
display: block;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0.5rem 0.75rem 0.5rem 0.9rem;
margin-bottom: 0.3rem;
}
}
}
`


