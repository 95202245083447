import React from 'react'
import CountUp, {useCountUp } from 'react-countup'

export default function delivers() {
  return (
    <div>
       <section class="relative py-28 px-10 sm:py-32 sm:px-24 bg-red-50 overflow-hidden"style={{fontFamily:'Manrope'}} >
  <img class="absolute bottom-0 left-0" src="flaro-assets/images/numbers/gradient.svg" alt=""/>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap -m-8">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <h2 class="text-6xl font-bold font-heading tracking-px-n leading-tight text-black">We have <span className='text-red-500'>Delivered</span></h2>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="md:w-56">
           
           <h2 class="mb-3 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight text-white bg-red-700 rounded-xl text-center py-4 "> <CountUp end={14600} duration={3}/></h2>
          <p class="text-lg text-black font-semibold leading-normal">Students tutioned</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="md:w-56">
          <h2 class="mb-3 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight text-white bg-red-700 rounded-xl text-center py-4"><CountUp end={3500} duration={3} delay={2}/></h2>
          <p class="text-lg text-black font-semibold leading-normal">Parents Coached</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="md:w-56">
          <h2 class="mb-3 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight text-white bg-red-700 rounded-xl text-center py-4"><CountUp end={1300} duration={3}  delay={4}/></h2>
          <p class="text-lg text-black font-semibold leading-normal">Teachers trained</p>
        </div>
      </div>
    </div>
  </div>
</section> 
    </div>
  )
}
