
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { authenticateSelector } from '../../api/authSlice'
import moment from 'moment';

export default function Index() {
  const {user} = useSelector(authenticateSelector)

  const [amountPaid,setAmountPaid] = useState(0)
 

  useEffect(()=>{
    // const amount =  (user?.office_details?.installment_details?.installment1?.payment_mode_1!= "") ? +user?.office_details?.installment_details?.installment1?.amount1 : (user?.office_details?.installment_details?.installment2?.payment_mode_2 !="" )? +user?.office_details?.installment_details?.installment2.amount2 :(user?.office_details?.installment_details?.installment3?.payment_mode_3!= "") ? +user?.office_details?.installment_details?.installment3.amount3 :0
    // user?.office_details?.installment_details?.installment2?.installment2_date ? user?.office_details?.installment_details?.installment2.amount2 :user?.office_details?.installment_details?.installment3?.installment3_date ?user?.office_details?.installment_details?.installment3.amount3 :
    
    // calculate the amount already paid 
    // donot erase the above commented line.(need for reference later if needed)

    const amount = user?.office_details?.installment_details?.installment1?.amount1 + user?.office_details?.installment_details?.installment2?.amount2 + user?.office_details?.installment_details?.installment3?.amount3
    setAmountPaid(amount)
   },[user?.office_details])



  console.log({amountPaid})

 
  return (
    <div className='bg-white py-20'>
<section class="relative pt-10 pb-20 md:pb-10 overflow-hidden"  style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap max-w-6xl mx-auto -m-7">
    <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-blue-700 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg"> Total Fees</h3>
          <p class="sm:text-4xl font-bold text-gray-100">{user?.office_details?.total_fee}</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-pink-500 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
        </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg">Amount Paid (till date)</h3>
          <p class="sm:text-4xl font-bold text-gray-100">{amountPaid}</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-7">
        <div class="text-center p-5 bg-orange-500 transform hover:-translate-y-2 rounded-2xl shadow-7xl transition ease-out duration-1000">
        <div class="flex justify-center">
       </div>
          <h3 class="mb-1 font-heading font-bold text-white text-lg">Amount Due</h3>
          <p class="sm:text-4xl font-bold text-gray-100">{(user?.office_details?.total_fee) - amountPaid}</p>
        </div>
      </div>
    </div>
  </div>
  </section>
  {/* ----------------------------------------------------------------------------------------------- */}
  <section class="bg-gray-50 py-8 px-4 hidden sm:block">
  <table class="table-auto w-full bg-white shadow rounded ">
    <thead class="border-b border-gray-100 ">
      <tr>
        <th class="py-6 border-r border-gray-200 text-center ">
          <a class="flex items-center text-red-700 font-bold text-xl w-full " href="#">
            <p className='w-full text-center'>Installment</p>
           
          </a>
        </th>
        <th class=" border-r border-gray-200">
          <a class="flex items-center text-red-700 font-bold text-xl" href="#">
            <p className='w-full text-center'>Amount</p>
            
          </a>
        </th>
        <th class="border-r border-gray-200">
          <a class="flex items-center text-red-700 font-bold text-xl" href="#">
            <p className='w-full text-center'>Paid Date</p>
          
          </a>
        </th>
        <th class="border-r border-gray-200">
          <a class="flex items-center text-red-700 font-bold text-xl" href="#">
            <p className='w-full text-center'>Method</p>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>

        {/* show the installement 1 details row*/}
      <tr class="text-xl bg-blue-50 border text-center">
        <td class="py-6 border-l-0 border border-gray-200">Installment 1</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment1?.amount1}</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment1?.payment_mode_1!="" ? moment(user?.office_details?.installment_details?.installment1?.installment1_date).format("DD-MM-YYYY"): "Not Yet Paid"}</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment1?.payment_mode_1}</td>
      </tr>
       
        {/* show the installement 2 details row*/}
      <tr class="text-xl border text-center">
        <td class="py-6 border-l-0 border border-gray-200">Installment 2</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment2?.amount2}</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment2?.payment_mode_2!="" ? moment(user?.office_details?.installment_details?.installment2?.installment2_date).format("DD-MM-YYYY"): "Not Yet Paid"}</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment2?.payment_mode_2}</td>
      </tr>

        {/* show the installement 3 details row*/}
      <tr class="text-xl bg-blue-50 border text-center">
        <td class="py-6 border-l-0 border border-gray-200">Installment 3</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment3?.amount3}</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment3?.payment_mode_3!="" ? moment(user?.office_details?.installment_details?.installment3?.installment3_date).format("DD-MM-YYYY"): " Not yet Paid"}</td>
        <td class="border border-gray-200">{user?.office_details?.installment_details?.installment3?.payment_mode_3}</td>
      </tr>
     
    </tbody>
  </table>
</section>
    </div>
  )
}

