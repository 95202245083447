import React,{useLayoutEffect,useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import {authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'  
import {logOut} from '../../api/authSlice'
import {IoIosNotificationsOutline} from 'react-icons/io';
import Edushrine from '../images/edulogo.png'
import {NavLink, useLocation } from 'react-router-dom'
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import {json, useParams,useNavigate} from 'react-router-dom'
import {broadcastSelector} from '../../api/broadcast'




// import { cartProductSelector} from '../api/cartSlice'
// import 'antd/dist/antd.css';

const Navbar = () => {
  const dispatch = useDispatch()

  const {isAuthenticate, } = useSelector(authenticateSelector)

  const [dropevent, setDropevent] = useState(false)

  const {user} = useSelector(authenticateSelector)

 

  console.log(user?.teaching_required_for?.subjects)
  
  const [drop, setDrop] = useState(false)

  console.log({isAuthenticate})
  console.log({user})

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropabout, setDropabout] = useState(false)
  const [dropmedia, setDropmedia] = useState(false)
  const [notify,setParentNotify] = useState([])
  const {current_broadcast} = useSelector(broadcastSelector)


  useEffect(()=>{
    user?.notifications?.map((item)=>{
       if(item.notify_on == 'result')
       setParentNotify([...notify,item])
    })

  },[])

  console.log(notify)

  const {id} = useParams()
    useEffect( ()=>{
      id && setIsMenuOpen(false)
   },[id])

   useLayoutEffect(() => {
    window.scrollTo(0, 0)
},[]);


  return (
    <header className='bg-red-800 h-16 sticky top-0  z-50' >
      <div class="relative text-center text-sm bg-red-800 block md:hidden">
    <div class="px-6 md:px-12 py-1">
    
      <div class="relative flex lg:justify-end">
        <div class="flex w-full lg:w-auto h-14 lg:h-auto justify-between items-center py-4 md:py-0">
          

<button
  aria-label="Open Menu"
  title="Open Menu"
  class="p-2 -mr-1 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
  onClick={() => setIsMenuOpen(true)}
>
<svg class="w-5 text-black" viewBox="0 0 24 24">
  <path
  fill="currentColor"
  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
  />
  <path
  fill="currentColor"
  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
  />
  <path
  fill="currentColor"
  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
  />
  </svg>
  </button>

          {/* <GiHamburgerMenu className= 'block sm:hidden text-white h-8 w-8' href="/"/> */}

          <div className='flex items-center justify-between z-20 md:hidden px-2'>

<div class="lg:hidden fixed top-0 left-0">

{  isMenuOpen  && <SidebarWrap
key="first"
    initial={{ x: -250, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{  x: -280, opacity: 1 }}
    transition={{ duration: 0.36, type:'tween', ease:"easeInOut" }}
>

<div class="absolute  w-full ">



<div className="container  lg:w-4/5 block  " style={{fontFamily:'Manrope'}}>
<div>
<div className="flex gap-0 ">

<li class="dropdown inline ml-4  text-start  cursor-pointer    ">

<div class="dropdown-menu absolute hidden h-auto mt-3  z-50   ">
<ul class="block w-full bg-[#FFFF] shadow px-6 py-4 text-gray-900 rounded">
</ul>
</div>
</li>

<Link
to="/profile"
className="flex py-6 text-gray-900  text-[20px] font-semibold rounded-lg shrink-0 "
>      
<Link to="/">
<img src={Edushrine} alt="" className=" w-28 md:hidden" />
</Link>
</Link>     
</div>       
</div>       
</div>

<hr/>


<MenuWrap>                              
<nav>
<ul class="space-y-2   text-left   text-[14px] text-gray-800" style={{fontFamily:'Manrope'}}>
<li className="nav-item pl-2 font-bold " >
  <NavLink activeClassName="selected" exact  to="/dashboard" onClick={()=>setIsMenuOpen(false)} >Dashboard </NavLink>
  
  </li>

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/myprofile" onClick={()=>setIsMenuOpen(false)} >My Profile</NavLink>
  </li> 
        
<li className="nav-item pl-2" >
<div>
<li><Link id="droptoggle" to='course'  style={{backgroundColor:dropabout ? '#f5f5f5' : '#fff' , color: dropabout ? 'var(--brandColor)' : 'text-teal-900' }}  onClick={()=>setDropabout(!dropabout)} exact ><b>Study Material </b>{dropabout ? <MdKeyboardArrowUp/> :<MdKeyboardArrowDown />}</Link>
{dropabout && <div className="dropdown_menu">
<ul>
<li><Link to='/phys' onClick={()=>setIsMenuOpen(false)} >Physics</Link></li>
<li><Link to='/chem' onClick={()=>setIsMenuOpen(false)} >Chemistry</Link></li>
<li><Link to='/maths' onClick={()=>setIsMenuOpen(false)} >Maths</Link></li>
<li><Link to='/bio' onClick={()=>setIsMenuOpen(false)} >Biology</Link></li>


</ul>
</div>}     
</li>
</div>
</li> 


<li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/news" onClick={()=>setIsMenuOpen(false)} >News & Events</NavLink>
  </li> 

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/blogs" onClick={()=>setIsMenuOpen(false)} >Blogs</NavLink>
  </li> 

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/results" onClick={()=>setIsMenuOpen(false)} >Results</NavLink>
  </li> 

  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/fee-details/63c9777b6b016dccd355aa28" onClick={()=>setIsMenuOpen(false)} >Fee Details</NavLink>
  </li> 

 
  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/broadcast" onClick={()=>setIsMenuOpen(false)} >Broadcast <span class="flex items-center justify-center w-6 h-6 bg-red-800 text-white text-xs font-semibold rounded-full">{current_broadcast?.broadcast?.length>0 ? "New" :" "}</span>
</NavLink>
  </li>


  <li className="nav-item pl-2 font-bold" activeClassName="selected">
  <NavLink to="/noty" onClick={()=>setIsMenuOpen(false)} >Notifications<span class="flex items-center justify-center w-6 h-6 bg-red-800 text-white text-xs font-semibold rounded-full">{user?.role == 'parent'?notify.length:user?.notifications?.length}</span>
 </NavLink>

  </li>

  <li className="nav-item pl-2 font-bold" >
  <NavLink to="/"  onClick={()=>{dispatch(logOut())}} activeClassName="selected">Logout</NavLink>
  </li> 
    
</ul>
</nav>                            
</MenuWrap> 

</div>
</SidebarWrap>          
}               

    <div>
    <AnimatePresence exitBeforeEnter  >
    { (isMenuOpen) && <BackdropWrap
    initial={{  opacity: 0 }}
    animate={{  opacity: 1 }}
    exit={{  opacity: 0 }}
    transition={{ duration: 0.36, type:'tween', ease:"easeInOut" }}
    onClick={() => setIsMenuOpen(false)}/>}
      </AnimatePresence>
        </div>
</div>

{/* -------------------------------------Laptop------------------------------------------------------------- */}

<div className="container mx-auto lg:w-11/12 block" style={{fontFamily:'Manrope'}}>

        <div className="flex h-12 sm:justify-end justify-right  ">


        <div class="hidden md:block mt-3 w-full md:w-1/2 lg:w-auto flex py-1 px-4 mb-4 md:mb-0 md:mr-4 md:ml-auto border rounded bg-white">


      <input class="text-sm placeholder-gray-500 bg-white" type="text" placeholder="Type to search..."/>
      <button class="ml-auto">
        <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.0921 16.9083L15.0004 13.8417C16.2005 12.3453 16.7817 10.4461 16.6244 8.53441C16.4672 6.62274 15.5835 4.84398 14.155 3.56386C12.7265 2.28375 10.8619 1.59958 8.94451 1.65205C7.02711 1.70452 5.20268 2.48963 3.84636 3.84594C2.49004 5.20226 1.70493 7.02669 1.65247 8.94409C1.6 10.8615 2.28416 12.7261 3.56428 14.1546C4.84439 15.583 6.62316 16.4668 8.53482 16.624C10.4465 16.7812 12.3457 16.2001 13.8421 15L16.9087 18.0667C16.9862 18.1448 17.0784 18.2068 17.1799 18.2491C17.2815 18.2914 17.3904 18.3132 17.5004 18.3132C17.6104 18.3132 17.7193 18.2914 17.8209 18.2491C17.9224 18.2068 18.0146 18.1448 18.0921 18.0667C18.2423 17.9113 18.3262 17.7036 18.3262 17.4875C18.3262 17.2714 18.2423 17.0637 18.0921 16.9083ZM9.16708 15C8.01335 15 6.88554 14.6579 5.92625 14.0169C4.96696 13.3759 4.21929 12.4649 3.77778 11.399C3.33627 10.3331 3.22075 9.16019 3.44583 8.02863C3.67091 6.89708 4.22648 5.85767 5.04229 5.04187C5.85809 4.22606 6.89749 3.67049 8.02905 3.44541C9.1606 3.22033 10.3335 3.33585 11.3994 3.77736C12.4653 4.21887 13.3763 4.96654 14.0173 5.92583C14.6583 6.88512 15.0004 8.01293 15.0004 9.16666C15.0004 10.7138 14.3858 12.1975 13.2919 13.2914C12.1979 14.3854 10.7142 15 9.16708 15Z" fill="#382CDD"></path>
        </svg>
      </button>
    </div>

        <div > <IoIosNotificationsOutline className='text-[40px] mr-5 mt-3 text-white ' /></div>

        <a class="inline-flex  md:items-center text-gray-800 rounded mt-4" href="#"  onClick={()=>setDropevent(!dropevent)}>


          <div class="relative w-8 h-8 rounded-full mr-4">
            <img className='rounded-full' src={user?.photo} alt=""/>
          </div>
          <span class="hidden md:block font-bold text-white">{user?.student_name}</span>
        
        </a>

            {dropevent && <div className="dropdown_menu">
            <ul class="mt-16 font-bold">
            <li><Link to='/' >Profile</Link></li>
            <>
        {
        (!isAuthenticate)?
        <Link to="/profile" className="flex text-gray-800  text-md rounded-lg  shrink-0 ">      
                 <span>Login</span>
        </Link>:

      <Link href='/' className="flex text-gray-800  text-md rounded-lg  shrink-0 " onClick={()=>{dispatch(logOut()) }}>Logout
    </Link>
        
        }
        </>
      
            </ul>
            </div>}
        
        </div>
        </div>
    </div>
        </div>
      </div>
    </div>
  </div>



    </header>
  )
}

const BackdropWrap = styled(motion.div)`

position: fixed;
top:0;
left:0;
width:100%;
height: 200vh;
background-color: #0a0a0a05;

`


const SidebarWrap = styled(motion.aside)`

position: fixed;
top:0;
left:0;
width:75%;
height: 100vh;
background-color: #ffffff;
z-index: 50;
`


const MenuWrap = styled.div`

.dropdown_menu{
text-align:left;
/* height: 167px ; */
max-height:  167px;
overflow-y: auto;
overflow-x: hidden;
background-color: #f5f5f5;

svg {
font-size: 1rem;
margin-right: 0.2rem;
}

} 


#subdroptoggle{
background-color: white;
font-size: 0.9rem;
font-weight: 600;
&:hover{

background-color:#88448A;
color: white;

}
}

ul{
padding-bottom:0.7px;
background-color: #f5f5f5;
li {
a{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0.5rem 0.5rem 0.5rem 0.5rem;
margin-bottom: 0.1rem;
transition:0.3s;

&:hover{

background-color: #ba1818;
color: white;

  }
}

list-style: none;
background-color: white;
/* color:; */
cursor: pointer;

svg {

  font-size: 1.3rem;
}


.selected{  
background-color: #88448A;
color: white;
width: 100%;
display: block;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0.5rem 0.75rem 0.5rem 0.9rem;
margin-bottom: 0.3rem;
}
}
}
`


export default Navbar