import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_testimonials:[],
    current_testimonials:null,
    loading:false,
    hasError:false,
}

export const testimonialsSlice = createSlice({

  name: 'testimonials',
  initialState,

  reducers: {
    gettestimonials: state => {
      state.loading = true;
    },

    getAll_testimonials_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_testimonials = payload
    },

    get_testimonials_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_testimonials = payload
  
  },

  },
})


export const { gettestimonials ,getAll_testimonials_success,getCurrentSuccess, get_testimonials_Failure } = testimonialsSlice.actions;

export const testimonialsSelector = state => state.testimonials;



export const fetchAlltestimonials = (id) => async dispatch => {
  dispatch(gettestimonials())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/testimonial`)
   console.log({data});
   dispatch(getAll_testimonials_success(data));

  } catch (error) {
 dispatch(get_testimonials_Failure())
  }
 };



 export const fetchOneTestimonials = (id) => async dispatch => {

  dispatch(gettestimonials())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/testimonial/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_testimonials_Failure())
  }
 };



export default testimonialsSlice.reducer;