import React from 'react'
import Board from '../images/board.png'
import Boardm from '../images/boardm.png'
import Ban1 from '../images/ban1.png'
import Img1 from '../images/img1.png'
import Img2 from '../images/img2.png'
import Img3 from '../images/img3.png'
import Img4 from '../images/img4.png'
import Monitor from '../images/monitor.png'
import Study from '../images/study.png'
import Shedule from '../images/shedule.png'
import Overview from '../images/overview.jpg'
import Book1 from '../images/book1.png'
import Edu from '../images/edu.jpg'
import {heading} from '../courses/faqdata'
import { NavLink } from 'react-router-dom'
import Stories from '../homepage/stories'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

console.log(heading)


export default function Aboard() {
  return (
    <div>
<div className='bg-white'>
       <div className='hidden md:block object-cover bg-cover w-full md:w-full justify-center items-center' style={{backgroundImage:`url(${Board})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}></div>
       <div className=' block md:hidden object-cover bg-cover w-full md:w-full justify-center items-center' style={{backgroundImage:`url(${Boardm})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}></div>

</div>
{/* ---------------------------------------------------------------------------------- */}
<section class="py-10 overflow-x-hidden bg-white " style={{fontFamily:'Manrope'}}>
<p class="max-w-6xl mx-auto font-heading font-bold text-center mb-10 px-16 text-3xl md:text-4xl text-gray-800 "> ABOARD - Target Exams<span className='text-red-600'>  & Courses</span></p>

  <div class="container mx-auto px-4">
    <div class="flex flex-wrap justify-center -mx-3 md:px-28">
      <div class="w-full md:w-1/2 lg:w-auto px-3 mb-6 ">
        <div class="h-full p-6 md:p-10 border bg-red-50  transform hover:-translate-y-3 transition ease-out">
          <div class="flex items-center">
            <span class="flex-shrink-0 inline-flex mr-4 md:mr-10 items-center justify-center w-20 h-20 bg-red-300 rounded-full">
              {/* <svg width="37" height="37" viewbox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.9845 11.6702C33.7519 10.3368 32 9.60814 30.0543 9.60814H24.9767V6.75543C24.9767 6.2438 24.5581 5.8252 24.0465 5.8252H0.930233C0.418605 5.8252 0 6.2438 0 6.75543V27.2128C0 27.7244 0.418605 28.143 0.930233 28.143H4.63566C4.93798 29.864 6.43411 31.174 8.24031 31.174C10.0465 31.174 11.5426 29.864 11.845 28.143H24.0465H26.0853C26.3876 29.864 27.8837 31.174 29.6899 31.174C31.4961 31.174 32.9922 29.864 33.2946 28.143H36.0698C36.5814 28.143 37 27.7244 37 27.2128V17.6004C36.9922 15.143 36.3023 13.0888 34.9845 11.6702ZM1.86047 7.68566H23.1163V10.5384V26.2903H11.6822C11.1783 24.8795 9.82171 23.864 8.24031 23.864C6.65892 23.864 5.30233 24.8795 4.79845 26.2903H1.86047V7.68566ZM8.24031 29.3136C7.24806 29.3136 6.44186 28.5074 6.44186 27.5151C6.44186 26.5229 7.24806 25.7167 8.24031 25.7167C9.23256 25.7167 10.0388 26.5229 10.0388 27.5151C10.0388 28.5074 9.23256 29.3136 8.24031 29.3136ZM29.6899 29.3136C28.6977 29.3136 27.8915 28.5074 27.8915 27.5151C27.8915 26.5229 28.6977 25.7167 29.6899 25.7167C30.6822 25.7167 31.4884 26.5229 31.4884 27.5151C31.4884 28.5074 30.6822 29.3136 29.6899 29.3136ZM35.1318 26.2826H33.1318C32.6279 24.8717 31.2713 23.8562 29.6899 23.8562C28.1085 23.8562 26.7519 24.8717 26.2481 26.2826H24.9845V11.4686H30.062C33.1938 11.4686 35.1395 13.8174 35.1395 17.6004V26.2826H35.1318Z" fill="white"></path>
              </svg> */}
                                         <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20" stroke="white" stroke-width="" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </span>
            <div>
              <h3 class=" text-xl font-bold font-heading text-red-700">SSLC - </h3>
              <p class="text-gray-800 mb-4">  Science, Mathematics, Social Studies</p>
              <h3 class=" text-xl font-bold font-heading text-red-700">CBSE - </h3>
              <p class="text-gray-800 mb-4"> Science, Mathematics, Social Studies</p>
              <h3 class=" text-xl font-bold font-heading text-red-700">ICSE  - </h3>
              <p class="text-gray-800 mb-4"> Physics, Chemistry, Biology, Mathematics, Social Studies</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------ */}
<section class="relative py-10 2xl:py-10 overflow-hidden bg-white">
  <img class="hidden md:block absolute top-0 left-0 px-20" src={Ban1} alt=""/>
  <div class="relative container px-4 mx-auto">
    <div>
      <div class="flex flex-wrap -mx-10 md:px-28">
        <div class="relative w-full lg:w-1/2 px-10">
          <img class="lg:hidden absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt=""/>
          <div class="px-10 pt-3 pb-5 rounded-lg">
            <h3 class=" mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Start Date</h3>
            <p class="text-lg text-gray-800 md:text-white mb-2">Every year – First Week of March</p>
            {/* <p class="text-lg text-gray-800 md:text-white">This Year COVID  Delay – Third week of June/First week of July</p> */}
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>

        <div class="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <img class="hidden lg:block absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt=""/>
          <div class="px-10 pt-3 pb-5 rounded-lg">           
            <h3 class=" mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Selection Procedure</h3>
            <p class="text-lg text-gray-800 md:text-white">ESSAT (EduShrine Scholarship cum Admission Test)</p>
          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>

        <div class="w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <div class="px-10 pb-5  rounded-lg">        
            <h3 class="mb-4 text-2xl md:text-3xl font-bold text-gray-800 md:text-white">Eligibility</h3>
            <p class="text-lg text-gray-800 md:text-white">Class-IX from the respective board with minimum of 60% marks</p>

          </div>
          <div class="relative border-t border-black md:border-white">
          </div>
        </div>
        <div class=" ml-32 md:ml-20  text-center"><a class="inline-block py-5 px-12 mr-4 text-xl bg-red-900 hover:bg-orange-600 rounded-lg text-white font-bold transition duration-200" href="/contact">Enroll now</a></div>
      </div>
    </div>
  </div>
</section>
{/* ---------------------------------------------------------------------------------------- */}
<section class="relative pt-10 pb-20 md:pb-14  bg-gradient-to-b from-gray-900 via-gray-900 to-red-800"  style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
  <p class="mb-10  text-center text-3xl md:text-4xl text-white font-bold">Program <span className='text-red-600'>Locations</span></p>
    <div class="flex flex-wrap max-w-6xl mx-auto md:mx-96 -m-7">
      <div class="w-full md:w-1/2 xl:w-1/2 p-7 ">
        <div class=" text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
          <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="60px" height="60px"><path fill="#DE5245" d="M12,44c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h16l11,11v26c0,1.7-1.3,3-3,3H12z"/><path fill="#B3433A" d="M39,15l-10.1-0.9l10.1,10V15z"/><path fill="#EEA6A0" d="M39,15h-8c-1.7,0-3-1.3-3-3V4L39,15z"/><path fill="#fff" d="M24,18c-3.7,0-6.8,3-6.8,6.8c0,4.6,5.2,6.3,6.3,13.4c0.1,0.5,0.1,0.8,0.5,0.8s0.4-0.3,0.5-0.8	c1.1-7.2,6.3-8.8,6.3-13.4C30.8,21,27.7,18,24,18z"/><path fill="#DE5245" d="M24,23c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,23,24,23z"/></svg> </div>
        <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">Nagadevanahalli (Kengeri) – Bengaluru</h3>
          <p class="text-sm text-gray-500">7090772512</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/2 p-7">
        <div class="text-center p-5 bg-red-50 transform hover:-translate-y-3 rounded-2xl shadow-7xl transition ease-out duration-1000">
          <h3 class="mb-2 font-heading font-bold text-gray-900 text-lg">Class Timings</h3>
          <p class="text-lg text-gray-700 mb-2">Morning Batch – 6 am to 8 am</p>
          <p class="text-lg text-gray-700 mb-2">Evening Batch – 5 pm to 7 pm</p>
          <h3 class="mb-1 font-heading font-bold text-gray-900 text-lg">6 Days a week : Mon to Sat</h3>

        </div>
      </div>
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------------- */}
<div className='bg-white'>
<section class="container mx-auto md:px-28 pb-10 md:pb-16 md:py-24 bg-white" style={{fontFamily:'Manrope'}}>
    <div class="flex flex-wrap justify-between -m-6">
      <div class="w-full lg:w-5/12 xl:w-1/2 p-6">
        {/* <p class="mb-20 text-center text-4xl text-gray-800 font-bold">Course <span className='text-red-600'> Highlights</span></p> */}
        <div class="w-full mt-4 lg:w-5/12 xl:w-1/2 p-6 lg:mt-24">
        {/* <p class=" mb-4 text-sm md:text-md font-heading text-gray-800 font-medium ">The course is primarily based on practical work</p> */}
        <p class="mb-12 text-center text-3xl md:text-7xl text-gray-800 font-bold">Course <span className='text-red-600'> Highlights</span></p>
        <div class="flex flex-wrap -m-3 lg:w-96 pl-4 md:mb-20">

      <p className=' mb-10 md:mb-20 text-left text-lg text-gray-800 font-semibold '> Edu-Shrine’s Study Material is the key factor of its Academic Programme. The Study Material by itself generates a System that enables the Management to monitor Faculty and Faculty and Parents to monitor the Students.</p>
      <p class=" text-center text-3xl md:text-3xl text-red-700 font-bold">[" Overall, Intensive Coaching....Stress-Free Learning "]</p>

        </div>
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
           
          </div>
        </div>
        
      </div>
        
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
          </div>
        </div>
      </div>
      <div class="w-auto lg:w-7/12 xl:w-1/2  p-2 md:p-6">
        <div class="max-w-max mx-auto">
          <div class="flex flex-wrap justify-center -m-3 mb-3">
            <div class="w-full p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                   <div class="w-28 p-2">
                    <img src={Img1} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800 ">Meticulous<br/> Schedule </p>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img2} alt=""/>
                  </div>
                  <div class="w-auto p-2">
                    <p class="font-heading font-bold text-gray-800">Exhaustive Study <br/>Content with large<br/> number of Practice<br/> questions as per the<br/> Latest Exam Pattern</p>
                 
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-72 p-3 bg-red-50 md:bg-red-500 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img3} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-black md:text-white">Passion-driven <br/>faculty team </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3 xl:-ml-20">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 md:bg-red-500  transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Img4} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-black md:text-white">Periodic Tests with<br/> immediate feedback,<br/> follow up and<br/> fine tuning</p>
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Shedule} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-5">
                    <p class="font-heading font-bold text-gray-800">Rigorous Monitoring <br/>& Mentoring System </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Monitor} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-3">
                    <p class="font-heading font-bold text-gray-800">Student Counseling<br/> & Mental<br/> Strengthening</p>
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-80 p-3 bg-red-50 md:bg-red-500 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <div class="flex flex-wrap -m-2">
                  <div class="w-28 p-2">
                    <img src={Study} alt=""/>
                  </div>
                  <div class="w-auto p-2 mt-3">
                    <p class="font-heading font-bold text-black md:text-white">Round the clock<br/> availability of<br/> teachers for the <br/>doubt clarification</p>
                   
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
{/* ---------------------------------------------------------------------------------------- */}
<section class="pt-10 pb-24 px-10 md:px-24 bg-red-50 overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
  <p class="mb-20 text-center text-3xl md:text-5xl text-gray-800 font-bold">Why <span className='text-red-600'> EDUShrine?</span></p>

  {/* <h2 class="mb-16 max-w-2xl mx-auto font-heading font-bold text-center text-6xl sm:text-3xl text-red-700">Why EDUShrine?</h2> */}
  
    <div class="flex flex-wrap -m-8 md:px-28">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold  text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teacher's Institution</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-red-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Ethical Coaching Practices</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Student-Centered, Dynamic Academic System</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Perfect balance between Education & Training</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">5</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Concept-Oriented, Self-Sufficient Teaching System</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">6</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Teachers with genuine empathy towards student's growth</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">7</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Cordial Relationship with parents and students</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold text-white font-heading bg-red-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">8</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-400"></div>
          </div>
        </div>
        <h3 class="text-xl font-semibold leading-normal md:max-w-xs text-gray-800">Most Reasonable fee structure for the quality delivered</h3>
      </div>
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------------- */}
<div className='bg-white'>
<h2 class=" ml-20 md:ml-44 pt-8 text-3xl md:text-5xl font-bold font-heading tracking-px-n text-gray-900 "><span className='text-red-600'> Course </span> Overview</h2>
</div>
<section class="pt-10 pb-10 md:px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
       
       <div class="container px-4 mx-auto">
         <div class="md:flex md:grid-cols-2">
         <div class=" md:w-1/2 md:p-8 ">
     
             <img class="hidden md:block md:mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src={Overview} alt=""/> ;
     
                         </div>
           <div class="w-full md:w-1/3 md:px-12 md:mt-40 text-gray-900 ">
             
             <div class="md:max-w-lg">
               <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">10 Months Course - July to April of every year</h3>
                       </div>
                     </div>
                   </div>
                 </div>
              
            
               </div>
               <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-1 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Detailed Theory conceptual teaching from both years' syllabus + Extensive Objective practice</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
               
            <div class="flex flex-wrap -m-5 mb-2 ">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">4.5 Hours (1.5Hr per subject) of Teaching Classes + 6 Hours (2 hours per subject) of Study sessions per day</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
            
            <div class="flex flex-wrap -m-5 mb-2">
                 <div class="w-full p-5">
                   <div class="flex flex-wrap -m-4">
                     <div class="w-auto p-4">
                       <div class="relative mb-3 w-8 h-8 text-md text-white bg-red-600 font-bold border border-gray-200 rounded-full">
                        
                         <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">4</span>
                       </div>
                     </div>
                     <div class="flex-1 p-4">
                       <div class="md:max-w-lg">
                         <h3 class="mb-3 text-md font-semibold leading-normal">Over 35 Topic-Wise & 30 Cumulative Mock Tests per subject</h3>
                       </div>
                     </div>
                   </div>
                 </div>
            </div>
            </div>
           </div>
         </div>
       </div>
     </section>
{/* ------------------------------------------------------------------------------------------------------- */}
<section class="bg-white py-5 md:py-20 md:px-24" style={{fontFamily:'manrope'}}>
  <div class="container mx-auto">
    <div class="relative py-11 md:px-16 rounded-md overflow-hidden">
    <img class="hidden md:block absolute top-0 left-0 px-20 md:h-full" src={Ban1} alt=""/>

      <div class="relative z-20 flex flex-wrap items-center">
        <div class="w-full md:w-1/2">
        <p class=" md:mt-5 mb-10 px-16 text-3xl md:text-5xl text-red-600 md:text-white font-bold">Get EduShrine<br/><span className='text-gray-800 md:text-white'> Advantage</span></p>
        </div>
        <div class="w-full md:w-1/2 p-3">
                    <p class="font-semibold text-xl text-gray-800 md:text-white text-center mb-1">" <i>GET UP TO 100% SCHOLARSHIP BASED ON YOUR NEET FIRST ATTEMPT SCORES</i> "</p>
                              <p class=" mt-5 md:mt-0 font-semibold text-xl px-10  text-gray-800 md:text-white mb-2">Enroll for ESCAT (EduShrine Competency Assessment Test). </p>
                              <p class=" mt-5 md:mt-0 font-semibold text-xl px-10  text-gray-800 md:text-white mb-2">A written test followed by interview that analyzes your current scholastic potential to achieve MBBS seat with an year coaching.</p>
                              <p class=" mt-5 md:mt-0 font-semibold text-xl px-10  text-gray-800 md:text-white">We give genuine feedback on whether time spent on Long-Term NEET coaching is worth enough for you.</p>


          <NavLink to='/contact'>
          <button class=" ml-20 block mt-3 md:ml-60 px-10 py-3 font-semibold text-lg text-white bg-red-600 hover:bg-orange-600 rounded-md">Contact Now</button>
          </NavLink>
        </div>
      </div>
    </div>
  </div>
</section>
{/* -------------------------------------------------------------------------------------------- */}
<Stories/>

{/* -------------------------------------------------------------------------------------------- */}


<div class="p-4 pt-8 md:pt-16 pb-12 md:px-24 bg-white " style={{fontFamily:'manrope'}}>
<p class="mb-10 md:mb-20 text-center text-3xl md:text-5xl text-gray-800 font-bold">Watch Online <span className='text-red-600'> Demo Class</span></p>
  <div class="aspect-w-16 aspect-h-8 rounded-lg md:pl-32 block md:hidden">
      <iframe width="360" height="200"   src="https://www.youtube.com/embed/O5cnEBUvQnI" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      <div class="aspect-w-16 aspect-h-8 rounded-lg md:pl-32 hidden md:block">
      <iframe width="1220" height="520"   src="https://www.youtube.com/embed/O5cnEBUvQnI" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>
{/* -------------------------------------------------------------------------------------------------- */}
<section class="py-10 md:py-10 bg-gray-50 overflow-hidden" style={{fontFamily:'manrope'}}>
  <div class="container mx-auto px-4 md:px-40">
    <div class="bg-white overflow-hidden rounded-10 shadow-5xl">
      <div class="flex flex-wrap items-center -m-6">
        <div class="w-full md:w-1/2">
          <img class=" hidden md:block relative" src={Book1} alt=""/>
        </div>
        <div class="w-full md:w-1/2 p-12">
          <div class="md:max-w-md">
            <h2 class="mb-2.5 font-heading font-semibold text-4xl sm:text-5xl text-gray-800">Book a Free Class</h2>
            <p class="mb-9 text-gray-700">Enroll for Free Bridge Course Classes</p>
            {/* <p class="text-sm text-gray-900 mb-3">Email</p> */}
            <div class="flex flex-wrap">
              <div class="w-full md:w-auto p-1.5">
                <button class="group relative font-heading py-4 px-9 block w-full md:w-auto text-base text-white font-medium bg-red-600 overflow-hidden rounded-lg">
                  <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full transition ease-in-out duration-500 bg-gradient-cyan"></div>
                  <p class="relative z-10">Book Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
