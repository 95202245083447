import React from "react";
import image from "../images/Kirankumar.png";
import image1 from "../images/Pankaj1.png";
import image3 from "../images/Praveenkumar.png";
import image4 from "../images/Vinay.png";
import image5 from "../images/Suresh1.png";
import image6 from "../images/Krishnamurthy.png";
import image7 from "../images/Deeksha.png";
import image9 from "../images/Praveena.png";

import image13 from "../images/Ashica1.png";

import image21 from "../images/Raveesh.png";
import image23 from "../images/Pavan.png";
import image28 from "../images/Loki.png";
import image29 from "../images/Nandini.png";
import image30 from "../images/Deepa Sampat.png";
import image32 from "../images/Pradeeprao.png";
import image33 from "../images/Manjula G.png";
import image34 from "../images/Rajkumar G.png";
import image35 from "../images/Manjunath.png";
import image36 from "../images/Chandrakala M G.png";
import image38 from "../images/Somashekhar L.png";
import image39 from "../images/Ramappa B.png";
import image40 from "../images/Chethan J.png";
import image41 from "../images/Ramdeep R.png";
import image43 from "../images/Boyina Venugopal.png";
import image44 from "../images/Lavanya S.png";
import image45 from "../images/Rakesh P.png";
import image46 from "../images/Umesh Bhat M V.png";
import image47 from "../images/Ravi Kiran A.png";
import image49 from "../images/Bindu V.png";
import image50 from "../images/Lakshminarayana A V.png";
import image54 from "../images/Vinod Kumar.png";
import image56 from "../images/Krishnamurthy D.png";
import image27 from '../images/geetha m.jpeg'
import image57 from '../images/praveen reddy.jpeg'
import image58 from '../images/ms shivanand.jpeg'
import image59 from '../images/madhushree.jpeg'
import image60 from '../images/sushma.jpeg'
import image61 from '../images/umadevi.jpeg'
import image63 from '../images/ronjalin.jpeg'
import image64 from '../images/tejaswini.jpeg'
import image65 from '../images/ravikiran.jpeg'
import image70 from '../images/soumya.jpeg'
import image71 from '../images/sunkra.jpeg'
import image80 from '../images/amalraj.jpeg'
import image81 from '../images/rang.jpeg'
import image83 from '../images/assim.jpg'
import image84 from '../images/girish.jpg'
import image85 from '../images/akshata.jpg'











export default function Faculty() {
  return (
    <div>
      <section class="py-4 bg-white" style={{ fontFamily: "Manrope" }}>
        <div class="container sm:px-4 sm:mx-auto px-4">
          <div class="text-center sm:mb-16 mb-8  ">
            <h1 class="font-heading font-bold text-5xl sm:text-7xl text-black bg-red-50 py-16">
              Our <span className="text-red-600">Faculty</span>
            </h1>
          </div>
          {/* ------------ASC------------------- */}
          <div class="text-center mb-20">
            <h1 class="font-heading font-bold text-5xl text-red-600 underline ">
              ASC
            </h1>
          </div>

          <div class="max-w-6xl mx-auto">
            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image1}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Pankaj Tamang
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Physics
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Head of the Department
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image13}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Ashica V
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Biology
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Head of Department
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
       
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image3}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              R.Praveen Kumar Reddy
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Chemistry
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Head of the Department
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image4}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Vinay Kashyap M
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Chemistry
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
{/* .................................................... */}
            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image7}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                          Deeksha Y
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                          Chemistry
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
               Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image5}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                          Suresh Kumar
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                          Mathematics
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Head of Department
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
       
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image9}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Praveen Kumar P
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Marketing
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                             Zonal Head
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image6}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Krishnamurthy
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Mathematics
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* ...... */}
            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image83}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                         Assim Mohammed Hasan H
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                        Mathematics
                          </h2>
                          {/* <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
               Lecturer
                          </h2> */}
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image84}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                          Girish V
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                       Physics
                          </h2>
                          {/* <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Head of Department
                          </h2> */}
                        </div>
                      </div>
                    </a>
                  </div>
       
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full  object-cover"
                            src={image85}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                           Akshata Umesh Mahalingpur
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Physics
                            </h2>
                         
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            
            {/* --------------------carmel--------- */}

            {/* ------------------------cordial--------------------------------- */}
            <div class="text-center mb-20">
              <h1 class="font-heading font-bold text-5xl text-red-600 underline">
                Cordial
              </h1>
            </div>

            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
           
            

                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image54}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Vinod Kumar C V
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Mathematics
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image23}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Pavan Kumar R
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Computer Science
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image56}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Krishnamurthy D
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Chemistry
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
              
                 
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image21}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Raveesh R S
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Biology
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-72 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image27} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Geetha G.N</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Methematics</h2>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 "></h2>
                  </div>
                </div>
              </a>
            </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image28}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Lokesh N
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Marketing
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Zonal Head
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image29}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Nandini C
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Marketing
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Admission Manager
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full object-cover"
                            src={image57}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                             Praveen Reddy
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Maths Lecturer
                            </h2>
                         
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>             
            </div>


            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-72 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image58} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">    M S Shivanand </h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Biology</h2>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 "></h2>
                  </div>
                </div>
              </a>
            </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image59}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                          Madhushree S
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                          Physics
                          </h2>
                       
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image71}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Sunkara Raja Sekhar
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer Mathematics
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full object-cover"
                            src={image70}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Soumya K M
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer in business studies
                            </h2>
                         
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>             
            </div>
{/* ,.,,,,,,,,,,,,,amal */}
            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-72 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={image80} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1"> Amalraj Panayan </h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Physics</h2>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 "></h2>
                  </div>
                </div>
              </a>
            </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image81}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                         Ranagaraju
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                Lecturer Commerence
                          </h2>
                       
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
                 
            </div>
            {/* --------------------ESA------------------------ */}
            <div class="text-center mb-20">
              <h1 class="font-heading font-bold text-5xl text-red-600 underline">
                ESA
              </h1>
            </div>

            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Kirankumar Sidde
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Management
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Founder Director
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image30}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Deepa Sampathi
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Management
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Director & CFO
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image33}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Manjula G
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            EDP
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Assistant
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image34}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Rajkumar G
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Administration
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Academic Director
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                  
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image32}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Pradeep Rao A
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              EDP
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Manager
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image35}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Manjunatha K C
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Administration
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Programme Manager
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image36}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Chandrakala M G
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Administration
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              AFO & Coordinator
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image38}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Somashekar L
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Administration
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Office Supervisor
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-4 mb-8">
              {/* <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                </div>
              </div> */}

              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image39}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Ramappa B
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Administration
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Logistic Supervisor
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image40}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Chethan J
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Administration
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Office Assistant
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------------------------------------------------Raman------------------------------------- */}

            <div class="text-center mb-20">
              <h1 class="font-heading font-bold text-5xl text-red-600 underline">
                Raman
              </h1>
            </div>

            <div class="flex flex-wrap -mx-4 mb-8">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image41}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Ramdeep R
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Physics
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
              
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image45}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Rakesh P
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Marketing
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Admission Manager
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image43}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Boyina Venugopal
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Mathematics
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image44}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Lavanya S
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Biology
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------------------------------------------------Suman------------------------------------- */}

            <div class="text-center mb-20">
              <h1 class="font-heading font-bold text-5xl text-red-600 underline">
                Suman
              </h1>
            </div>

            <div class="flex flex-wrap -mx-4 mb-8">

            <div class="w-full lg:w-1/2 px-4">
                <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
                  <div class="flex flex-wrap -mx-4 -mb-8">
                   
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image49}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                              Bindu V
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Business Studies
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image60}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                             Sushma M
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer in Chemistry
                            </h2>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                              Lecturer
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image63}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                     Rojalin.S
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                             Lecturer in Physics
                            </h2>
                       
                          </div>
                        </div>
                      </a>
                    </div>


                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image64}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                                 Tejaswini M
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                                 Biology Lecturer
                            </h2>
                       
                          </div>
                        </div>
                      </a>
                    </div>

                    <div class="w-full sm:w-1/2 px-4 mb-8">
                      <a
                        class="group block h-72 relative bg-blueGray-900 rounded-md"
                        href="#"
                      >
                        <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                          <img
                            class="img-fluid w-full h-full object-cover"
                            src={image65}
                            alt=""
                          />
                          <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                            <h3 class="text-red-900 md:text-lg font-bold mb-1">
                                 Ravikiran A
                            </h3>
                            <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                                 Lecturer in Lecturer
                            </h2>
                       
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="flex flex-wrap -mx-4 -mb-8">
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image46}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Umesh Bhat M V
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Physics
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image47}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Ravi Kiran A
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Mathematics
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full h-full object-cover"
                          src={image50}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                            Lakshminarayana A V
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Kannada
                          </h2>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                            Lecturer
                          </h2>
                        </div>
                      </div>
                    </a>
                  </div>
                  
                  <div class="w-full sm:w-1/2 px-4 mb-8">
                    <a
                      class="group block h-72 relative bg-blueGray-900 rounded-md"
                      href="#"
                    >
                      <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                        <img
                          class="img-fluid w-full object-cover"
                          src={image61}
                          alt=""
                        />
                        <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                          <h3 class="text-red-900 md:text-lg font-bold mb-1">
                      Umadevi
                          </h3>
                          <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">
                      Lecturer in Commerence
                          </h2>
                         
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
