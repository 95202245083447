import React from 'react'

export default function kcetvedios() {
  return (
    <div>
        <section>

<div class="py-10 bg-red-50 " style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
    <div class="mb-8 md:mb-16 max-w-lg mx-auto text-center">
      <h3 class="mb-6 text-2xl sm:text-5xl font-bold font-heading text-black">Watch Our <span className='text-red-600'> Videos</span></h3>
    
    </div>

    <div className='hidden sm:block'>
    <div class="flex flex-wrap mb-8  gap-10 justify-center">
    
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/gwq49tamsdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/0Pzi81F_9Lw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/NXV9eMYZw_k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/-Z5IXyvM_zE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/ngilj0EUYPo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="400" height="230" src="https://www.youtube.com/embed/c1Zhc1FEte4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
    </div>
    </div>

{/* ------------------------------------------------------------------------------------------ */}
    <div className='block sm:hidden'>
    <div class=" mb-6 px-2   gap-10 justify-center">
    
     <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/gwq49tamsdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div> <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/0Pzi81F_9Lw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div> <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/NXV9eMYZw_k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div> <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/-Z5IXyvM_zE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div> <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/ngilj0EUYPo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div> <div class="w-auto  md:w-auto lg:w-1/4 mb-2">
        <div class="relative mx-auto h-64 w-full rounded-lg">
        <iframe width="320" height="200" src="https://www.youtube.com/embed/c1Zhc1FEte4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
     </div>
    </div>
    </div>
  </div>
</div>
</section>
    </div>
  )
}
