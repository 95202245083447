import React from 'react'
import { useSelector } from 'react-redux'
import { authenticateSelector } from '../../api/authSlice'
export default function Myprofile() {
  const {user} = useSelector(authenticateSelector)
  return (
    <div className='bg-white'>
<div>
      <section class="py-4 md:pl-24 w-full h-full bg-white">
 
 <h3 class="font-bold text-gray-800 text-2xl text-left ml-5 "> <span> Hello  {user.role == 'student'? user?.student_name :  user?.parents_details?.father_name }</span>, <span className='text-4xl'><br/>Welcome <span class="text-red-600">to Edushrine!</span></span></h3>
  
</section>
</div>
<div className='display:block'>
<section class="relative sm:flex grid-cols-3 2xl:py-14 md:pt-12 ">
  <div class="container list-disc sm:w-1/3 mx-auto px-2">
  
 <section class="bg-coolGray-50 py-4">
  <div class="container mx-auto flex md:flex-wrap ">
    <div class=" p-6 pb-1 border border-coolGray-100 bg-red-50 rounded-md shadow-dashboard">
      <div class="flex flex-wrap items-center justify-between mb-1 -m-2">
        <div class="w-auto p-2 flex grid-cols-3">
        <img class=" sm:w-72 mb-5 sm:h-48 mr-4 self-start flex justify-center" src={user?.photo} alt=""/>
     
          <div className='w-full' >
        <h2 class=" text-2xl sm:text-3xl  mt-10 font-semibold text-red-700">  {user?.student_name} </h2>
        <div>
          <p class=" text-md sm:text-xl text-gray-900 font-medium">{user?.register_no}</p></div>
      </div>
          </div>
       
        </div>
       
      <div class="flex flex-wrap ">
        <div class="w-full border-b border-coolGray-100">
          <div class="flex flex-wrap items-center justify-between py-4 -m-2">
            <div class="w-auto p-2">
              <div class="flex flex-wrap items-center -m-2">
            
                <div class="w-auto p-2">
                  <h3 class="text-lg font-medium text-gray-900"><b>Phone number:</b> {user?.student_numb}</h3>
                </div>
              </div>
            </div>
            <div class="w-auto p-2">
              <p class="text-xs text-gray-900 font-medium"></p>
            </div>
          </div>
        </div>
        <div class="w-full border-b border-coolGray-100">
          <div class="flex flex-wrap items-center justify-between py-4 -m-2">
            <div class="w-auto p-2">
              <div class="flex flex-wrap items-center -m-2">
              
                <div class="w-auto p-2">
                  <h2 class="text-lg font-medium text-gray-900"><b>E-mail:</b> {user?.student_email}</h2>
                </div>
              </div>
            </div>
            <div class="w-auto p-2">
              <p class="text-xs text-gray-900 font-medium"></p>
            </div>
          </div>
        </div>
        <div class="w-full border-b border-coolGray-100">
          <div class="w-full">
          <div class="flex flex-wrap items-center justify-between py-4 -m-2">
            <div class="w-auto p-2">
              <div class="flex flex-wrap items-center -m-2">
              
                <div class="w-auto p-2">
                  <h2 class="text-lg font-medium text-gray-900"><b>Admission no:</b> {user?.admission_no}</h2>
                </div>
              </div>
            </div>
            <div class="w-auto p-2">
              <p class="text-xs text-gray-900 font-medium"></p>
            </div>
          </div>
        </div>
        </div>
        <div class="w-full border-b border-coolGray-100">
          <div class="w-full">
          <div class="flex flex-wrap items-center justify-between py-4 -m-2">
            <div class="w-auto p-2">
              <div class="flex flex-wrap items-center -m-2">
              
                <div class="w-auto p-2">
                  <h2 class="text-lg font-medium text-gray-900"><b>Parent number: </b> {user?.parent_numb}</h2>
                </div>
              </div>
            </div>
       
          </div>
        </div>
        </div>
 
        <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-gray-900"><b>Campus :</b> {user?.campus_address}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-gray-900 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
      </div>
    </div>
   
  </div>
</section>
  </div>
 <div class="w-full md:w-1/2  pb-12 px-2 ">
        <div class="md:max-w-lg w-full md:ml-16">
        
            <div class="mb-10 bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-50 rounded-md">
                <div class="w-full flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-4 font-bold text-red-800 text-2xl">Course Details </h3>
                        <p class="text-gray-800 font-normal"></p>
                        <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-gray-900"><b>Course : </b> {user?.teaching_required_for.course}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-coolGray-500 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
                      
               <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-gray-900"><b>Subjects : </b>{user?.teaching_required_for.subjects}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-coolGray-500 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
               <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-gray-900"><b>Campus :</b> {user?.campus_address}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-coolGray-500 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
            
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-gray-800 font-normal"></p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
         
            <div class="mb-10  p-px bg-gradient-cyan rounded-md">
              <div class="p-4 bg-red-50 rounded-md">
                <div class="flex flex-wrap justify-between -m-3">
                  <div class="w-auto p-3">
                    <div class="flex flex-wrap items-center -m-3">
                      <div class="w-auto p-3">
                        <img src="gradia-assets/elements/pricing/checkbox-white.svg" alt=""/>
                      </div>
                      <div class="w-auto p-3">
                        <h3 class="mb-1 font-heading font-medium text-gray-800 text-xl"></h3>
                        <p class=" mb-4 font-bold text-red-800 text-2xl">Additional information</p>

                        <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-coolGray-900"><b>Campus :</b> {user?.campus_address}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-coolGray-500 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
               
               <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-coolGray-900"><b>Campus :</b> {user?.campus_address}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-coolGray-500 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
               <div class="w-full border-b border-coolGray-100">
                           <div class="w-full">
                             <div class="flex flex-wrap items-center justify-between py-4 -m-2">
                               <div class="w-auto p-2">
                                <div class="flex flex-wrap items-center -m-2">
              
                                <div class="w-auto p-2">
                             <h2 class="text-lg font-medium text-coolGray-900"><b>Campus :</b> {user?.campus_address}</h2>
                           </div>
                          </div>
                        </div>
                        <div class="w-auto p-2">
                       <p class="text-xs text-coolGray-500 font-medium"></p>
                    </div>
                   </div>
                  </div>
               </div>
                      </div>
                      <div class="w-auto p-3">
                        <p class="text-gray-800 text-base"></p>
                      
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
        
        </div>
      </div>
</section>
</div>
    </div>
  )
}
