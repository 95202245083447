
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllbanner,bannerSelector } from '../../api/banner';
import { fetchAllmobbanner, mobbannerSelector } from '../../api/mobBanner';

import Loadable from '@loadable/component';
import { useState } from 'react';
import Physics from '../images/physics.png'
import Chemistry from '../images/chemistry.png'
import Maths from '../images/maths.png'
import Biology from '../images/biology.png'
import { authenticateSelector } from '../../api/authSlice';


const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 3000,
    responsive: {
        0:{
            items:1,
        },
        768:{
            items:1,
        },
        1200:{
            items:1,
        }
    }
}

export default function Dashboard() {

  const dispatch = useDispatch()
  const {all_banner} = useSelector(bannerSelector)
  const {all_mobbanner} = useSelector(mobbannerSelector)
  const [display,setDisplay] = useState(false)

  const {user} = useSelector(authenticateSelector)
  console.log(user)
  useEffect(()=>{
    dispatch(fetchAllbanner())
  },[dispatch])

  useEffect(()=>{
    setDisplay(true);
  },[])

  useEffect(()=>{
    dispatch(fetchAllmobbanner())
  },[dispatch])

  console.log(all_mobbanner)


  return (
    <div>

<section class=" block sm:hidden bg-gray-50 text-slate-700 bg-blueGray-100" style={{fontFamily:'Manrope'}}>
  <OwlCarousel 
          {...options}
      >
         
          {
            all_mobbanner?.map((item)=>{
                 return (  <img  key = {item._id} class=" h-auto w-auto " src={item.banner_image} alt=""/>)

              })
          }
        </OwlCarousel> 
</section>
    <div class="hidden sm:block bg-gray-50 text-slate-700 bg-blueGray-100 w-full" style={{fontFamily:'Lato'}}>
  

    {display ? <OwlCarousel 
            {...options}
        >
           
            {
              all_banner?.map((item)=>{
                   return (  <img  key = {item._id} class=" sm:h-1/2 md:h-1/2 sm:w-40 md:w-40 " src={item.banner_image} alt=""/>)

                })
            }
      
             
          
    </OwlCarousel> : ''}

</div>



<section class="py-5 2xl:py-5 bg-blue-50 overflow-hidden">
   <div class="container px-8 mx-auto">
     <div class="flex flex-wrap -mx-5">
       <div class="w-full md:w-1/2 lg:w-1/4 p-5">
         <div class="relative h-52 bg-red-100 rounded-t-lg">
         <a href="">
            <img class="w-full h-full object-cover object-top" src={Physics} alt=""/>
          </a>
         </div>
       <div class="py-2 px-5 bg-red-800 rounded-b-lg text-center ">
           <a class="inline-block mb-3 py-1 px-3 text-white font-bold text-lg" href="/phys">Physics</a>
           <a href="#">
           </a>
         </div>
      </div>

      <div class="w-full md:w-1/2 lg:w-1/4 p-5">
         <div class="relative h-52 bg-red-100 rounded-t-lg">
         <a href="">
            <img class="w-full h-full object-cover object-top" src={Chemistry} alt=""/>
          </a>
         </div>
       <div class="py-2 px-5 bg-red-800 rounded-b-lg text-center ">
           <a class="inline-block mb-3 py-1 px-3 text-white font-bold text-lg" href="/chem">Chemistry</a>
           <a href="#">
           </a>
         </div>
      </div>

      <div class="w-full md:w-1/2 lg:w-1/4 p-5">
         <div class="relative h-52 bg-red-100 rounded-t-lg">
         <a href="">
            <img class="w-full h-full object-cover object-top" src={Maths} alt=""/>
          </a>
         </div>
       <div class="py-2 px-5 bg-red-800 rounded-b-lg text-center ">
           <a class="inline-block mb-3 py-1 px-3 text-white font-bold text-lg" href="/maths">Maths</a>
           <a href="#">
           </a>
         </div>
      </div>

      {user?.teaching_required_for?.subjects == "PCMB"?<div class="w-full md:w-1/2 lg:w-1/4 p-5">
         <div class="relative h-52 bg-red-100 rounded-t-lg">
         <a href="">
            <img class="w-full h-full object-cover object-top" src={Biology} alt=""/>
          </a>
         </div>
       <div class="py-2 px-5 bg-red-800 rounded-b-lg text-center ">
           <a class="inline-block mb-3 py-1 px-3 text-white font-bold text-lg" href="/combination4">Biology</a>
           <a href="#">
           </a>
         </div>
      </div>:user?.teaching_required_for?.subjects == "PCME"?<div class="w-full md:w-1/2 lg:w-1/4 p-5">
         <div class="relative h-52 bg-red-100 rounded-t-lg">
         <a href="">
            <img class="w-full h-full object-cover object-top" src={Biology} alt=""/>
          </a>
         </div>
       <div class="py-2 px-5 bg-red-800 rounded-b-lg text-center ">
           <a class="inline-block mb-3 py-1 px-3 text-white font-bold text-lg" href="/combination4">Electronics</a>
           <a href="#">
           </a>
         </div>
      </div> :user?.teaching_required_for?.subjects == "PCMC"?<div class="w-full md:w-1/2 lg:w-1/4 p-5">
         <div class="relative h-52 bg-red-100 rounded-t-lg">
         <a href="">
            <img class="w-full h-full object-cover object-top" src={Biology} alt=""/>
          </a>
         </div>
       <div class="py-2 px-5 bg-red-800 rounded-b-lg text-center ">
           <a class="inline-block mb-3 py-1 px-3 text-white font-bold text-lg" href="/combination4">Computer Science</a>
           <a href="#">
           </a>
         </div>
      </div>:""}

              

       

    </div>
  </div>
 </section>
</div>
    

  )
}



{/* <div>
    <section class="bg-gray-50 text-slate-700 bg-blueGray-100" style={{fontFamily:'Lato'}}>
    <a class="block w-full bg-slate-300" href="#">
              <img class=" sm:h-96 md:h-96 sm:w-full md:w-full object-cover " src={Banner} alt=""/>
            </a>
            </section>
 

    </div> */}

