import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { fetchOneNews, newsSelector} from '../../api/news'
import {useParams} from 'react-router-dom'
import { Interweave } from 'interweave';

export default function NewsPage() {

    const dispatch = useDispatch()
    const {current_news} = useSelector(newsSelector) 
    const {id}= useParams()


    console.log({current_news});

    
    useEffect(()=>{
        dispatch(fetchOneNews(id))
    },[id])



    return (
        <div className='bg-white'>
     <section class="py-14 p-4 px-10 max-w-screen-lg mx-auto  bg-slate-50 "  style={{fontFamily:'Manrope'}} >

        
<div>
        <img class="h-full w-full object-cover mb-5" src={current_news?.image} alt=""/>    
</div>

<div className='px-4'>
            <p className=' mb-4 text-2xl md:text-3xl  text-left leading-tight text-red-600 font-bold tracking-tighter'>{current_news?.title}</p>
            <p className='mb-2 text-xl md:text-2xl  text-left leading-tight  text-gray-700 font-bold tracking-tighter'>{current_news?.sub_title}</p>

            
        <div className=" mt-10 text-slate-800">
              <Interweave content={current_news?.description} />
              </div>
        </div>    


    </section>
</div>
)
}

