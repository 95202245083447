import React from 'react'
import Banner from '../homepage/banner'
import Sectionone from '../homepage/sectionone'
import Courses from '../homepage/courses'
import Testimonials from '../homepage/testimonial'
import Academic from '../homepage/academiccenters'
import Delivers from '../homepage/delivers'
import News from '../homepage/newsletter'
import Video from '../homepage/video'
import Faculty from '../homepage/faculty'
import Stories from '../homepage/stories'
import Edushrine from '../homepage/edushrine'


export default function index() {
  return (
    <div>
     <Banner/>
     <Sectionone/>
    
      <Courses/>
      <Edushrine/>
      <Delivers/>
      <Academic/>
      
      <Faculty/>
      <Stories/>
     <Testimonials/>
    
     <Video/>
     <News/>
   
    </div>
  )
}
