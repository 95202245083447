import React from 'react'
import Ban2 from '../images/about.jpg'
import Ban from '../images/aboutmobile.png'
import Come1 from '../images/come1.webp'
import img from '../images/img.png'
import Rite from '../images/RITE.png'
import Final1 from '../images/Final1.png'
import Ed1logo from '../images/ed1logo.png'
import Blog from '../images/blog.png'
import Best1 from '../images/best1.png'
import Cover1 from '../images/Cover1.png'
import Ill from '../images/ill.webp'
import Found from '../images/found.png'
import {FcReadingEbook} from 'react-icons/fc';
import Facultyone from '../images/Pankaj1.png'
import Facultytwo from '../images/Praveenkumar.png'
import Facultythree from '../images/Suresh1.png'
import Facultyfour from '../images/Adisiva.png'
import Ban1 from '../images/ban1.png'
import { NavLink } from 'react-router-dom'

export default function Aboutus() {
  return (
    <div>
<div className='hidden sm:block bg-white'>
       <div className='object-cover bg-cover w-full sm:w-full  flex justify-center items-center' style={{backgroundImage:`url(${Ban2})`,fontFamily:'Manrope',width:'100%',height:'90vh',backgroundRepeat:'no-repeat'}}></div>
</div>
<div className='block sm:hidden bg-white'>
       <div className='object-cover bg-cover w-full sm:w-full  flex justify-center items-center' style={{backgroundImage:`url(${Ban})`,fontFamily:'Manrope',width:'100%',height:'53vh',backgroundRepeat:'no-repeat'}}></div>
</div>
{/* -------------------------------------------------------------------------------------- */}
<section id='Edushrine' class=" px-2  sm:px-24 bg-white overflow-hidden py-10" style={{fontFamily:'Manrope'}}>

  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center">
      <div class="hidden sm:block w-full md:w-1/2 ">
        <img class="mx-auto transform hover:translate-y-3 transition ease-in-out duration-1000" src={Ill} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8 sm:pl-28 px-4 sm:px-0 ">
        <div class="md:max-w-xl">
          {/* <img class="mb-2 text-xs text-gray-600 font-semibold uppercase tracking-px w-10 h-10 " src={Icon} alt=""/>  */}
          <h2 class=" text-3xl sm:text-5xl font-bold font-heading tracking-px-n leading-tight text-red-600">We are <span className='text-black'>Edushrine!</span></h2>
         </div>
         <div class="pt-8  sm:max-w-lg">
              <p class="text-gray-900 sm:text-md text-lg font-medium"><span className='text-red-600 font-bold'>EDU-SHRINE </span> - a leading Coaching and Mentoring Academy for High School & PUC Students in Karnataka. We provide Effective Coaching and mentoring for High School & PUC Students for their Board as well as Competitive Examinations like NTSE, KVPY, JEE, NEET, Olympiads etc.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg text-lg">
              <p class="text-gray-900 sm:text-md font-medium">We also conduct Two year Integrated Coaching Programmes in association with the Colleges.</p>
            </div>

            <div class=" pt-5  lg:max-w-lg text-lg">
              <p class="text-gray-900 text-md font-medium"><span className='text-red-600 font-bold'>BHAVISHYA</span> – a Futuristic Foundation for high school,</p>
            </div>

            <div class=" pt-5  lg:max-w-lg text-lg">
              <p class="text-gray-900 text-md font-medium"><span className='text-red-600 font-bold'>PATHSHALA</span>  - a Career planning and guidance programme for high school, college, and graduate students are unique initiatives of the academy. </p>
              </div>

              <div class=" pt-5  lg:max-w-lg text-lg">
              <p class="text-gray-900 text-md font-medium">Meticulous schedule, Comprehensive Result-oriented study content, Passionate faculty with scientific approach are our strengths.</p>
            </div>


            <div class=" pt-5  lg:max-w-lg text-lg">
              <p class="text-gray-900 text-md font-medium">All the Programmes we implement are student centred and it is not the success, but the process of achieving it the students would enjoy most with us.</p>
            </div>

      </div>
    </div>
  </div>
</section>
{/* ----------------------------------------------------------------------------------------------- */}
<section class="" style={{fontFamily:'Manrope'}}>
  <div class=" py-12 sm:py-10 bg-red-50 sm:px-28">
    <div class="container px-4 mx-auto">
      <div class="pb-10 mb-16 border-b border-gray-700">
        <h1 class="font-heading text-4xl sm:text-6xl  text-gray-800 font-bold mb-20 sm:ml-24 text-center">About <span class="text-red-600">Edushrine</span></h1>
        <div class="flex flex-wrap -mx-4">
          <div class="w-full sm:w-1/2 px-4 mb-28 sm:mb-0">
            <div class="max-w-md mx-auto relative">
              <img class="block mx-auto" src={Come1} alt=""/>
              <img class="absolute bottom-0 left-0 md:-ml-8 -mb-20" src={Come1} alt=""/>
            </div>
          </div>
          <div class="w-full lg:w-1/2 sm:px-4 px-8">
            <div class="max-w-md mx-auto">
              <h1 class="font-heading text-2xl sm:text-xl text-red-600 mb-8 font-semibold">We are EduShrine <span class="text-gray-800">(Literally meaning VIDYALAYA, a temple of Learning). </span></h1>
              <p class="max-w-sm font-medium text-lg sm:text-md text-gray-800 mb-8"> We are a 6-Year Old Bengaluru-based Coaching institute currently serving over 1500 High-School and PUC students from 6 different academic centers across Karnataka.  </p>
              <p class="font-medium text-lg sm:text-md text-gray-800">
If you are looking for a One-Stop destination for equally focused BOARD + COMPETITIVE Examination (NTSE/NEET/JEE/KCET) Coaching for classes 8 to 12, with immense professionalism, care, and concern towards students’ growth, we are the right choice.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-6xl sm:max-w-5xl mx-auto ">
          <div class="flex flex-wrap -mx-4">
            <div class="w-full md:w-1/2 sm:px-2 px-8 mb-12 md:mb-0">
              {/* <h4 class="font-heading text-2xl text-white mb-8">One platform for you</h4> */}
              <p class="sm:text-md text-lg text-gray-800 font-medium">It is the team of Passionate teachers with strong conceptual abilities and a rationalistic approach that forms the backbone of EduShrine. The team in turn is supported by a well-designed curriculum and meticulous schedule.It is not only the success but also the process of achieving it the students enjoy with us.It is not only the success but also the process of achieving it the students enjoy with us.</p>
            </div>
            <div class="w-full md:w-1/2 sm:px-4 px-8">
              {/* <h4 class="font-heading text-2xl text-white mb-8">Workflow builder</h4> */}
              <p class="sm:text-md text-lg text-gray-800 font-medium">With effective coaching, psychological guidance for stress-free learning, and mentoring,  we have produced over 50 MBBS seats and hundreds of B.Tech seats in the most prestigious colleges of the country and the state of Karnataka, along with scholarships through KVPY and NTSE.We are EduShrine, a perfect Academic Guide to Rise your Child’s Future.

</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* --------------------------------------------------------------------------------------------- */}
<div class="w-full lg:w-full 3xl:w-full px-2 sm:px-6 bg-gradient-to-br from-gray-900 via-gray-900 to-red-600 py-10 sm:py-12" style={{fontFamily:'Manrope'}}>
        <div class="max-w-full mx-auto pt-3 px-1 pb-1 rounded-md">
          <div class="pt-5 pb-5  px-6 sm:px-14  rounded-md">
            <div class="flex items-center justify-center mb-12">
              <h5 class="font-bold text-5xl sm:text-4xl text-red-600 ">Philosophy</h5>
            </div>
            <div class="flex mb-8 sm:text-center text-center">
              <span class="sm:text-lg text-lg text-white  ">Everything we do, we believe that the growth is result of strength and struggle is result of weakness. The way we deliver growth to every students is by helping identify opportunities, organize resources and teach & mentor them to play strengths. We happen to transform students’ lives by delivering proven teaching strategies, simple learning methods and innovative digital content & platform.

</span>
            </div>
            <div class="flex flex-wrap -mx-2">
              <div class="w-full xl:w-1/2 px-2 mb-4 xl:mb-0">
                <div class="flex">
                  
                  <span class="ml-7 font-light"></span>
                </div>
              </div>
              <div class="w-full xl:w-1/2 px-2">
                <div class="flex">
                
                  <span class="ml-7 font-light"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



{/* ------------------------------------------------------------------------------------------------- */}

<section class="sm:py-16 py-10 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container sm:px-24 px-8 sm:mx-auto">
    <div class="flex flex-wrap -m-8">
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="xl:pt-12">
          <h2 class="mb-7 text-4xl sm:text-3xl text-red-600 font-bold tracking-px-n leading-tight text-center"><span className='text-black'>Kiran Sidde’s </span>perception of Education :</h2>
          <p class="text-gray-900 text-lg ">As told by Socrates, “Education is not filling of empty vessels, it is lighting of a candle”.
Main aim of education is to bring out the innate capacities of a child, display them to one’s self and help the child channelize his/her potential.Edu-Shrine academic services is founded by Kiran Sidde with a vision of becoming an effective alternative for all the career needs of students.
With effective academic system, extensive study content, innovative teaching methods and rationalistic approach, Edu-Shrine has helped over 9000 students in Karnataka realize their dreams of joining professional courses like Engineering, Medicine, Pure Sciences, Agricultural Sciences, Paramedical Courses, etc.</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-8 sm:mt-24">
        <div class="flex flex-wrap justify-center">
        <h5 class="font-bold text-3xl text-center text-black pb-3 ">Managing Director</h5>


          <div class="relative inline-block rounded-3xl overflow-hidden shadow-3xl transform hover:-translate-y-2 transition ease-in-out duration-1000" style={{backdropfilter: `blur("1px")`}}>
            <img class="w-full h-full" src={img} alt=""/>
          
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="flex flex-wrap h-full xl:pb-12">
          <div class="flex flex-wrap self-end -m-10">
            <div class="w-full p-10">
              <div class="md:max-w-sm">
                <h3 class="mb-5 text-3xl text-red-600 font-bold leading-snug"><span className='text-black'>Kiran Sidde’s </span> is a Teacher by Choice</h3>
                <p class="text-gray-900 text-opacity-90 font-medium leading-relaxed">He has been teaching Chemistry to Students of High School and PUC for past 16 years and had produced good number of State and National Level rankers.</p>
              </div>
            </div>
            
            
            <div class="w-full p-10">
              <div class="md:max-w-sm">
                <h3 class="mb-5 text-3xl text-red-600 font-bold leading-snug"> <span className='text-black'>Kiran Sidde’s </span> is an administrator by Skill</h3>
                <p class="text-gray-800 text-opacity-90 font-medium leading-relaxed">He has served as Vice-Principal, Principal and Academic Director for many reputed institutions in the states of Karnataka and Andhra Pradesh</p>
              </div>
            </div>
            <div class="w-full px-10">
              <div class="h-px bg-red-900"></div>
            </div>
            {/* <div class="w-full p-10">
              <div class="md:max-w-sm">
                <h3 class="mb-5 text-3xl text-red-800 font-bold leading-snug"> Kiran Sidde is an administrator by Skill</h3>
                <p class="text-gray-800 text-opacity-90 font-medium leading-relaxed">He has served as Vice-Principal, Principal and Academic Director for many reputed institutions in the states of Karnataka and Andhra Pradesh</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* ------------------------------------------------------------------------------------------- */}
<section class=" py-4 bg-white overflow-hidden px-4 sm:px-0" style={{fontFamily:'Manrope'}}>
  <div class="container px-6 sm:px-4 sm:mx-auto">
  <h3 class="mb-5 text-3xl sm:text-3xl text-red-600 font-bold flex justify-center leading-snug"><span className='text-black'>Kiran Sidde</span>   is Educational Entrepreneur by Passion
</h3>
    <p class="mb-4 text-xl text-gray-800 text-center font-semibold leading-normal md: max-w-5xl mx-auto underline">He has founded three institutions

</p>
   
  </div>
</section>

<section class="hidden sm:block pt-4 pb-10 px-24 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container mx-auto px-4">
    <div class="flex grid-cols-6 justify-center -m-10">
      <a href='#Edushrine'><div class="w-96 h-96 p-6 transform hover:translate-y-2 ease-in-out duration-100">
        <img src={Rite} alt=""/>
      </div></a>
      <a href='#ASC'><div class="w-96 h-96 p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Blog} alt=""/>
      </div></a>
      <a href='#carmel'><div class="w-96 h-96 p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Final1} alt=""/>
      </div></a>
    </div>
  </div>
</section>

<section class="block sm:hidden pb-4 px-2 justify-center bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="container ">
    <div class=" justify-center">
      <a href='#Edushrine'><div class="w-96 h-96 p-6 transform hover:translate-y-2 ease-in-out duration-100">
        <img src={Rite} alt=""/>
      </div></a>
      <a href='#ASC'><div class="w-96 h-96 p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Blog} alt=""/>
      </div></a>
      <a href='#carmel'><div class="w-96 h-96 p-6 transform hover:translate-y-2 ease-in-out duration-100">
      <img src={Final1} alt=""/>
      </div></a>
    </div>
  </div>
</section>

{/* ---------------------------------------------------------------------------------------------- */}
<section class="relative sm:pt-14 sm:pb-12 sm:px-16 bg-white overflow-hidden" style={{fontFamily:'Manrope'}}>
  <div class="relative z-10 container px-4 mx-auto">
    <div class="md:max-w-9xl mx-auto">
    <div class="flex flex-wrap -m-5">
        <div class="w-full md:w-1/2 p-5">
          <div class="bg-red-50 bg-opacity-90 border border-blueGray-100 rounded-4xl shadow-9xl">
            <div class="">
              <div class="py-4 px-9">
                <h3 class=" text-3xl text-red-600 flex justify-center font-bold leading-snug">MISSION</h3>
              </div>
            </div>
            <div class="pt-5 pb-5 sm:px-16 px-4">
              <ol class="mb-5 list-disc  text-gray-900">
                <li class="mb-4 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">To provide complete support to managements in establishing constructive academic centers and help them in brand building.</p>
                </li>
                <li class="mb-4 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">To streamline the academic outlook of students and channelize their energies towards right direction.</p>
                </li>
                <li class="mb-4 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">To Provide Rationalistic Learning Methods under stress free yet competitive environment</p>
                </li>
                <li class="mb-4 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">To ensure that students attain life skills to survive as the fittest in society.</p>
                </li>
                <li class="flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">To provide a perfect balance between the training for both Board and Competitive Examinations.</p>
                </li>
              </ol>
              
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 p-5">
          <div class="bg-red-50 bg-opacity-90 border border-blueGray-100 rounded-4xl shadow-9xl">
            <div class="">
              <div class="py-4 px-9">
                <h3 class=" text-3xl text-red-600  flex justify-center font-bold leading-snug">CORE VALUES</h3>
              </div>
            </div>
            <div class="pt-3 pb-8 sm:px-16 px-4">
              <ul class="list-disc text-gray-900">
                <li class="mb-1 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">Integrity</p>
                </li>
                <li class="mb-1 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">Student Centeredness</p>
                </li>
                <li class="mb-1 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">Compassion</p>
                </li>
                <li class="mb-1 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">Professional Ethics</p>
                </li>
                <li class="flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">Non-Commercial Practices</p>
                </li>
              </ul>
            </div>
            <div class="py-2 px-9">
                <h3 class="text-3xl text-red-600 flex justify-center font-bold leading-snug">VISION</h3>
              </div>
            <div class="pt-3 pb-8 sm:px-16 px-4">
              <ul class="mb-5 text-gray-900">
                <li class="mb-1 flex items-center">
                <div className=' text-red-600 px-2'> <FcReadingEbook/></div>

                  <p class="font-semibold leading-normal">To emerge as an effective coaching alternative for every prestigious competitive examination in the country from NTSE to UPSC.</p>
                </li>
              </ul> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
{/* ------------------------------------------------------------------------------------------------ */}
<section class="py-10 bg-white sm:px-28 px-4"  style={{fontFamily:'Manrope'}}>
  <div class="container px-4 mx-auto">
    <div class="text-center mb-16">
      <h1 class="font-bold text-4xl sm:text-5xl text-red-600"> EduShrine <span class="text-gray-800">Gallery</span></h1>
    </div>
    <div class="flex flex-wrap -mx-3 -mb-6">
      <div class="w-full md:w-1/2 xl:w-1/3 px-3 mb-6">
        <a class="relative group block" href="#">
          <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
          <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 border-2 rounded-md overflow-hidden transition duration-500">
            <img class="img-fluid w-auto h-auto object-cover" src={Cover1} alt=""/>
           
          </div>
        </a>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 px-3 mb-6">
        <a class="relative group block" href="#">
          <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
          <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 border-2  rounded-md overflow-hidden transition duration-500">
            <img class="img-fluid w-auto h-auto object-cover" src={Ed1logo} alt=""/>
            <div class="absolute bottom-0 left-0 mb-4 w-full text-center">
              {/* <h3 class="text-2xl text-white font-black mb-1">Product title</h3> */}
              {/* <span class="text-gray-800 font-black">Kiran Sidde an Author by Zeal
II PUC Text Book, TREXEX MAGISTERIAL CHEMISTRY</span> */}
            </div>
          </div>
        </a>
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 px-3 mb-6">
        <a class="relative group block" href="#">
          <div class="absolute top-0 left-0 w-full h-full bg-blueGray-900 rounded-md"></div>
          <div class="relative transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 border-2  rounded-md overflow-hidden transition duration-500">
            <img class="img-fluid w-auto h-auto object-cover" src={Best1} alt=""/>  
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

{/* ------------------------------------------------------------------------------------------------------ */}
<div>
        <section>


  <div class="py-12 px-4 sm:py-20 bg-red-50 sm:px-24 " style={{fontFamily:'Manrope'}}>
    <div class="container mx-auto px-4">
      <div class="mb-8 md:mb-16 max-w-lg mx-auto text-center">
        <h2 class="mb-6 text-4xl sm:text-5xl font-bold font-heading text-black ">Our <span className='text-red-600 font-bold'>Faculty</span></h2>
       
      </div>
      <div class="flex flex-wrap -mx-4 mb-8">
        <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <div class="flex flex-wrap -mx-4 -mb-8">
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultyone} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Pankaj Tamang</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Physics</h2>
                  </div>
                </div>
              </a>
            </div>
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultytwo} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">R.Praveen Kumar Reddy</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Chemistry</h2>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 px-4">
        <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
          <div class="flex flex-wrap -mx-4 -mb-8">
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultythree} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Suresh Kumar</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Mathematics</h2>
                  </div>
                </div>
              </a>
            </div>
            <div class="w-full sm:w-1/2 px-4 mb-8">
              <a class="group block h-80 relative bg-blueGray-900 rounded-md" href="#">
              <div class="absolute top-0 h-full left-0 w-full border-2 border-gray-200 rounded-md transform -translate-x-1 -translate-y-1 group-hover:-translate-x-0 group-hover:-translate-y-0 overflow-hidden transition duration-300">
                  <img class="img-fluid w-full h-full object-cover" src={Facultyfour} alt=""/>
                  <div class="absolute bottom-0 left-0 py-3 w-full text-left pl-4 bg-white opacity-90">
                    <h3 class="text-red-900 md:text-lg font-bold mb-1">Adisiva Sekhar M</h3>
                    <h2 class="text-base md:text-xs text-red-700 font-black mb-1 ">Biology</h2>
                  </div>
                </div>
              </a>
            </div>
           
          </div>
        </div>
        </div>
      </div>
      <div class="text-center"><a class="inline-block py-2 px-6 leading-loose rounded-l-xl rounded-t-xl bg-red-600 hover:bg-red-700 text-gray-50 font-bold tansition duration-200" href="/faculty">View all faculties</a></div>
    </div>
  </div>

</section>
    </div>


{/* ------------------------------------------------------------management section--------------------------------------------- */}


{/* ------------------------------------------------------------------------------------------- */}
<div>
   <section class="bg-white py-10  sm:py-20 sm:px-24" style={{fontFamily:'manrope'}}>
  <div class="container sm:mx-auto ">
    <div class="relative  sm:py-11 sm:px-16 px-2 rounded-2xl overflow-hidden">
    <img class=" absolute h-52 w-full sm:w-auto sm:h-auto top-0 left-0 px-6 sm:px-20" src={Ban1} alt=""/>

      <div class="relative z-10 sm:z-20 flex flex-wrap items-center">
        <div class="w-1/2 sm:w-1/2">
        <p class="px-4 mt-4 mb-5 sm:mt-5 sm:mb-10 sm:px-16 sm:text-4xl text-md pl-14  text-white font-bold">Design your Future with Edushrine.<span className='text-red-600'>Today!</span> </p>
        </div>
        <div class="w-full sm:w-1/2 sm:p-3 p-2 ">

          <NavLink to='/contact'>
          <button class="block sm:mt-4  ml-10 sm:ml-60 sm:px-10 sm:py-2 px-5 py-2  font-semibold sm:text-2xl text-sm  text-white bg-red-600 hover:bg-orange-600 rounded-lg sm:rounded-md">Contact Now</button>
          </NavLink>
        </div>
      </div>

      
    </div>
  
  
  </div>

  

  
</section>
    </div>
    </div>
  )
}
