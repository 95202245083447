import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    all_mobbanner:[],
    current_mobbanner:null,
    loading:false,
    hasError:false,
}

export const mobbannerSlice = createSlice({

  name: 'mobbanner',
  initialState,

  reducers: {
    getmobbanner: state => {
      state.loading = true;
    },

    getAll_mobbanner_success: (state, {payload})  =>{
      console.log(payload);
        state.loading = false
        state.all_mobbanner = payload
    },

    get_mobbanner_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, {payload}) =>{
      state.loading = false
      state.current_mobbanner = payload
  
  },

  },
})


export const { getmobbanner ,getAll_mobbanner_success,getCurrentSuccess, get_mobbanner_Failure } = mobbannerSlice.actions;

export const mobbannerSelector = state => state.mobbanner;



export const fetchAllmobbanner = (id) => async dispatch => {
  dispatch(getmobbanner())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/mobile-banner`)
   console.log({data});
   dispatch(getAll_mobbanner_success(data));

  } catch (error) {
 dispatch(get_mobbanner_Failure())
  }
 };



 export const fetchOneMobbanner = (id) => async dispatch => {

  dispatch(getmobbanner())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/mobile-banner/${id}`, config)

   console.log({data});

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_mobbanner_Failure())
  }
 };



export default mobbannerSlice.reducer;