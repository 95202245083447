import React, { Component } from "react";
import Slider from "react-slick";
import story1 from '../images/new1img.jpg'
import story2 from '../images/new2img.jpg'
import story3 from '../images/new3img.jpg'
import story4 from '../images/Board Website Posting_page-0011.jpg'
import story5 from '../images/Board Website Posting_page-0012.jpg'
import story6 from '../images/Board Website Posting_page-0001.jpg'
import story7 from '../images/Board Website Posting_page-0002.jpg'
import story8 from '../images/Board Website Posting_page-0003.jpg'
import story9 from '../images/Board Website Posting_page-0004.jpg'
import story10 from '../images/Board Website Posting_page-0005.jpg'
import story11 from '../images/Board Website Posting_page-0006.jpg'
import story12 from '../images/Board Website Posting_page-0007.jpg'
import story13 from '../images/Board Website Posting_page-0008.jpg'
import story14 from '../images/Board Website Posting_page-0009.jpg'
import story15 from '../images/Board Website Posting_page-0010.jpg'
import story16 from '../images/Board Website Posting_page-0013.jpg'
import story17 from '../images/JEE Mains Website Posting_page-0001.jpg'
import story18 from '../images/JEE Mains Website Posting_page-0002.jpg'
import story19 from '../images/JEE Mains Website Posting_page-0003.jpg'
import story20 from '../images/JEE Mains Website Posting_page-0004.jpg'
import story21 from '../images/JEE Mains Website Posting_page-0005.jpg'
import story22 from '../images/JEE Mains Website Posting_page-0006.jpg'
import story23 from '../images/JEE Mains Website Posting_page-0007.jpg'
import story24 from '../images/JEE Mains Website Posting_page-0008.jpg'
import story25 from '../images/JEE Mains Website Posting_page-0009.jpg'
import story26 from '../images/JEE Mains Website Posting_page-0010.jpg'
import story27 from '../images/JEE Mains Website Posting_page-0011.jpg'
import story28 from '../images/JEE Mains Website Posting_page-0012.jpg'
import story29 from '../images/JEE Mains Website Posting_page-0013.jpg'
import story30 from '../images/JEE Mains Website Posting_page-0014.jpg'
import story31 from '../images/JEE Mains Website Posting_page-0015.jpg'
import story32 from '../images/JEE Mains Website Posting_page-0016.jpg'
import story33 from '../images/JEE Mains Website Posting_page-0017.jpg'
import story34 from '../images/JEE Mains Website Posting_page-0018.jpg'
import story35 from '../images/JEE Mains Website Posting_page-0019.jpg'
import story36 from '../images/JEE Mains Website Posting_page-0020.jpg'
import story37 from '../images/JEE Mains Website Posting_page-0021.jpg'
import story38 from '../images/JEE Mains Website Posting_page-0022.jpg'
import story39 from '../images/JEE Mains Website Posting_page-0023.jpg'
import story40 from '../images/NeetRes2.jpg'
import story41 from '../images/NeetRes1.jpg'

export default class CenterMode  extends Component {
  render() {
    const settings = {
      
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "12px",
      slidesToShow: 1,
      speed: 500,
      pauseOnHover: true,
      fade:true,
      dots: true,
      infinite: true,
      focusOnSelect:true,
    
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay:true,
            autoplaySpeed: 1000,
            speed: 2000,

            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    
    return (
        <div className=" py-8 px-14 sm:px-24 sm:py-16  bg-white sm: w-full"  style={{fontFamily:'Manrope'}}>
        <div>
        <div class=" sm:mb-4 md:mb-2 max-w-lg mx-auto text-center">
        
        <h2 class="mb-2 sm:mb-2 text-3xl sm:text-5xl font-bold font-heading text-black w-full">Success <span className="text-red-600">Stories</span></h2>
        
      </div>
        
        <Slider {...settings}>
      

        
       
          <div >
          <img class='lg:pt-20 mx-auto' src={story4} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story5} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story6} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story7} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story8} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story9} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story10} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story11} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story12} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story13} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story14} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story15} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story16} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story17} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story18} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story19} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story20} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story21} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story22} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story23} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story24} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story25} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story26} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story27} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story28} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story29} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story30} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story31} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story32} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story33} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story34} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story35} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story36} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story37} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story38} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story39} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story40} alt=""/>
          </div>
          <div >
          <img class='lg:pt-20 mx-auto' src={story41} alt=""/>
          </div>


        </Slider>
      </div>
      </div>
    );
  }
} 