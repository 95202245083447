import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
    loading:false,
}


export const notificationSlice = createSlice({
    name:"notify",
    initialState,
    reducers:{
        getnotification: state =>{
            state.loading = true
        },
    }
})


export const {getnotification} = notificationSlice.actions;
export const notifySelector = state =>state.notify;

export const updateNotify = (id,values) => async dispatch =>{


    console.log("The values are :",values)
    console.log("The id is ",id)
       const key = "notify"
       dispatch(getnotification())
       try{
        const {data} = await axios.put(keyUri.BACKEND_URI+`/updateNotification/${id}`,values,config)
        console.log(data)
        
        window.location.reload()
  
  
       }
       catch(error){
        console.log(error)
       }
  };

  export default notificationSlice.reducer;