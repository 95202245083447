import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { fetchOneBlog, blogSelector} from '../../api/blog'
import {useParams} from 'react-router-dom'
import { Interweave } from 'interweave';

export default function BlogPage() {

    const dispatch = useDispatch()
    const {current_blog} = useSelector(blogSelector) 
    const {id}= useParams()


    console.log({current_blog});

    
    useEffect(()=>{
        dispatch(fetchOneBlog(id))
    },[id])



    return (
        <div className='py-16 md:py-24 bg-white'>
     <section class="container px-4 mx-auto "  style={{fontFamily:'Manrope'}} >
        <div className='md:max-w-4xl mx-auto mb-12'>
            <p className='mb-4 text-3xl md:text-5xl  text-center leading-tight text-gray-900 font-bold tracking-tighter'>{current_blog?.title}</p>
            <p className='text-xl text-red-600  text-center capitalize'>{current_blog?.sub_title}</p>

            <img class="h-full w-full object-cover rounded-lg mt-5" src={current_blog?.image} alt=""/>

        <div className="px-4 mt-10 text-gray-900" >
              <Interweave content={current_blog?.description} />
        </div>    
        <p className='text-xl text-red-600  text-end capitalize'>-{current_blog?.author}</p>

        </div>
        


    </section>
</div>
)
}

