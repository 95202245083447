import React from 'react'
import { useSelector } from 'react-redux'

import { authenticateSelector } from '../../../api/authSlice'

export default function Index() {
  const {user} = useSelector(authenticateSelector)

  return (

    <div className='bg-white '>
      <section class="py-8 w-full h-full bg-white">
 
 <h3 class="font-bold text-gray-800 text-2xl text-left ml-5 py-5"> <span> Hello  {user.role == 'student'? user?.student_name :  user?.parents_details?.father_name }</span>, <span className='text-4xl'><br/>Welcome <span class="text-red-600">to Edushrine!</span></span></h3>

  
</section>
</div>
  )
}
