import { Collapse } from "antd"
import { useEffect, useState } from "react"
import { PieChart, Pie,Cell } from "recharts";
import { useDispatch, useSelector } from "react-redux"
import { authenticateSelector } from "../api/authSlice"
import { fetchOneGTEResults, GTESelector } from "../api/GTE"
import {HiOutlineDownload} from 'react-icons/hi'

const { Panel } = Collapse;

export default function GTE(){

 
    const {user} = useSelector(authenticateSelector)
    const [active, setActive]= useState('0')

      const COLORS = ['#06C931', '#E51A4B', '#0B7BFF'];

const keyHandler=(e)=>{
        console.log(e)
        setActive(e)
      }


    return (
        <div className="text-xl" style={{fontFamily:"Manrope"}}>
        {
            user?.gte_results?.map((item,i)=>{
                console.log({item:item})

const phydata = [
{ name: 'A1', value: item?.phy_r},
{ name: 'A2', value: item?.phy_w},
{ name: 'B1', value: item?.phy_l},   
];
const chemdata = [
{ name: 'A1', value: item?.che_r},
{ name: 'A2', value: item?.che_w},
{ name: 'B1', value: item?.che_l},   
];
const mathdata = [
{ name: 'A1', value: item?.mat_r},
{ name: 'A2', value: item?.mat_w},
{ name: 'B1', value: item?.mat_l},   
];
     
           return <Collapse accordion = {true} destroyInactivePanel={true} className =' sm:mx-auto w-[100%] sm:w-[95%]' activeKey={active} onChange={(e)=>keyHandler(e)}>
                <Panel header = {item?.exam_name} key = {i}>
                <div className=' grid grid-cols-3 gap-2' style={{fontFamily:'Manrope'}}>
                    <div>
                    <h1 className=' text-[#262E57] w-[220px] text-2xl font-bold'>{item?.student_name}</h1>
                        <p class="text-lg">{item?.register_no}</p>
                        <p className='mt-5 text-lg bg-[#3478C8] rounded-md text-white text-center py-2'>Total Marks : {item?.total_m}</p>    
                    </div>
               
                        <div className='mt-20 md:mt-12 w-40 h-20 rounded-md bg-[#E8EFF8] md:ml-40'>
                                <p className=' text-3xl text-center text-[#3478C8] font-bold'>{item?.final_r}</p>
                                <p className='py-3 text-lg font-bold rounded-b-md bg-[#3478C8] text-center text-[#FFFFFF]'>Rank</p>
                            </div>
                    <div>
                    <button class="inline-block h-14 py-4 px-6 md:ml-80 text-center text-red-900 font-bold text-4xl leading-4 rounded-sm transition duration-200"><HiOutlineDownload/></button>
                    </div>
                    </div>

                    <div className=" md:flex" >
            <div class="md:px-14">      
         <div className=' sm:w-[275px] h-[116px] rounded-lg bg-[#FEF2FE] mt-8'>
        <div className=' ml-4 grid grid-cols-2 gap-3'>
            <div>
                <h1 className=' mt-2 text-lg text-gray-800 mb-4 font-bold leading-[14.63px]'>Physics</h1>
                <div className='mt-2 flex items-center gap-1'>
                     <div className= 'bg-[#06C931] w-[4px] h-[4px]'></div>
                     <div className=''>Right : {item?.phy_r}</div>
 
                </div>

                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#E51A4B] w-[4px] h-[4px]'></div>
                        <div className=''>Wrong : {item?.phy_w}</div>
                    </div>
                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#0B7BFF] w-[4px] h-[4px]'></div>
                        <div className=''>Unattended: {item?.phy_l}</div>
                    </div>
                </div>
                
                <div class="hidden sm:block">
                <PieChart width={400} height={100}  >
                    <Pie
                    data={phydata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {phydata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>

            <div class="block sm:hidden">
                <PieChart width={150} height={110}  >
                    <Pie
                    data={phydata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {phydata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>
        </div>
        </div>

         <div className=' pl-2 pr-2 py-2 text-white bg-[#D76767] sm:w-[275px] rounded-b-md grid grid-cols-2'>
                <div className=' inline-block text-white text-lg'>Marks : {item?.phy_m}</div>
                <div className=" text-right text-white text-lg">Rank: {item?.phy_rank}</div>
        </div>
        </div>

        <div class="md:px-14">
              {/*Chemistry Card  */}         
        <div className=' sm:w-[275px] h-[116px] rounded-lg bg-[#FEF2FE] mt-8'>
        <div className=' ml-4 grid grid-cols-2 gap-3'>
            <div>
                <h1 className=' mt-2 text-lg text-gray-800 mb-4 font-bold leading-[14.63px]'>Chemistry</h1>
                <div className='mt-2 flex items-center gap-1'>
                     <div className= 'bg-[#06C931] w-[4px] h-[4px]'></div>
                     <div className=''>Right : {item?.che_r}</div>
 
                </div>

                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#E51A4B] w-[4px] h-[4px]'></div>
                        <div className=''>Wrong : {item?.che_w}</div>
                    </div>
                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#0B7BFF] w-[4px] h-[4px]'></div>
                        <div className=''>Unattended: {item?.che_l}</div>
                    </div>
                </div>
                
                <div class="hidden sm:block">
                <PieChart width={400} height={100}  >
                    <Pie
                    data={chemdata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {chemdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>

            <div class="block sm:hidden">
                <PieChart width={150} height={110}  >
                    <Pie
                    data={chemdata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {chemdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>
        </div>
        </div>

         <div className=' pl-2 pr-2 py-2 text-white bg-[#81c160] sm:w-[275px] rounded-b-md grid grid-cols-2'>
                <div className=' inline-block text-white text-lg'>Marks : {item?.che_m}</div>
                <div className=" text-right text-white text-lg">Rank: {item?.che_rank}</div>
        </div>  
        </div>

        {/*Mathematics Card if PCM (For JEE)  */}
        <div class="md:mx-14">
            {
                item?.mat_r ?
                <>
                <div className=' sm:w-[275px] h-[116px] rounded-lg bg-[#FEF2FE] mt-8'>
        <div className=' ml-4 grid grid-cols-2 gap-3'>
            <div>
                <h1 className=' mt-2 text-lg text-gray-800 mb-4 font-bold leading-[14.63px]'>Maths</h1>
                <div className='mt-2 flex items-center gap-1'>
                     <div className= 'bg-[#06C931] w-[4px] h-[4px]'></div>
                     <div className=''>Right : {item?.mat_r}</div>
 
                </div>

                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#E51A4B] w-[4px] h-[4px]'></div>
                        <div className=''>Wrong : {item?.mat_w}</div>
                    </div>
                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#0B7BFF] w-[4px] h-[4px]'></div>
                        <div className=''>Unattended: {item?.mat_l}</div>
                    </div>
                </div>
                
                <div class="hidden sm:block">
                <PieChart width={400} height={100}  >
                    <Pie
                    data={mathdata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {mathdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>

            <div class="block sm:hidden">
                <PieChart width={150} height={110}  >
                    <Pie
                    data={mathdata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {mathdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>
        </div>
        </div>

         <div className=' pl-2 pr-2 py-2 text-white bg-[#bb5487] sm:w-[275px] rounded-b-md grid grid-cols-2'>
                <div className=' inline-block text-[#FFFFFF] text-lg'>Marks : {item?.math_m}</div>
                <div className=" text-right text-[#FFFFFF] text-lg">Rank: {item?.math_rank}</div>
        </div>  
                </> :

                <>
        {/* if not JEE,then its NEET So Show the BIology Card */}
                <div className=' w-[275px] h-[116px] rounded-lg bg-[#FEF2FE] mt-8'>
        <div className=' ml-4 grid grid-cols-2 gap-3'>
            <div>
                <h1 className=' mt-2 text-[12px] text-[#262E57] leading-[14.63px]'>Biology</h1>
                <div className='mt-2 flex items-center gap-1'>
                     <div className= 'bg-[#06C931] w-[4px] h-[4px]'></div>
                     <div className=''>Right : {item?.bio_r}</div>
 
                </div>

                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#E51A4B] w-[4px] h-[4px]'></div>
                        <div className=''>Wrong : {item?.bio_w}</div>
                    </div>
                    <div className=' flex items-center gap-1'>
                        <div className= 'bg-[#0B7BFF] w-[4px] h-[4px]'></div>
                        <div className=''>Unattended: {item?.bio_l}</div>
                    </div>
                </div>
                
                <div class="hidden sm:block">
                <PieChart width={400} height={100}  >
                    <Pie
                    data={chemdata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {chemdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>


            <div class="block sm:hidden">
                <PieChart width={150} height={110}  >
                    <Pie
                    data={chemdata}
                    cx={70}
                    cy={50}
                    innerRadius={15}
                    outerRadius={20}      
                    dataKey="value"
                    label
                    >
                    {chemdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                
                </PieChart>
            </div>
        </div>
        </div>

         <div className=' pl-2 pr-2 text-white h-6 bg-[#D76767] sm:w-[275px] rounded-md grid grid-cols-2'>
                <div className=' inline-block text-[#FFFFFF]'>Marks : {item?.che_m}</div>
                <div className=" text-right text-[#FFFFFF]">Rank: {item?.che_rank}</div>
        </div> 

                </>
            }
        </div>   
        </div>      
            </Panel>
            </Collapse>
        }) } 
        </div>    

)
}