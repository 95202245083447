const  keyUri = {

    // BACKEND_URI:'http://localhost:5000/api'


    // live_server_link
    BACKEND_URI:'https://clownfish-app-4ttkm.ondigitalocean.app/api'


}


let token = localStorage.getItem('token')

const config = {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    }
  };
  
  
export  {keyUri, config }

